export const eventTags = {
  LIST_DETAIL_ITEMS: 'LIST_DETAIL_ITEMS',
  LIST_DETAIL_ITEMS_GO_TO_PAGE: 'LIST_DETAIL_ITEMS_GO_TO_PAGE',
  LIST_DETAIL_ITEMS_GO_TO_EXTERNAL_PAGE: 'LIST_DETAIL_ITEMS_GO_TO_EXTERNAL_PAGE',
  SPONSOR_APPOINTMENT_BOOK: 'SPONSOR_APPOINTMENT_BOOK',
  SPONSOR_APPOINTMENT_CANCEL: 'SPONSOR_APPOINTMENT_CANCEL',
  SPONSOR_APPOINTMENT_ADD_TO_CALENDAR: 'SPONSOR_APPOINTMENT_ADD_TO_CALENDAR',
  SPONSOR_DOWNLOAD_FILE: 'SPONSOR_DOWNLOAD_FILE',
  SPONSOR_GO_TO_PAGE: 'SPONSOR_GO_TO_PAGE',
  PROGRAM_APPOINTMENT_BOOK: 'PROGRAM_APPOINTMENT_BOOK',
  PROGRAM_APPOINTMENT_CANCEL: 'PROGRAM_APPOINTMENT_CANCEL',
  PROGRAM_APPOINTMENT_ADD_TO_CALENDAR: 'PROGRAM_APPOINTMENT_ADD_TO_CALENDAR',
  PROGRAM_DOWNLOAD_FILE: 'PROGRAM_DOWNLOAD_FILE',
  PROGRAM_GO_TO_PAGE: 'PROGRAM_GO_TO_PAGE',
  WORKSHOP_REGISTER: 'WORKSHOP_REGISTER',
  WORKSHOP_UNREGISTER: 'WORKSHOP_UNREGISTER',
  WORKSHOP_VIEW_LIVE: 'WORKSHOP_VIEW_LIVE',
  WORKSHOP_CLICK_LIVE: 'WORKSHOP_CLICK_LIVE',
  CTA_NETWORKING_ENABLE: 'CTA_NETWORKING_ENABLE',
  NETWORKING_INVITATION_ACCEPT: 'NETWORKING_INVITATION_ACCEPT',
  NETWORKING_INVITATION_IGNORE: 'NETWORKING_INVITATION_IGNORE',
  NETWORKING_CONNECT_TO_USER: 'NETWORKING_CONNECT_TO_USER',
  NETWORKING_ENABLE: 'NETWORKING_ENABLE',
  NETWORKING_DISABLE: 'NETWORKING_DISABLE',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  VIEW_FILE: 'VIEW_FILE',
  REPLAY_VIDEO_LAUNCHED: 'REPLAY_VIDEO_LAUNCHED',
  VIDEO_VIEW: 'VIDEO_VIEW',
  VIDEO_VIEW_SECTION: 'VIDEO_VIEW_SECTION',
  IMAGE_VIEW: 'IMAGE_VIEW',
  ITEM_VIDEO_VIEW: 'ITEM_VIDEO_VIEW',
  VIDEO_DOWNLOAD: 'VIDEO_DOWNLOAD',
  ITEM_VIDEO_DOWNLOAD: 'ITEM_VIDEO_DOWNLOAD',
  VIDEO_VIEW_TICK: 'VIDEO_VIEW_TICK',
  WEBSITE_CLICK: 'WEBSITE_CLICK',
  CTA_GO_TO: 'CTA_GO_TO',
  ITEM_CTA_GO_TO: 'ITEM_CTA_GO_TO',
  ITEM_CONTACT_CLICK: 'ITEM_CONTACT_CLICK',
  ITEM_DOWNLOAD_FILE: 'ITEM_DOWNLOAD_FILE',
  BUTTON_CLICK: 'BUTTON_CLICK',
  MENU_CLICK: 'MENU_CLICK',
  FILTER_MENU_CLICK: 'FILTER_MENU_CLICK',
  ITEM_ACTION_CLICK: 'ITEM_ACTION_CLICK',
  SHARE_SOCIAL_CLICK: 'SHARE_SOCIAL_CLICK',
  POST_COMMENT: 'POST_COMMENT',
  WEBINAR_ENTER: 'WEBINAR_ENTER',
};
