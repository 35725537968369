import keyBy from 'lodash/keyBy';
import partition from 'lodash/partition';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMount, useSetState } from 'react-use';
import { Button, DropdownItemProps, Form, LabelProps, Modal } from 'semantic-ui-react';

import GlobalModalManager, {
  ModalComponent,
} from '../../../components/GlobalModalManager/GlobalModalManager';
import notificationsService from '../../../core/services/notifications.service';
import { selectUsers } from '../../../core/users/users.select';
import { throttledFetch } from '../../../participants/ParticipantsScreen';
import { ConditionContext } from '../../../utils/conditionUtils';
import { useNotificationGroups } from '../../hooks/useNotificationGroups';
import {
  GroupOption,
  UserGroupOption,
  UserOption,
  useUserAndGroupOptions,
} from '../../hooks/useUserAndGroupOptions';
import { generateConditionForGroups } from '../../utils/groups.utils';

// eslint-disable-next-line @typescript-eslint/ban-types
type SendNotificationModalProps = {};

type NotificationTemplate = {
  title: string;
  body: string;
  to: string[];
};

const collections = ['participants'];

function buildSendTo(
  to: string[],
  options: UserGroupOption[],
): { type: 'condition'; condition: ConditionContext } | null {
  if (!to?.length) return null;

  const optionsById = keyBy(options, 'value');
  const filterOptions = to.map((v) => optionsById[v]);
  const [users, groups] = partition(filterOptions, (opt) => opt.type === 'user');
  return {
    type: 'condition',
    condition: generateConditionForGroups(
      (users as UserOption[]).map((u) => u.user),
      (groups as GroupOption[]).map((g) => g.group),
    ),
  };
}

function renderLabel(option: UserGroupOption): LabelProps {
  switch (option.type) {
    case 'user': {
      return {
        content: option.text,
        basic: true,
        color: 'blue',
        icon: 'user',
      };
    }
    case 'group': {
      return {
        content: option.text,
        detail: option.group.groupCategory,
        basic: true,
        color: 'red',
        icon: 'users',
      };
    }
    default: {
      return {
        content: (option as any).text,
      };
    }
  }
}

const SendNotificationModal = (({ onValidate, onCancel }) => {
  const { t } = useTranslation();
  const [notif, setNotif] = useSetState<NotificationTemplate>({ title: '', body: '', to: [] });
  const { users: participants = [] } = useSelector((state) => selectUsers(state, collections));
  const groups = useNotificationGroups();
  useMount(() => throttledFetch(collections));

  const options = useUserAndGroupOptions(participants, groups);

  const handleChange = (_e: any, { name, value }: any) => {
    setNotif({ [name]: value });
  };

  const handleSend = async () => {
    const res = await notificationsService.sendNotification({
      title: notif.title,
      body: notif.body,
      sendTo: buildSendTo(notif.to, options),
    });
    if (res.success) {
      onValidate(notif);
    }
  };

  return (
    <Modal open className="SendNotificationModal" onClose={onCancel}>
      <Modal.Header>{t('notifications.send.button')}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Select
            name="to"
            label={t('notifications.send.to')}
            value={notif.to}
            labeled
            options={options}
            lazyLoad
            multiple
            search
            deburr
            selectOnBlur={false}
            onChange={handleChange}
            placeholder={t('notifications.send.everyone')}
            renderLabel={renderLabel as (item: DropdownItemProps) => any}
          />
          <Form.Input
            name="title"
            autoFocus
            label={t('notifications.send.title')}
            value={notif.title}
            onChange={handleChange}
          />
          <Form.TextArea
            name="body"
            label={t('notifications.send.body')}
            rows={5}
            value={notif.body}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{t('btn.cancel')}</Button>
        <Button primary onClick={handleSend} disabled={!notif.title || !notif.body}>
          {t('btn.send')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}) as ModalComponent<SendNotificationModalProps, NotificationTemplate> & {
  open: () => Promise<NotificationTemplate | null>;
};

SendNotificationModal.open = () => {
  return GlobalModalManager.open(SendNotificationModal, {});
};

export default SendNotificationModal;
