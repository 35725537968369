import get from 'lodash/get';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { UserType } from '../../../core/users/users.types';
import i18n from '../../../translations/i18n';
import './UserInfoTab.scss';
import { UpdateProfile } from './actions/UpdateProfile';

const css = bem('UserInfoTab');

type Field = {
  key: string;
  label: string;
  type?: string;
  mapping?: any;
  editable?: boolean;
};

type UserInfoProps = {
  user: UserType;
  fields: Field[];
  enableEdition?: boolean;
  config?: Record<string, any>;
};

export const userDefaultFields: Field[] = [
  { key: 'firstName', label: i18n.t('users.field.firstName'), type: 'string', editable: true },
  { key: 'lastName', label: i18n.t('users.field.lastName'), type: 'string', editable: true },
  { key: 'email', label: i18n.t('users.field.email'), type: 'string', editable: true },
];

export const UserInfoTab: FC<UserInfoProps> = (props) => {
  const { t } = useTranslation();
  const { user, fields = userDefaultFields, config } = props;
  const enableEdition = fields.filter((f) => f.editable).length > 0;
  const handleEditProfile = async () => {
    await UpdateProfile.open({ user, config });
  };
  return (
    <div className={css()}>
      <div className={css('fields')}>
        {fields.map((field) => {
          const { key, label, mapping } = field;
          const userValue = get(user, key);
          const value = get(mapping, userValue, userValue);
          if (!value) return null;
          return (
            <div className={css('field', { key })}>
              <div className="label">{label}</div>
              <div className="value">{value}</div>
            </div>
          );
        })}
      </div>
      {enableEdition && (
        <Button className={css('edit').toString()} primary onClick={handleEditProfile}>
          <Icon name="pencil" />
          {t('profile-v2.actions.edit-profile')}
        </Button>
      )}
    </div>
  );
};
