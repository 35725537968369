import { useTranslation } from 'react-i18next';

import { bem } from '../../../core/design/bem';
import { useTeamRanking } from '../../hooks/leaderboard.hooks';
import './RankingByTeam.scss';

const translationPrefix = 'achievements.leaderboard.ranking-by-team';

const css = bem('RankingByTeam');

type Props = {
  field: string;
};

export const TeamRanking = (props: Props): JSX.Element => {
  const { field } = props;
  const { t } = useTranslation();
  const globalRanking = useTeamRanking(field);
  return (
    <div className={css()}>
      {globalRanking.map((user) => {
        const { teamId, userCount, rank, score } = user;
        return (
          <div className={css('Team')} key={teamId}>
            <div className="rank">{rank}</div>
            <div className="infos">
              <div className="team">{teamId}</div>
              <div className="userCount">
                {t(`${translationPrefix}.user-count`, { count: userCount })}
              </div>
            </div>
            <div className="score">{score}</div>
          </div>
        );
      })}
    </div>
  );
};
