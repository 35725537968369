/* eslint-disable import/no-cycle */
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Header } from 'semantic-ui-react';

import { isFinished } from '../../../utils/dateUtils';
import { generateGroups, generateWorkshopStatus } from '../../../utils/groupUtils';
import '../WorkshopList.scss';
import { WorkshopItems } from './WorkshopItems';
import { HorizontalLists } from './variants/HorizontalList';
import TabList from './variants/TabList';
import { VerticalLists } from './variants/VerticalList';

function getVariant(groupByConfig) {
  const { isVertical, variant } = groupByConfig;
  if (isVertical) return 'vertical';
  if (variant) return variant;
  return 'horizontal';
}

const listComponents = {
  vertical: VerticalLists,
  horizontal: HorizontalLists,
  tabs: TabList,
};

export function filterOptionsIfNeeded(groupByConfig, filters) {
  if (!filters) return groupByConfig;
  const { field, options } = groupByConfig;
  if (filters && filters[field] && options) {
    // Filter groups as well...
    return { ...groupByConfig, options: options.filter((opt) => opt.value === filters[field]) };
  }
  return groupByConfig;
}

export const filterRegistered = (workshops, registrations, showOnlyRegistered) => {
  if (!showOnlyRegistered) {
    return workshops;
  }

  return workshops.filter((w) => (w.sessions || [w]).some((s) => !!registrations[s._id]));
};

const WorkshopList = ({
  centered,
  filteredWorkshopIds,
  filters,
  orderBy: orderByConfig,
  groupBy: groupByConfig,
  itemProps,
  template: inputTemplate,
  workshopList,
}) => {
  const { t } = useTranslation();
  const workshops = generateWorkshopStatus(workshopList, itemProps.statusOptions);
  const template = {
    ...(inputTemplate || {}),
    variant: inputTemplate?.type || inputTemplate?.variant || 'card',
  };
  if (groupByConfig) {
    const variant = getVariant(groupByConfig);
    const { iconConfig, showPastBelowOthers } = groupByConfig || {};
    const ListComponent = listComponents[variant] || HorizontalLists;

    // eslint-disable-next-line no-inner-declarations
    function renderWorkshops(header, innerWorkshops) {
      if (!innerWorkshops?.length) return null;

      const groups = generateGroups(innerWorkshops, filterOptionsIfNeeded(groupByConfig, filters));
      return (
        <div className="items--group workshops--group">
          {header && <Header as="h2">{header}</Header>}
          <ListComponent
            centered={centered}
            groupByConfig={groupByConfig}
            orderByConfig={orderByConfig}
            groups={groups}
            filteredWorkshopIds={filteredWorkshopIds}
            template={template}
            iconConfig={iconConfig}
            itemProps={itemProps}
          />
        </div>
      );
    }

    if (showPastBelowOthers) {
      const [finished, upcoming] = partition(workshops, isFinished);
      return (
        <>
          {renderWorkshops('', upcoming)}
          {renderWorkshops(t('workshops.group.previous-content'), finished)}
        </>
      );
    }

    return renderWorkshops('', workshops);
  }

  return (
    <Card.Group className="workshops--container" itemsPerRow={3} centered={centered}>
      <WorkshopItems
        workshops={workshops}
        filteredWorkshopIds={filteredWorkshopIds}
        template={template}
        itemProps={itemProps}
      />
    </Card.Group>
  );
};

WorkshopList.defaultProps = {
  centered: undefined,
  filters: undefined,
  filteredWorkshopIds: undefined,
  groupBy: undefined,
  orderBy: [],
  itemProps: {},
  template: { variant: 'card' },
};

WorkshopList.propTypes = {
  workshopList: PropTypes.array.isRequired,
  centered: PropTypes.bool,
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.object,
  orderBy: PropTypes.array,
  groupBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  itemProps: PropTypes.object,
  template: PropTypes.shape({ variant: PropTypes.string, minHeight: PropTypes.number }),
};

export default WorkshopList;
