/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from 'react';
import { useMedia } from 'react-media';
import { Container, Grid } from 'semantic-ui-react';

import { CmsPageLayout } from '../../../cms/CmsPage';
import { TabbedMenu } from '../../../components/TabbedMenu/TabbedMenu';
import { useScreenConfig } from '../../../config/screens.context';
import { bem } from '../../../core/design/bem';
import useUrlState from '../../../hooks/useUrlState';
import i18n from '../../../translations/i18n';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import { GlobalRanking } from '../tabs/GlobalRanking';
import { TeamRanking } from '../tabs/RankingByTeam';
import { UserRanking } from '../tabs/UserRanking';
import { UserRankingInOwnTeam } from '../tabs/UserRankingInOwnTeam';
import './LeaderboardPage.scss';

const translationPrefix = 'achievements.leaderboard';
const css = bem('Leaderboard');

type LeaderboardPageProps = {
  design?: Record<string, any>;
  metadata?: Record<string, any>;
  theme?: string;
  pageId: string;
  pageFooter?: Record<string, any>;
  pageHeader?: Record<string, any>;
};

const defaultProps = {
  design: {},
  pageFooter: undefined,
  pageHeader: undefined,
  metadata: undefined,
  theme: undefined,
};

const defaultTabs: any = {
  all: { enabled: true, title: i18n.t(`${translationPrefix}.tabs.all`), component: GlobalRanking },
  teams: {
    enabled: false,
    title: i18n.t(`${translationPrefix}.tabs.teams`),
    component: TeamRanking,
  },
  myteam: {
    enabled: false,
    title: i18n.t(`${translationPrefix}.tabs.my-team`),
    component: UserRankingInOwnTeam,
  },
  profile: {
    enabled: true,
    title: i18n.t(`${translationPrefix}.tabs.profile`),
    component: UserRanking,
  },
};

export const LeaderboardPage = (props: LeaderboardPageProps): JSX.Element => {
  const { design, metadata, pageFooter, pageHeader, pageId, theme } = props;
  const screen = useScreenConfig(pageId);
  const { tabs = defaultTabs, showTopThree, teamBy, subtitleTemplate } = screen;
  const tabKeys = Object.keys(tabs);
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const menuTabs = useMemo(
    () => tabKeys.map((tab) => ({ ...tabs[tab], name: tab })).filter((t) => t.enabled),
    [tabKeys, tabs],
  );
  const desktopMenus = menuTabs.filter((t) => t.name !== 'profile');
  const [urlState, setUrlState] = useUrlState({ activeTab: menuTabs[0]?.name || undefined });
  const { activeTab } = urlState;
  const Component = defaultTabs[activeTab]?.component;
  const common = { showTopThree, subtitleTemplate, field: teamBy };

  const handleClick = (_e: any, { name }: { name: string }) => {
    setUrlState({ activeTab: name });
  };

  useEffect(() => {
    if (!mobile && activeTab === 'profile') {
      if (desktopMenus.length > 0) {
        setUrlState({ activeTab: menuTabs[0]?.name });
      }
    }
  }, [mobile, activeTab]);
  console.log('css', css('Menu').toString());
  return (
    <CmsPageLayout
      className={css().toString()}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      metadata={metadata}
      designOverride={design}
      theme={theme}
      fullheight
    >
      <Container>
        {!mobile && (
          <Grid
            columns={desktopMenus.length > 0 ? 2 : 1}
            stretched
            className={css('Grid').toString()}
          >
            <Grid.Column>
              <UserRanking {...common} />
            </Grid.Column>
            {desktopMenus.length > 0 && (
              <Grid.Column>
                <TabbedMenu
                  activeTab={activeTab}
                  tabs={desktopMenus}
                  onClick={handleClick}
                  className={css('Menu').toString()}
                />
                {Component && <Component {...common} />}
              </Grid.Column>
            )}
          </Grid>
        )}
        {mobile && (
          <>
            {menuTabs.length > 1 && (
              <TabbedMenu
                activeTab={activeTab}
                tabs={menuTabs}
                onClick={handleClick}
                className={css('Menu').toString()}
              />
            )}
            {Component && <Component {...common} {...(tabs[activeTab] ?? {})} />}
          </>
        )}
      </Container>
    </CmsPageLayout>
  );
};

LeaderboardPage.defaultProps = defaultProps;
