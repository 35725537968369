import { UserType } from '../../core/users/users.types';
import store from '../../shared/Store';
import { replaceValues } from '../../utils/stringUtils';

const { endpoints } = window.__DATA__;

export function getUserBadgeUri(badge: Record<string, any>, user: UserType): string {
  if (!badge) return '';
  const { templateUrl } = badge;
  const badgeUrl = `${endpoints.document}/${store.eventId}/badges/users/${user.collection}/${user._id}/badge.pdf`;
  if (templateUrl) {
    return replaceValues(templateUrl, { url: badgeUrl });
  }
  return badgeUrl;
}
