/* eslint-disable consistent-return */
import keyBy from 'lodash/keyBy';
import noop from 'lodash/noop';
import uniqBy from 'lodash/uniqBy';
import { FC, useMemo, useState } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { Icons } from '../../../components/Icons/material-icon.types';
import { bem } from '../../../core/design/bem';
import platformService from '../../../core/services/platform.service';
import { trackEvent } from '../../../core/trackers';
import { eventTags } from '../../../core/trackers/events';
import store from '../../../shared/Store';
import ResetPasswordModal from '../../components/ResetPasswordModal';
import { getUserBadgeUri } from '../utils';
import './ActionsTab.scss';
import { FlightTickets } from './actions/FlightTickets';
import { QRcode } from './actions/QRcode';
import { UpdateProfile } from './actions/UpdateProfile';
import { disconnectAction, userActions } from './data';
import { ActionTabProps, UserAction } from './types';

const css = bem('ActionsTab');

const defaultActions = userActions.filter((u) => u.default);
const actionsByKey = keyBy(userActions, 'key');

export const ActionsTab: FC<ActionTabProps> = ({ config, user, actions = defaultActions }) => {
  const tabActions = useMemo(
    () =>
      uniqBy(
        [
          ...actions.map((action) => ({
            ...actionsByKey[action.key],
            ...action,
          })),
          disconnectAction,
        ],
        'key',
      ),
    [actions],
  );
  const { qrcode, file } = config || {};
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handleAction = async (action: UserAction) => {
    switch (action.key) {
      case 'update-password':
        setShowPasswordModal(true);
        break;
      case 'edit-profile':
        await UpdateProfile.open({ user, config });
        break;
      case 'download-badge': {
        const badgeUrl = getUserBadgeUri(qrcode?.badge, user);
        if (badgeUrl) {
          window.open(badgeUrl, '_blank');
        }
        break;
      }
      case 'display-qrcode':
        await QRcode.open({ user, qrcode });
        break;
      case 'download-plane-tickets': {
        await FlightTickets.open({ user, file });
        break;
      }
      case 'download-agenda': {
        window.open(platformService.buildUserAgendaICSUrl(user._id), '_blank');
        break;
      }
      case 'download-file': {
        if (action.url) {
          window.open(action.url, '_blank');
        }
        break;
      }
      case 'disconnect': {
        store.disconnect();
        window.location.reload();
        break;
      }
      default:
        return noop();
    }
  };

  const trackNetworkingStatus = (status?: boolean) => {
    const tag = status ? eventTags.NETWORKING_ENABLE : eventTags.NETWORKING_DISABLE;
    trackEvent(tag, {
      userId: store.userId,
    });
  };

  const handleActivateNetworking = (_e: any, { checked }: CheckboxProps) => {
    trackNetworkingStatus(checked);
    store.updateUser({ networking: checked });
  };

  return (
    <div className={css()}>
      {tabActions.map((action) => {
        const { key, icon, label } = action;
        return (
          <div className={css('Action', { key })} onClick={() => handleAction(action)}>
            {icon && <MaterialIcon size={20} name={icon as Icons} />}
            <span className="label">{label}</span>
            {key === 'enable-networking' && (
              <Checkbox toggle onClick={handleActivateNetworking} checked={user.networking} />
            )}
          </div>
        );
      })}
      {showPasswordModal && <ResetPasswordModal onClose={() => setShowPasswordModal(false)} />}
    </div>
  );
};
