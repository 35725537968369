/* eslint-disable operator-linebreak */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { areIntervalsOverlapping } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';

import { userAgenda } from '../../../agenda/store/agenda.selectors';
import BlockContainer from '../../../components/BlockContainer';
import { ContainerProps } from '../../../components/cms/types';
import { bem } from '../../../core/design/bem';
import { matchesWithArray } from '../../../core/filter/utils';
import { formatTimeRanges } from '../../../home/blocks/CloudTvBlock/cloudtv.hooks';
import { Workshop } from '../../../types/workshop.types';
import { checkUserAvailability } from '../../../utils/agendaUtils';
import { useSyncedCollectionWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';
import { useSyncedCollectionWorkshops } from '../../store/workshops.hooks';
import './SuggestedWorkshops.scss';
import { CardVariant } from './variants/CardVariant';
import { ListVariant } from './variants/ListVariant';

const translationPrefix = 'workshops.workshop.suggested-sessions';
const css = bem('SuggestedWorkshops');

type Props = {
  _id: string;
  container: ContainerProps;
  fieldsToMatch?: Record<string, any>;
  item: Workshop;
  maxItems?: number;
  variant: 'list' | 'card';
};

function areTwoEventsOverlapping(event: Workshop, selectedEvent: Workshop) {
  const leftInterval = formatTimeRanges(event);
  const rightInterval = formatTimeRanges(selectedEvent);
  return rightInterval && leftInterval && areIntervalsOverlapping(leftInterval, rightInterval);
}

function useSuggestedSessions(workshop: Workshop, fieldsToMatch?: Record<string, any>) {
  const allSessions = useSyncedCollectionWorkshopSessions(workshop.collection) as Workshop[];
  const allWorkshops = useSyncedCollectionWorkshops(workshop.collection) as Workshop[];
  const hasSessions = allSessions.length > 0;
  const items = hasSessions ? allSessions : allWorkshops;
  const registrations = useSelector(
    (state: any) =>
      state.registrations[hasSessions ? 'registrationsBySessionId' : 'registrationsById'],
  );
  const agenda = useSelector(userAgenda);

  return useMemo(() => {
    if (isEmpty(fieldsToMatch)) return [];
    const workshopSessions = items.filter((s) =>
      hasSessions ? s.workshopId === workshop._id : s._id === workshop._id,
    );
    const matchObject = pick(workshop, fieldsToMatch);
    const matchedSessions = items.filter(matchesWithArray(matchObject));
    return orderBy(
      matchedSessions.filter(
        (s) =>
          (hasSessions
            ? s.workshopId !== workshop._id
            : s._id !== workshop._id) /** hide other workshop sessions **/ &&
          !s.mandatory /** hide mandatory sessions */ &&
          (!s.quota ||
            (s.quota > 0 && s.usersCount < s.quota)) /** show only available sessions */ &&
          (s.startDate && s.endDate
            ? !(s._id in registrations) /** hide sessions to which user is already registered */ &&
              !areTwoEventsOverlapping(s, workshopSessions[0]) /** hide overlapping sessions */ &&
              checkUserAvailability(agenda, s) /** show only sessions to which user can register */
            : true),
      ),
      ['startDate', 'endDate'],
      ['asc', 'asc'],
    );
  }, [fieldsToMatch, items, workshop, hasSessions, registrations, agenda]);
}

const components = {
  list: ListVariant,
  card: CardVariant,
};

export const SuggestedWorkshops = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { item, container, fieldsToMatch, maxItems, variant = 'list' } = props;
  const sessions = useSuggestedSessions(item, fieldsToMatch);
  if (sessions.length === 0) return null;
  const finalSessions = !!maxItems && maxItems > 0 ? sessions.slice(0, maxItems) : sessions;
  const Component = components[variant];

  return (
    <BlockContainer className={css()} {...container}>
      <Header as="h2">{t(`${translationPrefix}.header`)}</Header>
      <Component workshops={finalSessions} {...props} />
    </BlockContainer>
  );
};

SuggestedWorkshops.defaultProps = {
  fieldsToMatch: {},
  maxItems: undefined,
};
