import { orderBy } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';

import socialWallService from '../../core/services/social-wall.service';
import { Media, Publication, UpdatedPublication } from '../types/SocialWallV2.types';

type UseSocialWallPublications = {
  publications: Publication[];
  createPublication: (message: string, media?: Media) => Promise<Publication | undefined>;
  deletePublication: (messageId: string) => Promise<void>;
  editPublication: (
    initPublication: Publication,
    newPublication: { message?: string; media?: Media; thumbnail?: Media },
  ) => Promise<Publication | undefined>;
};

const REFETCH_INTERVAL = 20000;

export const useSocialWallPublications = (
  publicationEnabled?: boolean,
): UseSocialWallPublications => {
  const queryClient = useQueryClient();

  const { data = [] } = useQuery(
    ['social-wall', 'publications'],
    async () => {
      const publications = await socialWallService.getPublished();
      //TO DO: orderedBy publishedAt when key will be available
      return orderBy(publications, 'createdAt', 'desc');
    },
    {
      refetchInterval: REFETCH_INTERVAL,
    },
  );

  const createPublication = async (
    message: string,
    media?: Media,
    thumbnail?: Media,
  ): Promise<Publication | undefined> => {
    if (!publicationEnabled) return undefined;
    const postMessage = await socialWallService.postMessage({
      message,
      media,
      thumbnail,
    });

    queryClient.invalidateQueries({ queryKey: ['social-wall', 'publications'] });

    return postMessage;
  };

  const deletePublication = async (publicationId: string): Promise<void> => {
    await socialWallService.deleteMessage(publicationId);
    queryClient.invalidateQueries({ queryKey: ['social-wall', 'publications'] });
  };

  const editPublication = async (
    initPublication: Publication,
    newPublication: UpdatedPublication,
  ) => {
    if (!initPublication) return undefined;

    const { message, media, thumbnail } = newPublication;

    const patchMessage = {
      ...initPublication,
      data: {
        content: message,
        media,
        thumbnail,
      },
    };

    const { success } = await socialWallService.patchMessage(initPublication._id, newPublication);
    if (!success) return undefined;

    // Invalidate query to launch getPosts
    queryClient.invalidateQueries({ queryKey: ['social-wall', 'publications'] });
    return patchMessage;
  };
  return {
    publications: data,
    createPublication,
    deletePublication,
    editPublication,
  };
};

type UseSocialWallPublication = {
  publication: Publication;
  deletePost: () => Promise<void>;
  editPost: (newPublication: {
    message?: string;
    media?: Media;
    thumbnail?: Media;
  }) => Promise<Publication | undefined>;
};
export const useSocialWallPublication = (publicationId: string): UseSocialWallPublication => {
  const { publications, deletePublication, editPublication } = useSocialWallPublications();
  const publication = publications.find((p) => p._id === publicationId) as Publication;

  const deletePost = async () => {
    await deletePublication(publicationId);
  };

  const editPost = async (newPublication: {
    message?: string;
    media?: Media;
    thumbnail?: Media;
  }) => {
    return editPublication(publication, newPublication);
  };
  return { publication, deletePost, editPost };
};
