import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Modal } from 'semantic-ui-react';

import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../components/ConfirmationModal/ConfirmationModal';
import GlobalModalManager from '../../../components/GlobalModalManager';
import { useBackButton } from '../../../hooks/useBackButton';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import { useSocialWallEmotes } from '../../hooks/useSocialWallEmotes';
import {
  useSocialWallPublication,
  useSocialWallPublications,
} from '../../hooks/useSocialWallPublications';
import { useSocialWallPublicationsComments } from '../../hooks/useSocialWallPublicationsComments.hook';
import { SocialWallConfigProvider } from '../../social-wall-config.context';
import { Media, SocialWallConfigEntry } from '../../types/SocialWallV2.types';
import { SocialWallPublicationModal } from '../SocialWallPublicationModal';
import { DesktopDetailsPublication } from './DesktopDetailsPublication';
import './DetailedPublicationModal.scss';
import { MobileDetailsPublication } from './MobileDetailsPublication';

interface DetailedPublicationModalProps {
  publicationId: string;
  userId: string;
  userCollection: string;
  onCancel?: () => void;
  config: SocialWallConfigEntry;
}

export const DetailedPublicationModal = ({
  publicationId,
  onCancel,
  userId,
  userCollection,
  config,
}: DetailedPublicationModalProps): JSX.Element => {
  const { publications } = useSocialWallPublications();
  const [selectedPublicationId, setPublicationId] = useState(publicationId);

  const currentPublicationIndex = useMemo(
    () => publications.findIndex((post) => post._id === selectedPublicationId),
    [selectedPublicationId, publications],
  );

  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  const mobile = useMedia(GLOBAL_MEDIA_QUERIES.mobile);
  const {
    comments,
    addPublicationComment,
    deletePublicationComment,
  } = useSocialWallPublicationsComments(selectedPublicationId);

  const { publication, deletePost, editPost } = useSocialWallPublication(selectedPublicationId);

  const { toggleInteraction, userInteractions } = useSocialWallEmotes({ userId, userCollection });

  const onDeletePublication = async () => {
    const confirm = await GlobalModalManager.open(ConfirmationModal, {
      header: <span>{t('social-wall.publication-deletion')}</span>,
      children: <span>{t('social-wall.confirm-publication-deletion')}</span>,
    } as ConfirmationModalProps);
    if (!confirm) return;
    await deletePost();

    if (onCancel) onCancel();
  };

  const goNext = () => {
    const nextId = (publications[currentPublicationIndex + 1] || publications[0])._id;
    setPublicationId(nextId);
  };
  const goPrev = () => {
    const nextId = (
      publications[currentPublicationIndex - 1] || publications[publications.length - 1]
    )._id;
    setPublicationId(nextId);
  };

  const onEditPublication = async () => {
    if (onCancel) onCancel();
    await GlobalModalManager.open(SocialWallPublicationModal, {
      initialPublication: publication,
      createPublication: async (message?: string, media?: Media) => {
        const confirm = await GlobalModalManager.open(ConfirmationModal, {
          header: <span>{t('social-wall.publication-edition')}</span>,
          children: <span>{t('social-wall.confirm-publication-edition')}</span>,
          buttonOptions: {
            color: 'blue',
          },
        } as ConfirmationModalProps);
        if (!confirm) return undefined;

        return editPost({ message, media });
      },
    });
  };

  const onDeleteComment = async (commentId: string) => {
    const confirm = await GlobalModalManager.open(ConfirmationModal, {
      header: <span>{t('social-wall.comment-deletion')}</span>,
      children: <span>{t('social-wall.confirm-comment-deletion')}</span>,
    } as ConfirmationModalProps);
    if (!confirm) return;
    await deletePublicationComment(commentId);
  };

  const PublicationComponent = mobile ? MobileDetailsPublication : DesktopDetailsPublication;

  const onClose = onCancel || (() => setOpen(false));
  useBackButton(onClose);
  return (
    <SocialWallConfigProvider {...config}>
      <Modal
        open={open}
        className={`publication-details publication-details--${mobile ? 'mobile' : 'desktop'}`}
        onClose={onClose}
        dimmer={{ className: mobile ? 'animated-modal' : '' }}
      >
        <PublicationComponent
          publication={publication}
          comments={comments}
          onClose={onClose}
          onDelete={onDeletePublication}
          onEdit={onEditPublication}
          onAddComment={addPublicationComment}
          onDeleteComment={onDeleteComment}
          userId={userId}
          onInteractionClick={(emote) => toggleInteraction(selectedPublicationId, emote)}
          userInteractions={userInteractions[selectedPublicationId]}
          onNext={goNext}
          onPrev={goPrev}
        />
      </Modal>
    </SocialWallConfigProvider>
  );
};

DetailedPublicationModal.open = async (
  props: DetailedPublicationModalProps,
): Promise<boolean | null> => {
  return GlobalModalManager.open(DetailedPublicationModal, props);
};
