import React, { FC } from 'react';

import { videoCDN } from '../../../../utils/videoUtils';
import CdnImage from '../../../CdnImage';
import { Image, Video } from '../../types';

type MediaProps = {
  image?: Image;
  type: string;
  video?: Video;
};

const Media: FC<MediaProps> = (props): JSX.Element | null => {
  const { type, video, image } = props;
  switch (type) {
    case 'video': {
      if (!video?.uri) {
        return null;
      }
      return (
        <video key="video" muted autoPlay loop>
          <source src={videoCDN(video.uri)} type="video/mp4" />
        </video>
      );
    }
    case 'image': {
      return <CdnImage src={image} maxWidth={1920} />;
    }
    default:
      return null;
  }
};

Media.defaultProps = {
  image: undefined,
  video: undefined,
};

export default Media;
