import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useSyncedWorkshopSessions } from '../../workshop-session/store/workshopSessions.hooks';
import { useSyncedWorkshops } from '../../workshops/store/workshops.hooks';
import { userAgenda } from './agenda.selectors';

export function getAgendaWorkshops(agenda, workshops, sessions) {
  const events = agenda.filter(({ type }) => type === 'session' || type === 'workshop');
  const sessionsById = keyBy([...workshops, ...sessions], '_id');
  return events.map((event) => ({ ...event, ...sessionsById[event._id] }));
}
export function useFreshAgendaWorkshops() {
  const workshops = useSyncedWorkshops();
  const sessions = useSyncedWorkshopSessions();
  const agenda = useSelector(userAgenda);

  return useMemo(() => getAgendaWorkshops(agenda, workshops, sessions), [
    agenda,
    workshops,
    sessions,
  ]);
}
