import React, { FC, useContext, useMemo } from 'react';

interface SocialWallConfigProps {
  comments: {
    enabled: boolean;
  };
  publications: {
    enabled: boolean;
  };
  interactions: {
    enabled: boolean;
    defaultEmotes?: string[];
  };
}
interface SocialWallConfigType {
  commentsEnabled: boolean;
  publicationsEnabled: boolean;
  interactionsEnabled: boolean;
  defaultInteractions: string[];
}

export const SocialWallConfig = React.createContext<SocialWallConfigType>({
  commentsEnabled: true,
  publicationsEnabled: true,
  interactionsEnabled: true,
  defaultInteractions: [],
});

export const SocialWallConfigProvider: FC<SocialWallConfigProps> = ({
  children,
  comments,
  publications,
  interactions,
}) => {
  const value = useMemo(
    () => ({
      commentsEnabled: comments.enabled,
      publicationsEnabled: publications.enabled,
      interactionsEnabled: interactions.enabled,
      defaultInteractions: interactions.defaultEmotes || [],
    }),
    [comments, publications, interactions],
  );
  return <SocialWallConfig.Provider value={value}>{children}</SocialWallConfig.Provider>;
};

export function useSocialWallConfig(): SocialWallConfigType {
  return useContext(SocialWallConfig);
}
