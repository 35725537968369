import PropTypes from 'prop-types';
import React from 'react';
import { Header } from 'semantic-ui-react';

import WorkshopLogos from '../../../../components/workshops/WorkshopLogos';
import EntityTags from './EntityTags';
import SessionDates from './SessionDates';

const Details = ({ session, config, registration, workshopSessions }) => {
  const { logos, title } = session;
  const {
    tagFields = [],
    logosProps,
    showDuration,
    showLocation,
    showSessions = true,
    showQuota,
    filterDates = false,
  } = config;
  return (
    <>
      <WorkshopLogos logos={logos} {...logosProps} />
      <Header as="h2" className="title">
        {title}
      </Header>
      {showSessions && (
        <SessionDates
          workshopSessions={workshopSessions}
          registration={registration}
          showDuration={showDuration}
          showLocation={showLocation}
          showQuota={showQuota}
          filterDates={filterDates}
        />
      )}
      <EntityTags tagFields={tagFields} entity={session} type="workshop" />
    </>
  );
};

Details.defaultProps = {
  registration: undefined,
  workshopSessions: [],
};

Details.propTypes = {
  config: PropTypes.object.isRequired,
  registration: PropTypes.object,
  session: PropTypes.object.isRequired,
  workshopSessions: PropTypes.arrayOf(PropTypes.object),
};

export default Details;
