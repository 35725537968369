/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Menu, SemanticICONS, SemanticWIDTHSNUMBER } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import ImageIcon from '../../ImageIcon';

const videoSizer = bem('VideoSizer');

type SidebarTab = {
  key: string;
  field: string;
  title: string;
  icon?: SemanticICONS;
  url?: string;
  iconOptions?: Record<string, any>;
};

type VideoSidebarTabsProps = {
  tabs: SidebarTab[];
  activeIndex: number;
  onChange: (tabIndex: number) => void;
  config?: Record<string, any>;
};

type VideoSidebarProps = {
  open: boolean;
  tabsAlwaysVisible?: boolean;
  tabs: SidebarTab[];
  config?: Record<string, any>;
};

const VideoSidebarTabs = ({
  tabs,
  activeIndex,
  onChange,
  config,
}: VideoSidebarTabsProps): JSX.Element => {
  const { iconOptions } = config || {};
  return (
    <Menu attached="bottom" widths={tabs.length as SemanticWIDTHSNUMBER}>
      {tabs.map((tab, index) => (
        <Menu.Item
          key={tab.key || index}
          name={tab.key || tab.title}
          active={index === activeIndex}
          onClick={() => onChange(index)}
        >
          {tab.icon && <ImageIcon className="icon" icon={tab.icon} {...iconOptions} />}
          <div className="title">{tab.title}</div>
        </Menu.Item>
      ))}
    </Menu>
  );
};

VideoSidebarTabs.defaultProps = {
  config: {},
};

const VideoSidebar = ({
  open,
  tabsAlwaysVisible,
  tabs,
  config,
}: VideoSidebarProps): JSX.Element | null => {
  const [tabIndex, setTab] = useState(0);
  if (tabs.length === 0) return null;
  const safeTabIndex = tabIndex >= tabs.length ? 0 : tabIndex;
  const tab = tabs[safeTabIndex];

  return (
    <div className={videoSizer('tab', { showTab: open, hasTabs: tabs.length > 1 }).toString()}>
      {(tabs.length > 1 || tabsAlwaysVisible) && (
        <VideoSidebarTabs
          tabs={tabs}
          activeIndex={safeTabIndex}
          onChange={setTab}
          config={config}
        />
      )}
      {tab && (
        <iframe
          key={tab.key || tab.url}
          className="block--video__qa"
          title={tab.title}
          src={tab.url}
        />
      )}
    </div>
  );
};

VideoSidebar.defaultProps = {
  tabsAlwaysVisible: false,
  config: {},
};

export default VideoSidebar;
