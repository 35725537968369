/* eslint-disable max-len */
import { lighten } from '@wessberg/color';

export const COLOR_REGEX = /^((#(?:[0-9a-fA-F]{3}){1,2}$)|rgb\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)\)|rgba\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|0?\.\d|1(\.0)?)\)|hsl\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d),(0|100|\d{1,2})%,(0|100|\d{1,2})%\)|hsla\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d),(0|100|\d{1,2})%,(0|100|\d{1,2})%,(0?\.\d|1(\.0)?)\))$/;

const alphaHex = (alphaLevel: number): string => {
  return Math.floor(alphaLevel * 255)
    .toString(16)
    .padStart(2, '0');
};

export const isValidColor = (color: string): boolean => {
  return COLOR_REGEX.test(color);
};

export const setColorAlpha = (color: string, alphaLevel: number): string | null => {
  if (!color) return null;
  return color
    .replace(/rgb\((.*)\)/, (_all, group1) => `rgba(${group1}, ${alphaLevel})`)
    .replace(/(rgba\(.*),[^,]+\)/, (_all, group1) => `rgba(${group1}, ${alphaLevel})`)
    .replace(/(#......)/, (_all, group1) => `${group1}${alphaHex(alphaLevel)}`);
};

export const generateColorsVariant = (
  colors: Record<string, string>,
  options?: {
    brightnesses?: Record<string, number>;
    opacities?: number[];
  },
): Record<string, string> => {
  const { brightnesses, opacities } = options || {};
  const finalColors = {} as Record<string, string>;

  Object.entries(colors).forEach(([colorKey, color]) => {
    if (!color) return;
    const colorIsValid = isValidColor(color.replace(' ', ''));
    const editedColor = colorIsValid ? color.trim() : '#000000';
    finalColors[colorKey] = editedColor;

    if (brightnesses) {
      Object.entries(brightnesses).forEach(([key, brightness]) => {
        finalColors[`${colorKey}-${key}`] = lighten(editedColor, brightness);
      });
    }
    if (opacities) {
      opacities.forEach((colorOpacity) => {
        finalColors[`${colorKey}-${colorOpacity * 100}`] = setColorAlpha(
          editedColor,
          colorOpacity,
        ) as string;
      });
    }
  });
  return finalColors;
};
