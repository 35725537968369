import React, { FC } from 'react';
import { Modal } from 'semantic-ui-react';

import AppointmentsBlock from '../../appointments/AppointmentsBlock';
import { getString } from '../../utils';

const translationPrefix = 'appointments.modal';

type AppointmentUserRef = { _id: string; collection: string; name: string };
type HalfDays = {
  duration: number;
  endDate: string;
  label: string;
  startDate: string;
};
type Day = { header: string; halfDays: HalfDays };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Appointment = {
  days: Day[];
  [x: string]: any;
};

type AppointmentsModalProps = {
  appointmentUserRef: AppointmentUserRef;
  appointment: Appointment;
  extraBookingData: Record<string, any>;
  bookingType?: string;
  onCancel: () => void;
  slotFilters: Record<string, string>;
};

const AppointmentsModal: FC<AppointmentsModalProps> = (props: AppointmentsModalProps) => {
  const {
    bookingType,
    extraBookingData,
    onCancel,
    slotFilters,
    appointment,
    appointmentUserRef,
  } = props;
  const { name } = appointmentUserRef;
  return (
    <Modal
      open
      className="modal--AppointmentsModal"
      size="large"
      closeOnEscape={false}
      closeIcon
      onClose={onCancel}
      closeOnDimmerClick={false}
    >
      <Modal.Header>{getString(`${translationPrefix}.appointment-with`, { name })}</Modal.Header>
      <Modal.Content scrolling style={{ maxHeight: '70vh' }}>
        <AppointmentsBlock
          {...appointment}
          slotFilters={slotFilters}
          appointmentUserRef={appointmentUserRef}
          extraBookingData={extraBookingData}
          onCancel={onCancel}
          bookingType={bookingType}
        />
      </Modal.Content>
    </Modal>
  );
};

AppointmentsModal.defaultProps = {
  bookingType: undefined,
};

export default AppointmentsModal;
