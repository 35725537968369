import cx from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import GlobalModalManager from '../../../../components/GlobalModalManager';
import { QueryModal } from '../../../../components/QueryModal/QueryModal';
import { UserAvatarImageUploader } from '../../../../components/UserAvatarImageUploader/UserAvatarImageUploader';
import { bem } from '../../../../core/design/bem';
import { UserType } from '../../../../core/users/users.types';
import store from '../../../../shared/Store';
import { GLOBAL_MEDIA_QUERIES } from '../../../../utils/mediaQueries';
import { sweetAlert } from '../../../../utils/popupUtils';
import FieldInput from '../../../components/FieldInput';
import { expandField } from '../../../components/UserProfileDetail/UserProfileDetail';
import { userDefaultFields } from '../UserInfoTab';
import './UpdateProfile.scss';

const css = bem('UpdateProfile');

type UpdateProfileStatic = {
  open: (props: any) => Promise<boolean | null>;
};

export type UpdateProfileProps = {
  user: UserType;
  config: Record<string, any>;
  onCancel: () => void;
};

type UserField = {
  key: string;
  label: string;
  editable?: boolean;
};

const SaveButton = ({ onSave, hasChanged }: { onSave: () => void; hasChanged: boolean }) => {
  const { t } = useTranslation();
  return (
    <Button disabled={!hasChanged} className={css('save').toString()} primary onClick={onSave}>
      <Icon name="save" />
      {t('btn.save')}
    </Button>
  );
};

export const UpdateProfile: FC<UpdateProfileProps> & UpdateProfileStatic = (props) => {
  const { t } = useTranslation();
  const [patch, setPatch] = useState({});
  const { user, config, onCancel, ...restProps } = props;
  const { avatarConfig, tabs } = config || {};
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const userFields = (tabs?.infos?.fields ?? userDefaultFields) as UserField[];
  const finalUser = { ...user, ...patch };
  const hasChanged = !isEqual(user, finalUser);

  const handleChange = (update: Record<string, any>) => {
    setPatch({ ...patch, ...update });
  };

  const handleSave = async () => {
    try {
      await store.updateUser(patch);
      onCancel();
      await sweetAlert({
        text: t('profile.confirm.update-success'),
        icon: 'success',
      });
    } catch (e) {
      await sweetAlert({
        text: t('profile.errors.update-profile'),
        icon: 'error',
      });
    }
  };

  return (
    <QueryModal
      className={cx('Action', 'UpdateProfile')}
      draggable
      open
      closeIcon
      scrolling
      actions={
        <Modal.Actions>
          <SaveButton onSave={handleSave} hasChanged={hasChanged} />
        </Modal.Actions>
      }
      onCancel={onCancel}
      initialStep={90}
      {...restProps}
    >
      <div className={css('avatar-uploader')}>
        <UserAvatarImageUploader
          onChange={(value: Record<string, any>) => handleChange({ thumbnail: value })}
          value={user.thumbnail}
          avatarConfig={avatarConfig}
        />
      </div>
      <div className={css('fields')}>
        <Form>
          {userFields
            .filter((f) => f.editable)
            .map((field) => {
              const { key, label, required, mapping } = expandField(field);
              const userValue = get(finalUser, key);
              const displayValue = get(mapping, userValue, userValue);
              if (!displayValue) return null;
              return (
                <FieldInput
                  field={field}
                  label={label}
                  value={displayValue}
                  editable
                  error={required && !displayValue}
                  onChange={handleChange}
                />
              );
            })}
        </Form>
      </div>
      {mobile && <SaveButton onSave={handleSave} hasChanged={hasChanged} />}
    </QueryModal>
  );
};

UpdateProfile.open = async (props: UpdateProfileProps): Promise<boolean | null> => {
  return GlobalModalManager.open(UpdateProfile, props);
};
