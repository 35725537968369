import PropTypes from 'prop-types';
import { Card, Label } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { useComponentOptions } from '../../config/design.context';
import { eventTags } from '../../core/trackers/events';
import { getEntityString } from '../../utils';
import { replaceValues } from '../../utils/stringUtils';
import CdnImage from '../CdnImage';
import EntityLink from '../EntityLink';
import { ExhibitorFavorite } from './ExhibitorFavorite';
import './SponsorCard.scss';

export function getSponsorString(sponsor, key) {
  return getEntityString(sponsor, 'sponsor', key);
}

const defaultTags = [
  {
    field: 'category',
    icon: 'trophy',
  },
];

const SponsorTag = (props) => {
  const { tag = {}, item } = props;
  const { icon = 'trophy' } = tag;
  const content = getSponsorString(item, tag.field);

  if (!content) {
    return null;
  }

  return <Label icon={icon} className={`${icon}--${content}`} content={content} />;
};

const SponsorCard = ({ item, tagFields, headerTemplate, allowFavorites }) => {
  const { trackEvent } = useTracking();
  const { _id, collection, shortDescription = '', logo, name } = item;
  const tags = tagFields?.length ? tagFields : defaultTags;
  const imageProps = useComponentOptions('company-logos');

  const computedName = headerTemplate ? replaceValues(headerTemplate, item) : name;
  return (
    <Card
      className={`card--sponsor ${collection}--${_id}`}
      as={EntityLink}
      entity={item}
      onClick={() => trackEvent(eventTags.SPONSOR_GO_TO_PAGE, { item })}
    >
      <ExhibitorFavorite exhibitor={item} allowFavorites={allowFavorites} />
      {logo && (
        <Card.Content style={{ flexGrow: 0 }} className="logo">
          <CdnImage
            as="img"
            src={logo}
            alt="logo"
            className="logo"
            maxHeight={90}
            {...imageProps}
          />
        </Card.Content>
      )}
      <Card.Content className="information">
        <Card.Header> {computedName}</Card.Header>
        <Card.Description>
          {tags.map((tag) => (
            <SponsorTag key={tag.field} item={item} tag={tag} />
          ))}
        </Card.Description>
        <Card.Description>{shortDescription}</Card.Description>
      </Card.Content>
    </Card>
  );
};

SponsorCard.defaultProps = {
  allowFavorites: false,
};

SponsorCard.propTypes = {
  allowFavorites: PropTypes.bool,
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    category: PropTypes.string,
    collection: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
  }).isRequired,
};

export default SponsorCard;
