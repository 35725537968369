/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { NavLink } from 'react-router-dom';
import { Button, Icon, Popup } from 'semantic-ui-react';

import { useMenuEntries } from '../../../../config/config.context';
import { bem } from '../../../../core/design/bem';
import { useNotifications } from '../../../../hooks/useNotifications';
import Notification from '../../../../notifications/components/Notification';
import store from '../../../../shared/Store';
import { Notification as Notif } from '../../../../types/Notification.types';
import { GLOBAL_MEDIA_QUERIES } from '../../../../utils/mediaQueries';
import ImageIcon from '../../../ImageIcon';
// eslint-disable-next-line import/no-cycle
import { PageItem } from '../../AppSecondaryMenu';
import './NotificationsPopup.scss';

const cssList = bem('NotificationsPopup__List');

type NotificationListProps = {
  notifications: Notif[];
  maxItems: number;
  path?: string;
  showAll: boolean;
};

const NotificationList = (props: NotificationListProps) => {
  const { t } = useTranslation();
  const { notifications, maxItems, path, showAll } = props;
  const lastNotifications = useMemo(() => notifications?.slice(0, maxItems), [
    notifications,
    maxItems,
  ]);

  if (!notifications.length) {
    return (
      <span className={cssList('no-notifications')}>
        {t('notifications.popup.no-notifications')}
      </span>
    );
  }

  return (
    <div className={cssList('wrapper')}>
      {lastNotifications.map((notification: Notif) => (
        // @ts-ignore
        <Notification key={notification._id} {...notification} />
      ))}
      {(notifications.length > maxItems || showAll) && (
        <Button
          as={NavLink}
          primary
          fluid
          labelPosition="right"
          icon
          to={path || undefined}
          className={cssList('see-all-button').toString()}
        >
          {t('notifications.popup.see-all', { notificationsLength: notifications.length })}
          <Icon name="chevron right" />
        </Button>
      )}
    </div>
  );
};

NotificationList.defaultProps = {
  path: undefined,
};

const cssPopup = bem('NotificationsPopup');

type NotificationsPopupProps = {
  icon?: Record<string, any>;
  path?: string;
  maxItems?: number;
  showAll?: boolean;
} & typeof NotificationsPopupDefaultProps;

const NotificationsPopupDefaultProps = {
  icon: undefined,
  maxItems: 10,
  path: '/notifications',
  showAll: false,
};

const NotificationsPopup = (props: NotificationsPopupProps): JSX.Element | null => {
  const { icon, path, maxItems, showAll } = props;
  const { t } = useTranslation();
  const { notifications = [], unreadNotifications = [], enabled } = useNotifications();
  const { entries, config } = useMenuEntries();
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const handleCloseNotifications = async () => {
    const now = new Date().toISOString();
    await store.updateUser({ 'dates.notificationsReadAt': now });
  };

  if (!enabled) {
    return null;
  }
  const entry = entries?.find((e) => e.type === 'notifications');
  if (mobile) {
    return (
      <PageItem
        className="item--NotificationsPopup"
        entry={entry}
        count={unreadNotifications.length}
        config={config}
      />
    );
  }

  return (
    <Popup
      on="click"
      pinned
      position="bottom center"
      className={cssPopup()}
      wide
      onClose={handleCloseNotifications}
      size="small"
      trigger={
        <Button icon className={cssPopup('button').toString()}>
          {icon ? (
            <ImageIcon
              className={cssPopup('icon').toString()}
              icon={icon}
              {...(icon as any)}
              style={{ display: 'initial' }}
            />
          ) : (
            <Icon className={cssPopup('icon')} name="bell" size="large" />
          )}
          {unreadNotifications.length > 0 && (
            <span className={cssPopup('counter')}>{unreadNotifications.length}</span>
          )}
        </Button>
      }
    >
      <Popup.Header className={cssPopup('header').toString()}>
        {t('notifications.popup.header')}
      </Popup.Header>
      <Popup.Content className={cssPopup('List').toString()}>
        <NotificationList
          notifications={notifications}
          maxItems={maxItems}
          path={path}
          showAll={showAll}
        />
      </Popup.Content>
    </Popup>
  );
};

NotificationsPopup.defaultProps = NotificationsPopupDefaultProps;

export default NotificationsPopup;
