import { parseISO } from 'date-fns';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Message, Segment } from 'semantic-ui-react';

import { useTracking } from '../../../Context';
import { useFreeTimeSlots } from '../../../appointments/appointments.hooks';
import UserRegistrationModal from '../../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import { AppointmentAddToCalendarBlock } from '../../../components/AppointmentAddToCalendarBlock/AppointmentAddToCalendarBlock';
import AppointmentsModal from '../../../components/AppointmentsModal';
import GlobalModalManager from '../../../components/GlobalModalManager';
import { useIsPreview } from '../../../config/preview.context';
import { useIsAnonymous } from '../../../profile/hooks';
import { appointmentProptypes, userProptypes } from '../../../propTypes';
import { getString } from '../../../utils';
import { localeFormat } from '../../../utils/date';
import { sweetAlert } from '../../../utils/popupUtils';
import AccessMeetingRoomBlock from './AccessMeetingRoomBlock';
import './BookAppointmentBlock.scss';
import { cancelAppointment } from './utils';

const BookAppointmentBlock = (props) => {
  const { appointment, collection, config, item, translationPrefix } = props;
  const { bookingType = 'appointments' } = config || {};
  const timeSlots = useFreeTimeSlots(collection, item._id, {
    bookingType,
    mode: 'check-availability',
  });
  const isAnonymous = useIsAnonymous();
  const isPreview = useIsPreview();
  const { trackEvent } = useTracking;
  async function handleShowSlots() {
    if (isPreview) {
      await sweetAlert({
        icon: 'error',
        title: getString(`${translationPrefix}.booking-disable-in-preview-mode`),
      });
    } else if (isAnonymous) {
      await GlobalModalManager.open(UserRegistrationModal, {
        onClose: () => {
          //Do nothing :)
        },
      });
    } else {
      const { _id, name } = item;
      const userId = get(item, _id) || _id;
      const userName = get(item, name) || name;
      const appointmentUserRef = { _id: userId || _id, collection, name: userName };

      await GlobalModalManager.open(AppointmentsModal, {
        appointment: config,
        appointmentUserRef,
        bookingType: config.bookingType,
      });
    }
  }

  async function cancel() {
    await cancelAppointment(appointment, item, { trackEvent });
  }

  if (appointment) {
    const { startDate } = appointment;
    const date = parseISO(startDate);
    return (
      <div>
        <Segment className="BookAppointmentBlock--container">
          <Message>
            <Message.Header style={{ fontSize: 14 }}>
              <p>
                <Icon name="calendar outline alternate" /> {localeFormat(date, 'PPPP')}
              </p>
              <p>
                <Icon name="clock" /> {localeFormat(date, 'p')}
              </p>
            </Message.Header>
            <p>{getString(`${translationPrefix}.already-registered`)}</p>
            <AppointmentAddToCalendarBlock appointment={appointment} sponsor={item} primary />
            <Button
              className="BookAppointmentBlock--container__button"
              color="red"
              onClick={cancel}
            >
              {getString(`${translationPrefix}.cancel-appointment`)}
            </Button>
          </Message>
        </Segment>
        <AccessMeetingRoomBlock appointment={appointment} translationPrefix={translationPrefix} />
      </div>
    );
  }
  let styleDisabled = {};
  let bookMeetingLabel = getString(`${translationPrefix}.interested-by-sponsor`);
  if (!timeSlots.isBookingAvailable) {
    bookMeetingLabel = getString(`${translationPrefix}.unavailable-booking`);
    styleDisabled = { backgroundColor: 'grey' };
  }
  return (
    <>
      <Segment className="BookAppointmentBlock--container">
        <Message className="BookAppointmentBlock--container__message">
          <Message.Header>{bookMeetingLabel}</Message.Header>
          <p />
        </Message>
        <Button
          className="BookAppointmentBlock--container__button"
          primary
          disabled={!timeSlots.isBookingAvailable}
          style={styleDisabled}
          onClick={handleShowSlots}
        >
          {getString(`${translationPrefix}.book-a-meeting`)}
        </Button>
      </Segment>
    </>
  );
};

BookAppointmentBlock.defaultProps = {
  appointment: undefined,
  translationPrefix: 'sponsors.sponsor',
};

BookAppointmentBlock.propTypes = {
  appointment: PropTypes.shape(appointmentProptypes),
  item: PropTypes.shape(userProptypes).isRequired,
  translationPrefix: PropTypes.string,
  collection: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
};

export default connect((state, props) => ({
  appointment: state.appointments.appointments.find(
    (appointment) => appointment.groupId === props.item._id,
  ),
}))(BookAppointmentBlock);
