import cx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Card } from 'semantic-ui-react';

import { userAgenda } from '../../agenda/store/agenda.selectors';
import Images from '../../utils/Images';
import { checkUserAvailability } from '../../utils/agendaUtils';
import { AddWorkshopToCalendar } from '../../workshops/blocks/WorkshopAddToCalendarBlock';
import { getClassName, getWorkshopString } from '../../workshops/utils';
import UserAvatars from '../Avatars/UserAvatars';
import ClickmeetingAccessButton from '../ClickmeetingAccessButton';
import EntityLink from '../EntityLink';
import SpeakerAvatars from '../speakers/SpeakerAvatars';
import QuotaField from './QuotaField';
import WorkshopDate from './WorkshopDate';
import './WorkshopSessionsCard.scss';

const defaultBlocks = [
  { type: 'category' },
  { type: 'title' },
  { type: 'speakers' },
  { type: 'exhibitors' },
  { type: 'description' },
  { type: 'quota' },
  { type: 'registered-actions' },
];

const WorkshopSessionsCard = ({ workshop, blocks, ...rest }) => {
  const { showExhibitors, showEndTime, showDuration } = rest;
  const { t } = useTranslation();
  const { _id, image, quota, usersCount, workshopStatus } = workshop;

  const hasImage = image && image.uri;
  const agenda = useSelector(userAgenda);
  const hasOverlappingEvent = useMemo(() => {
    const otherEvents = agenda.filter((evt) => evt._id !== _id);
    return !checkUserAvailability(otherEvents, workshop);
  }, [agenda, workshop, _id]);

  const registration = agenda.find((evt) => evt._id === _id || evt.workshopId === _id);
  return (
    <div
      className={cx(
        'workshop--card3',
        getClassName(workshop),
        `session--${workshopStatus}`,
        hasOverlappingEvent && `session--has-overlapping-event`,
      )}
    >
      <Card link as={EntityLink} entity={workshop}>
        {hasImage && (
          <div className="card__image">
            <div
              className="image-with-shadow"
              style={{
                backgroundImage: `url(${Images.maxWidth(image, 800)})`,
              }}
            />
          </div>
        )}
        {hasImage && <Card.Content style={{ marginTop: 'calc(56.25% - 70px)', borderTop: 0 }} />}
        <Card.Content className="borderless">
          {blocks.map((block) => {
            const { type, dataKey } = block;
            const key = `${type}-${dataKey || 'default'}`;
            switch (type) {
              case 'category': {
                return (
                  <p key={key} className="workshop--category">
                    {getWorkshopString(workshop, dataKey || 'category')}
                  </p>
                );
              }
              case 'title': {
                return (
                  <Card.Header key={key} style={{ fontSize: '1.2em', fontWeight: 'normal' }}>
                    {workshop[dataKey || 'title']}
                  </Card.Header>
                );
              }
              case 'speakers': {
                return <SpeakerAvatars key={key} speakerIds={workshop.speakers} {...block} />;
              }
              case 'exhibitors': {
                if (!showExhibitors) return null;
                const { collection = 'exhibitors', field = 'exhibitors', config = {} } = block;

                return (
                  <UserAvatars
                    collection={collection}
                    imageKey="logo"
                    userIds={get(workshop, field, [])}
                    {...config}
                  />
                );
              }
              case 'description': {
                return (
                  <Card.Description key={key}>
                    {workshop[dataKey || 'shortDescription']}
                  </Card.Description>
                );
              }
              case 'quota': {
                if (registration) return null; // Registered, hide quota...
                if (!quota) {
                  // Just show session count
                  return (
                    <Card.Description key={key}>
                      {t('workshop-sessions.session-count', { count: workshop.sessions?.length })}
                    </Card.Description>
                  );
                }
                return <QuotaField key={key} quota={quota} usersCount={usersCount} />;
              }
              case 'registered-actions': {
                if (!registration) return null;
                const isToday = moment(registration.startDate).isSame(new Date(), 'day');
                return (
                  <Card.Description className="workshop-card-actions">
                    <WorkshopDate
                      startDate={registration.startDate}
                      endDate={registration.endDate}
                      showEndTime={showEndTime}
                      showDuration={showDuration}
                    />
                    <div style={{ marginTop: 4, display: 'flex', flexDirection: 'row' }}>
                      <AddWorkshopToCalendar
                        iconOnly={isToday}
                        primary={!isToday}
                        workshop={registration}
                      />
                      <div style={{ flex: 1 }} />
                      {isToday && <ClickmeetingAccessButton event={registration} />}
                    </div>
                  </Card.Description>
                );
              }
              case 'sessions-count': {
                // eslint-disable-next-line max-len
                if (registration || !quota) return null; // Registered, hide sessions count, no quota => already display session...
                return (
                  <Card.Description className="workshop-sessions-count" key={key}>
                    {t('workshop-sessions.session-count', { count: workshop.sessions?.length })}
                  </Card.Description>
                );
              }
              case 'view-sessions-button': {
                if (registration) return null; // Registered, hide button...
                return (
                  <Button primary fluid className="view-sessions-button">
                    {t('workshop-sessions.view-sessions')}
                  </Button>
                );
              }
              default: {
                console.warn('Unknown block type', type);
                return null;
              }
            }
          })}
        </Card.Content>
      </Card>
    </div>
  );
};

WorkshopSessionsCard.defaultProps = {
  blocks: defaultBlocks,
};

WorkshopSessionsCard.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  workshop: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    category: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string.isRequired,
    ]),
    collection: PropTypes.string.isRequired,
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    image: PropTypes.object,
    productCategories: PropTypes.array,
    quota: PropTypes.number,
    technicityLevel: PropTypes.string,
    sessions: PropTypes.array,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    shortDescription: PropTypes.string,
    slug: PropTypes.string,
    speakers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    usersCount: PropTypes.number,
    workshopStatus: PropTypes.string,
  }).isRequired,
};

export default WorkshopSessionsCard;
