/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card } from 'semantic-ui-react';

import EntityLink from '../../../../components/EntityLink';
import { bem } from '../../../../core/design/bem';
import { Workshop } from '../../../../types/workshop.types';
import { EntityTags } from '../../../components/WorkshopTable/WorkshopModal';

const css = bem('SuggestedWorkshops');
type Props = {
  workshops: Workshop[];
  config?: Record<string, any>;
};

export const CardVariant = (props: Props): JSX.Element => {
  const { config = {}, workshops } = props;
  const { itemsPerRow = 2, tagFields = [] } = config || {};
  return (
    <Card.Group itemsPerRow={itemsPerRow} className={css({ variant: 'card' }).toString()}>
      {workshops.map((workshop: Workshop) => {
        const { title: header, _id, workshopDuration: duration } = workshop;
        return (
          <Card
            className={css('Item').toString()}
            key={_id}
            style={{ cursor: 'pointer' }}
            as={EntityLink}
            entity={{ ...workshop, kind: 'workshops' }}
          >
            <Card.Content>
              <EntityTags tagFields={tagFields} entity={workshop} type="workshop" />
              <Card.Header className="title">{header}</Card.Header>
              {duration && <span className="duration">{duration}</span>}
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};

CardVariant.defaultProps = {
  config: {},
};
