import cx from 'classnames';
import React, { FC } from 'react';

import CdnImage from '../../components/CdnImage';
import { VideoPlayIcon } from '../../components/VideoPlayIcon/VideoPlayIcon';
import { bem } from '../../core/design/bem';
import { PublicationInteractions } from '../types/SocialWallV2.types';
import { EmoteList } from './EmoteList';
import './MediaCard.scss';

interface MediaCardTypes {
  className: string;
  url?: string;
  mimeType?: string;
  interactions: PublicationInteractions;
  userInteractions?: string[];
  onClick?: (params?: any) => any;
}

const css = bem('media-card');
export const MediaCard: FC<MediaCardTypes> = ({
  className,
  url,
  mimeType,
  interactions,
  userInteractions,
  onClick,
}) => {
  const isVideo = mimeType?.split('/')?.[0] === 'video';
  return (
    <div className={cx('media-card', className)} onClick={onClick}>
      {isVideo && <VideoPlayIcon />}
      <CdnImage src={url} maxWidth={600} className="media-card__image" />
      <EmoteList
        disabled
        className={css('interactions')}
        interactions={interactions}
        userInteractions={userInteractions}
        display={{ defaultEmotes: false, allEmotes: true }}
      />
    </div>
  );
};
