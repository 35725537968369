import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import { Achievement, AchievementState, Category } from '../../../types/achievements.types';
import { AchievementBadge } from './types';

export function computePercentage(achievements: Achievement[]): number {
  const unlocked = achievements.filter((a) => a.state === AchievementState.COMPLETED);
  const calc = (unlocked.length / achievements.length) * 100;
  return Math.round(calc) || 0;
}

export function computeAchievementBadgesByCategory(
  achievements: Achievement[],
  categories: Category[],
): Record<string, AchievementBadge[]> | Record<string, unknown> {
  const formatedBadge = achievements.map((achievement) => {
    const { categoryId, label, points, state, _id, status, media } = achievement;
    const category = categories.find((c) => c._id === categoryId);
    const isLocked = state !== AchievementState.COMPLETED;
    return {
      _id,
      title: label,
      points,
      category: {
        label: category?.label,
        _id: category?._id,
        description: category?.data?.description,
        order: category?.data?.order,
      },
      image: media,
      locked: isLocked,
      state,
      status,
    };
  });

  const orderedBadge = orderBy(formatedBadge, ['category.order', 'locked'], ['asc', 'asc']); // Je suis preneur d'une meilleure façon d'avoir les badges débloqués en premier
  return groupBy(orderedBadge, 'category._id');
}
