/* eslint-disable import/no-cycle */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import hasher from 'hash-string';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-use';
import { Button, Icon, Popup } from 'semantic-ui-react';

import { useMenuEntries } from '../../../../config/config.context';
import { bem } from '../../../../core/design/bem';
import useUrlState from '../../../../hooks/useUrlState';
import {
  startNetworkingChat,
  stopNetworkingChat,
} from '../../../../networking/store/networking.actions';
import { chatIdFromHash } from '../../../../networking/store/networking.selectors';
import { useMe } from '../../../../profile/hooks';
import { GLOBAL_MEDIA_QUERIES } from '../../../../utils/mediaQueries';
import ImageIcon from '../../../ImageIcon';
import { PageItem } from '../../AppSecondaryMenu';
import { MessagesList } from './MessagesList';
import './MessagesPopup.scss';
import { useChats } from './hooks';

const css = bem('MessagesPopup');

const translationPrefix = 'messages.popup';

type MessagesPopupProps = {
  icon?: Record<string, any>;
  path?: string;
  maxItems?: number;
  showAll?: boolean;
} & typeof MessagesPopupDefaultProps;

const MessagesPopupDefaultProps = {
  icon: undefined,
  maxItems: 10,
  path: '/messages',
  showAll: false,
};

const MessagesPopup = (props: MessagesPopupProps): JSX.Element => {
  const { icon } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const me = useMe();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const defaultChatHash = query.get('chatHash') as string;
  const [urlState, setUrlState] = useUrlState({});
  const { chatHash = defaultChatHash } = urlState;
  const [isOpen, setIsOpen] = useState(false);
  const chatId = useSelector(chatIdFromHash(chatHash));

  useEffect(() => {
    if (!chatId) return;
    setTimeout(() => {
      setIsOpen(true);
      dispatch(startNetworkingChat(chatId));
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(stopNetworkingChat(chatId));
    };
  }, [chatId, dispatch]);

  const cleanChat = () => {
    setUrlState({ chatHash: undefined });
  };

  const { chats, conversations, unreadConversations } = useChats();
  const { entries, config } = useMenuEntries();
  const entry = entries?.find((e) => e.type === 'messages');
  const { config: entryConfig } = entry;
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const unreadCount = unreadConversations.length;
  const otherProfile = useMemo(() => {
    const currentConversation = chats?.[chatId];
    if (!currentConversation) return null;
    const { invitedProfile, inviterProfile, inviter } = currentConversation;
    return inviter === me._id ? invitedProfile : inviterProfile;
  }, [chats, chatId, me]);
  if (mobile) {
    return (
      <PageItem className="item--MessagesPopup" entry={entry} count={unreadCount} config={config} />
    );
  }
  return (
    <Popup
      on="click"
      pinned
      position="bottom center"
      className={css()}
      wide
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      onClose={() => {
        cleanChat();
        setIsOpen(false);
      }}
      positionFixed
      size="small"
      trigger={
        <Button icon className={css('button').toString()}>
          {icon ? (
            <ImageIcon
              className={css('icon').toString()}
              icon={icon}
              {...(icon as any)}
              style={{ display: 'initial' }}
            />
          ) : (
            <Icon className={css('icon').toString()} name="bell" size="large" />
          )}
          {unreadCount > 0 && <span className={css('counter')}>{unreadConversations.length}</span>}
        </Button>
      }
    >
      <Popup.Header className={css('header').toString()}>
        {t(`${translationPrefix}.${chatId ? `chat-with` : `header`}`, { user: otherProfile })}
      </Popup.Header>
      <Popup.Content>
        <MessagesList
          chats={conversations}
          config={entryConfig}
          chatId={chatId}
          onStartChat={(id: string) => setUrlState({ chatHash: hasher(id).toString(16) })}
          onBackToChats={cleanChat}
        />
      </Popup.Content>
    </Popup>
  );
};

MessagesPopup.defaultProps = MessagesPopupDefaultProps;

export default MessagesPopup;
