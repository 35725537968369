import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { useAutoRefresh } from '../hooks/useAutoRefresh';

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const LINK_EXPIRATION_DELAY = 12 * HOURS;
const startTime = Date.now();

function timeLeft() {
  if (window.__DATA__.refreshConfig?.disableLinkExpiration) {
    return 60 * MINUTES;
  }
  return startTime + LINK_EXPIRATION_DELAY - Date.now();
}

function hasExpired() {
  // TODO: disabled if logged in ? Importing the store breaks storybook ??
  if (window.__DATA__.refreshConfig?.disableLinkExpiration) {
    return false;
  }
  return /* store.hasSavedUser() && */ timeLeft() <= 0;
}

export function isExternalLink(url: string | undefined): boolean {
  return !!url && (url.indexOf('http') === 0 || url.indexOf(':') !== -1);
}

const ExpiringNavLink: FC<any> = (props: Record<string, any>): JSX.Element => {
  useAutoRefresh(Math.max(1 * MINUTES, timeLeft()));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { to, visibleBy, target, ...rest } = props;
  if (isExternalLink(to)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...rest} href={to} target={target || '_blank'} rel="noreferrer" />;
  }

  const linkProps = { ...rest };
  if (hasExpired()) {
    linkProps.component = 'a';
  }
  return <NavLink to={to} {...linkProps} />;
};

ExpiringNavLink.propTypes = (NavLink as any).propTypes;

export default ExpiringNavLink;
