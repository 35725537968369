/* eslint-disable operator-linebreak */
import { memo, useContext } from 'react';

import { TrackingContext } from '../../Context';
import AddToCalendar from '../../components/AddToCalendar';
import { useConfig } from '../../config/config.context';
import { eventTags } from '../../core/trackers/events';
// import { useUser } from '../../core/users/users.hooks';
import store from '../../shared/Store';
import { useCalendarEventLink } from '../../utils/hooks';
import { replaceObjectValues, replacePath } from '../../utils/stringUtils';
import { workshopPropType } from '../utils';
import './WorkshopAddToCalendarBlock.scss';

function useEntityConfig(kind) {
  return useConfig()?.entities?.[kind] || {};
}

export function useEntityIcal(kind, collection) {
  const config = useEntityConfig(kind);
  const ical = config[collection]?.ical ?? config.default?.ical;
  return ical ?? {};
}

export const AddWorkshopToCalendar = ({ workshop, ...props }) => {
  // NOTE: only {var} placeholders are replaced. Add replacement for :var
  const url = replacePath(useCalendarEventLink(workshop, true), workshop);
  const { trackEvent } = useContext(TrackingContext);
  const icalConfig = useEntityIcal('workshops', workshop.collection);

  const event = {
    description: `${workshop.shortDescription || ''}. \n${url}`,
    reminderTime: '-PT15M',
    title: workshop.title,
    url,
    ...replaceObjectValues(icalConfig, { workshop }),
    // Flush organizer and email for now
    organizer: undefined,
    email: undefined,
    startDate: workshop.startDate,
    endDate: workshop.endDate,
  };

  function onAdd({ calendarType }) {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.userId,
      item: workshop,
      kind: 'workshops',
      entityType: 'addToCalendar',
      calendarType,
    });
  }

  function onOpen() {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.userId,
      item: workshop,
      kind: 'workshops',
      entityType: 'listCalendarOptions',
    });
  }

  return <AddToCalendar event={event} onAdd={onAdd} onOpen={onOpen} {...props} />;
};

AddWorkshopToCalendar.propTypes = {
  workshop: workshopPropType.isRequired,
};

export const WorkshopAddToCalendarBlock = memo(({ workshop }) => (
  <div className="WorkshopAddToCalendarBlock--Container">
    <AddWorkshopToCalendar workshop={workshop} />
  </div>
));

WorkshopAddToCalendarBlock.propTypes = {
  workshop: workshopPropType.isRequired,
};
