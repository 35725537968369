import isArray from 'lodash/isArray';
import throttle from 'lodash/throttle';

// eslint-disable-next-line import/no-cycle
import workshopSessionService from '../services/workshop-session.service';
import { SET } from './workshopSessions.types';

export function setWorkshopSessions(workshopSessions) {
  const formattedSessions = workshopSessions.map((s) => ({
    ...s,
    type: 'session',
    kind: 'workshops',
  }));
  return {
    type: SET,
    workshopSessions: formattedSessions,
  };
}

async function dispatchFetchWorkshopSessions(dispatch) {
  const workshopSessions = await workshopSessionService.fetchEventWorkshopSessions();
  if (isArray(workshopSessions)) {
    dispatch(setWorkshopSessions(workshopSessions));
  }
}

const throttledDispatch = throttle(dispatchFetchWorkshopSessions, 2000, {
  leading: true,
  trailing: true,
});

export function throttledFetchWorkshopSessions() {
  return throttledDispatch;
}

export function fetchWorkshopSessions() {
  return dispatchFetchWorkshopSessions;
}
