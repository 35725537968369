/* eslint-disable class-methods-use-this */
import wretch, { Wretcher } from 'wretch';

import store from '../../shared/Store';
import {
  Media,
  Publication,
  PublicationInteraction,
  PublicationInteractionPayload,
} from '../../social-wall-v2/types/SocialWallV2.types';

const BASE_URI = 'social-wall-v2';

class SocialWallService {
  api(): Wretcher {
    return wretch().url(window.__DATA__.endpoints.platform).auth(`JWT ${store.token}`);
  }

  private getPostRef(): Wretcher {
    return this.api().url(`/${BASE_URI}/posts`);
  }

  private getPostIdRef(postId: string): Wretcher {
    return this.getPostRef().url(`/${postId}`);
  }

  private getPostCommentRef(postId: string): Wretcher {
    return this.getPostIdRef(postId).url(`/comments`);
  }

  private getPostInteractionsRef(postId: string): Wretcher {
    return this.getPostIdRef(postId).url(`/interactions`);
  }

  public getPublished(): Promise<Publication[]> {
    return this.getPostRef().get().json();
  }

  async postMessage(post: {
    message: string;
    media?: Media;
    thumbnail?: Media;
  }): Promise<Publication> {
    return this.getPostRef().post({ data: post }).json();
  }

  async patchMessage(postId: string, patch: any): Promise<{ success: boolean }> {
    return this.getPostIdRef(postId).patch({ data: patch }).json();
  }

  async getComments(parentId: string): Promise<Publication[]> {
    return this.getPostCommentRef(parentId).get().json();
  }

  async postComment(
    parentId: string,
    post: {
      message: string;
    },
  ): Promise<Publication> {
    return this.getPostCommentRef(parentId).post({ data: post }).json();
  }

  async deleteMessage(postId: string) {
    return this.getPostIdRef(postId).delete().json();
  }

  async getUserInteractions(): Promise<PublicationInteraction[]> {
    const userInteractions = await this.getPostRef().url('/interactions').get().json();
    return userInteractions?.interactions;
  }

  async addInteraction(messageId: string, body: PublicationInteractionPayload) {
    return this.getPostInteractionsRef(messageId).post(body).json();
  }

  async removeInteraction(messageId: string, body: PublicationInteractionPayload) {
    return this.getPostInteractionsRef(messageId).json(body).delete().json();
  }
}

export default new SocialWallService();
