/* eslint-disable operator-linebreak */

/* eslint-disable react/no-array-index-key */
import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Table,
} from 'semantic-ui-react';

import { TrackingContext } from '../../../Context';
import CdnImage from '../../../components/CdnImage';
import QRCode from '../../../components/QRCode';
import { UserAvatarImageUploader } from '../../../components/UserAvatarImageUploader/UserAvatarImageUploader';
import { bem } from '../../../core/design/bem';
import ordersService from '../../../core/services/orders.service';
import { eventTags } from '../../../core/trackers/events';
import useTranslations from '../../../hooks/useTranslations';
import store from '../../../shared/Store';
import { replaceValues } from '../../../utils/stringUtils';
import FieldInput from '../FieldInput';
import ResetPasswordModal from '../ResetPasswordModal';
import UserFiles from '../UserFiles';
import './UserProfile.scss';

const css = bem('UserProfile');

const { endpoints } = window.__DATA__;

export const expandField = (field) =>
  typeof field === 'string' ? { key: field, type: 'string' } : field;

const UserProfileDetail = ({
  design,
  user,
  fields,
  actions,
  editing,
  qrcode,
  file,
  avatarConfig,
  enableAvatar,
  enableNetworking,
  showResetPasswordButton,
  onFieldChange,
  onUpdateUser,
  className,
}) => {
  const { t } = useTranslations();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const { trackEvent } = useContext(TrackingContext);
  const trackNetworkingStatus = (status) => {
    const tag = status ? eventTags.NETWORKING_ENABLE : eventTags.NETWORKING_DISABLE;
    trackEvent(tag, {
      userId: store.userId,
    });
  };

  const hasAvatar = avatarConfig?.enableChange || !!user.thumbnail;
  const hasAvatarAndQrcode = hasAvatar && enableAvatar && qrcode?.enabled;
  const { order: { status, _id: orderId } = {} } = user;
  const hasOrder = !!status;
  const { badge = {} } = qrcode ?? {};

  if (qrcode?.downloadBadge) {
    badge.enabled = qrcode.downloadBadge;
  }

  function getBadgeUri() {
    const { templateUrl } = badge;
    const badgeUrl = `${endpoints.document}/${store.eventId}/badges/users/${user.collection}/${user._id}/badge.pdf`;
    if (templateUrl) {
      return replaceValues(templateUrl, { url: badgeUrl });
    }

    return badgeUrl;
  }

  const handleDownloadInvoice = async () => {
    await ordersService.downloadInvoice(orderId);
  };

  return (
    <Grid.Row className={cx(`user-profile`, className)} stretched columns="equal">
      {enableAvatar && hasAvatar && (
        <Grid.Column
          className={css('column', { avatar: true }).toString()}
          width={hasAvatarAndQrcode ? 4 : 5}
        >
          <Segment>
            <UserAvatarImageUploader
              onChange={(json) => onUpdateUser({ thumbnail: json })}
              value={user.thumbnail}
              avatarConfig={avatarConfig}
            />
            {avatarConfig?.displayFullname && (
              <Header className="fullname" as="h3">
                {t('profile.name', user)}
              </Header>
            )}
          </Segment>
        </Grid.Column>
      )}
      <Grid.Column className={css('column', { info: true }).toString()}>
        <Card fluid>
          <Card.Content>
            <Card.Header as="h3">
              {t('profile.information')}
              {hasOrder && (
                <Button
                  floated="right"
                  primary
                  basic
                  size="small"
                  compact
                  onClick={handleDownloadInvoice}
                >
                  {t('profile.download-invoice')}
                </Button>
              )}
            </Card.Header>
            <Form inverted={design?.inverted}>
              <Table basic="very" className="table--profile">
                <Table.Body>
                  {fields.map((field) => {
                    const { key, label, editable, required, mapping } = expandField(field);
                    const userValue = get(user, key);
                    const displayValue = get(mapping, userValue, userValue);
                    if (!displayValue && (!editing || !editable)) return null;

                    return (
                      <Table.Row key={key}>
                        <Table.Cell width="6">{t(`users.field.${key}`, label || key)}</Table.Cell>
                        <Table.Cell width="10">
                          <FieldInput
                            field={field}
                            label={t(`profile.field.${field}.${displayValue}`, displayValue)}
                            value={displayValue}
                            editable={editable && editing}
                            error={required && !displayValue}
                            onChange={onFieldChange}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Form>

            <UserFiles user={user} fileConfig={file} />

            {(actions || showResetPasswordButton) && (
              <div>
                {actions}
                {!actions && showResetPasswordButton && (
                  <Button size="small" icon onClick={() => setShowResetPassword(true)}>
                    <Icon name="lock" />
                    &nbsp;
                    {t(`profile.updatePassword`)}
                  </Button>
                )}
              </div>
            )}

            {showResetPassword && (
              <ResetPasswordModal onClose={() => setShowResetPassword(false)} />
            )}
          </Card.Content>
          {enableNetworking && (
            <Card.Content extra>
              <Checkbox
                toggle
                label={
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label>
                    {t('profile.enableNetworking')}
                    <br />
                    <i style={{ color: 'grey', fontSize: '0.85em' }}>
                      {t('profile.networkingLabel')}
                    </i>
                  </label>
                }
                onClick={(e, { checked }) => {
                  trackNetworkingStatus(checked);
                  onUpdateUser({ networking: checked });
                }}
                checked={user.networking}
              />
            </Card.Content>
          )}
        </Card>
      </Grid.Column>
      {qrcode?.enabled && (
        <Grid.Column
          className={css('column', { qrcode: true }).toString()}
          width={hasAvatarAndQrcode ? 4 : 5}
        >
          <Segment className={css('qrcode').toString()}>
            {!!qrcode.uri && <CdnImage src={replaceValues(qrcode.uri, user)} />}
            {!qrcode.uri && (
              <QRCode
                width={qrcode.size}
                height={qrcode.size}
                data={user._id}
                qrcodeConfig={qrcode.config}
              />
            )}
            {badge?.enabled && (
              <Button primary as="a" target="_blank" href={getBadgeUri()}>
                <Icon name="id badge outline" />
                {t('profile.download-badge')}
              </Button>
            )}
            {qrcode.text && <p>{qrcode.text}</p>}
          </Segment>
        </Grid.Column>
      )}
    </Grid.Row>
  );
};

UserProfileDetail.propTypes = {
  avatarConfig: PropTypes.object,
  design: PropTypes.shape({ inverted: PropTypes.bool }),
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string,
        type: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
  user: PropTypes.shape({
    _id: PropTypes.string,
    collection: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    networking: PropTypes.bool,
    rewards: PropTypes.object,
    thumbnail: PropTypes.object,
    order: PropTypes.object,
  }).isRequired,
  actions: PropTypes.arrayOf(PropTypes.element),
  editing: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  enableNetworking: PropTypes.bool,
  enableAvatar: PropTypes.bool,
  file: PropTypes.object,
  qrcode: PropTypes.shape({
    downloadBadge: PropTypes.bool,
    enabled: PropTypes.bool,
    uri: PropTypes.string.isRequired,
    text: PropTypes.string,
    badge: PropTypes.shape({
      templateUrl: PropTypes.string,
      enabled: PropTypes.bool,
    }),
  }),
  showResetPasswordButton: PropTypes.bool,
  className: PropTypes.string,
};

UserProfileDetail.defaultProps = {
  avatarConfig: {},
  design: undefined,
  fields: [],
  actions: [],
  editing: false,
  qrcode: undefined,
  file: undefined,
  enableNetworking: false,
  enableAvatar: false,
  showResetPasswordButton: false,
  className: undefined,
};

export default UserProfileDetail;
