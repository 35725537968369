import PropTypes from 'prop-types';
import { Ref } from 'semantic-ui-react';

import './Chat.scss';
import ChatFeed from './ChatFeed';
import ChatInput from './ChatInput';
import { useFormatMessages } from './hooks/useFormatMessages';
import useChatScroll from './useChatScroll';

const Chat = ({
  headerMessage,
  messages,
  me,
  sponsorImage,
  onEnterVisio,
  onSendMessage,
  onAddMeeting,
  onUpdateMeeting,
  onStartVideoCall,
  onDelete,
}) => {
  const formatMessages = useFormatMessages(messages);
  console.log('formatMessages', formatMessages);
  const scrollElementRef = useChatScroll(formatMessages);
  return (
    <div className="Chat">
      <Ref innerRef={scrollElementRef}>
        <ChatFeed
          onEnterVisio={onEnterVisio}
          feedHeader={headerMessage}
          messages={formatMessages}
          me={me}
          sponsorImage={sponsorImage}
          onDelete={onDelete}
          onUpdateMeeting={onUpdateMeeting}
        />
      </Ref>
      <ChatInput
        onSubmit={onSendMessage}
        onAddMeeting={onAddMeeting}
        onStartVideoCall={onStartVideoCall}
      />
    </div>
  );
};

Chat.defaultProps = {
  headerMessage: undefined,
  sponsorImage: undefined,
  onDelete: undefined,
  onEnterVisio: undefined,
  onStartVideoCall: undefined,
  onAddMeeting: undefined,
  onUpdateMeeting: undefined,
};

Chat.propTypes = {
  headerMessage: PropTypes.shape({
    text: PropTypes.string,
  }),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      emitter: PropTypes.string.isRequired,
      profile: PropTypes.shape({
        isSponsor: PropTypes.bool,
        name: PropTypes.string.isRequired,
        thumbnail: PropTypes.object,
      }),
    }),
  ).isRequired,
  me: PropTypes.string.isRequired,
  sponsorImage: PropTypes.shape({ uri: PropTypes.string }),
  onEnterVisio: PropTypes.func,
  onDelete: PropTypes.func,
  onSendMessage: PropTypes.func.isRequired,
  onAddMeeting: PropTypes.func,
  onUpdateMeeting: PropTypes.func,
  onStartVideoCall: PropTypes.func,
};

export default Chat;
