/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import ReactPlayer from 'react-player';

import { useVideoSectionStats } from '../hooks/useVideoSectionStats';
import './ReactVideoPlayer.scss';

interface OnProgressProps {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

type ReactVideoPlayerProps = {
  _id: string;
  url: string;
  originalUrl: string;
  autoPlay: boolean;
  item?: Record<string, any>;
  block?: Record<string, any>;
  onPlay: () => void;
  onSectionStat: (stat: Record<string, any>) => void;
} & typeof DefaultProps;

const DefaultProps = { item: {}, block: {} };

export const ReactVideoPlayer = (props: ReactVideoPlayerProps): JSX.Element => {
  const { _id: videoId, url, originalUrl, autoPlay, item, block, onPlay, onSectionStat } = props;
  const playerRef = useRef(null) as any;
  const { play, stop, updatetime } = useVideoSectionStats(
    url,
    { item, block, originalUrl, videoId },
    onSectionStat,
  );

  const onPlayerPlay = () => {
    const seconds = playerRef.current.getCurrentTime();
    const duration = playerRef.current.getDuration();
    play(seconds, duration);
    if (onPlay) {
      onPlay();
    }
  };

  const onPlayerProgress = (event: OnProgressProps) => {
    const { playedSeconds } = event;
    const duration = playerRef.current.getDuration();
    updatetime(playedSeconds, duration);
  };

  const onPlayerPaused = () => {
    const seconds = playerRef.current.getCurrentTime();
    stop(seconds, false);
  };

  const onVideoEnded = () => {
    const seconds = playerRef.current.getCurrentTime();
    stop(seconds, true);
  };

  return (
    <div className="ReactVideoPlayer">
      <ReactPlayer
        controls
        ref={playerRef}
        url={url}
        onPlay={onPlayerPlay}
        onProgress={onPlayerProgress}
        onPause={onPlayerPaused}
        onEnded={onVideoEnded}
        config={{
          youtube: {
            playerVars: {
              autoplay: autoPlay ? 1 : 0,
              muted: !autoPlay,
            },
          },
        }}
      />
    </div>
  );
};

ReactVideoPlayer.defaultProps = DefaultProps;
