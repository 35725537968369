/*eslint-disable no-lonely-if*/
import cx from 'classnames';
import inRange from 'lodash/inRange';
import React, { useRef, useState } from 'react';
import { useMount, useWindowSize } from 'react-use';

import useEvent from '../../../core/hooks/useEvent.hook';
import { useBodyScrollLock } from '../../../utils/hooks';
import getPointerPosY from './getPointerPosY';
import { GestureCheckpoint, UseDraggableProps } from './types';

interface UseDraggableBottomPanel {
  onMouseDown: (params: any) => any;
  setPanelPosition: (position: number) => any;
  containerProps: {
    className: string;
    style: { height: number };
  };
}

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

const formatSteps = (steps?: number[]) => {
  if (!steps?.length) return [];

  return steps
    .filter((s) => s)
    .map((step, stepIndex) => ({
      value: step,
      range: [
        stepIndex === 0 ? 0 : (step + steps[stepIndex - 1]) / 2,
        stepIndex === steps.length - 1 ? 100 : (step + steps[stepIndex + 1]) / 2,
      ],
    }));
};

export const useDraggableBottomPanel = ({
  onClose,
  options,
  panelElementRef,
  positionCallback,
}: UseDraggableProps): UseDraggableBottomPanel => {
  const gestureRef = useRef({
    pointerOffset: 0,
    start: {
      position: 0,
      time: 0,
    } as GestureCheckpoint<number>,
    end: {
      position: 0,
      time: 0,
    } as GestureCheckpoint<number>,
  });
  useBodyScrollLock();

  const { height: windowHeight } = useWindowSize();

  const [isDragging, setIsDragging] = useState(false);
  const [height, setHeight] = useState<number>(0);

  const changeHeight = (newHeight: number) => {
    setHeight(newHeight);
    if (positionCallback) {
      positionCallback(newHeight);
    }
  };

  const setPanelPosition = (heightPercentage: number) => {
    changeHeight((windowHeight * heightPercentage) / 100);
  };

  const onMouseMove = useEvent((e: TouchEvent | MouseEvent) => {
    e.preventDefault();
    const mousePosY = getPointerPosY(e);

    if (mousePosY === undefined) return;

    gestureRef.current.end = {
      position: mousePosY,
      time: Date.now(),
    };

    const position = mousePosY - gestureRef.current.pointerOffset;

    changeHeight(clamp(windowHeight - position, 0, windowHeight));
  });

  const setPanelToCloserStep = (
    panelPosition: number,
    steps: {
      value: number;
      range: number[];
    }[],
  ): void => {
    // 99 because range is start <= position < end, 100 not allowed
    const positionToSearch = clamp(panelPosition, 0, 99);

    const targetStep = steps.find(({ range }) => inRange(positionToSearch, range[0], range[1]));

    if (targetStep) {
      setPanelPosition(targetStep.value);
    }
  };

  const onMouseUp = useEvent(async (e: TouchEvent | MouseEvent) => {
    e.preventDefault();

    const { start, end } = gestureRef.current;

    const distance = start.position - end.position;
    const time = end.time - start.time;

    setIsDragging(false);

    const velocity = Math.abs(distance / time);
    const formattedSteps = formatSteps(options?.steps);
    const percentagePosition = ((windowHeight - end.position) * 100) / windowHeight;

    if ((velocity > 1.4 && distance < 0) || percentagePosition < 10) {
      if (onClose) {
        onClose();
      }
      setPanelToCloserStep(percentagePosition, formattedSteps);
    } else if (velocity > 1.4 && distance > 0) {
      setPanelPosition(formattedSteps[formattedSteps.length - 1]?.value ?? 100);
    } else {
      //Time can be < 0 when we only click on draggable element

      if (time > 0) setPanelToCloserStep(percentagePosition, formattedSteps);
    }

    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('touchend', onMouseUp);
    panelElementRef.current?.removeEventListener('mousemove', onMouseMove);
    panelElementRef.current?.removeEventListener('touchmove', onMouseMove);
  });

  const onMouseDown = useEvent((e: React.TouchEvent | React.MouseEvent) => {
    if (!panelElementRef.current) return;
    const panel = panelElementRef.current;

    const pointerPosition = getPointerPosY(e);
    if (pointerPosition === undefined) return;

    setIsDragging(true);

    gestureRef.current.start = {
      position: pointerPosition,
      time: Date.now(),
    };

    gestureRef.current.pointerOffset = pointerPosition - panel.offsetTop;

    panel.addEventListener('mousemove', onMouseMove, { passive: false });
    panel.addEventListener('touchmove', onMouseMove, { passive: false });
    document.addEventListener('mouseup', onMouseUp, { passive: false });
    document.addEventListener('touchend', onMouseUp, { passive: false });
  });

  useMount(() => {
    const formattedSteps = formatSteps(options?.steps);
    const initialPosition = options?.initialStep || formattedSteps[0]?.value || 100;
    setPanelPosition(initialPosition);
  });

  return {
    onMouseDown,
    setPanelPosition,
    containerProps: {
      className: cx(height === windowHeight && 'is-anchored-top', isDragging && 'is-dragging'),
      style: { height },
    },
  };
};
