import { FC, useMemo } from 'react';

import { Publication } from '../types/SocialWallV2.types';
import { MediaCard } from './MediaCard';

interface SocialWallMediaPageProps {
  className?: string;
  publications: Publication[];
  userInteractions: Record<string, string[]>;
  openPublication: (publicationId: string) => void;
}

export const SocialWallMediaPage: FC<SocialWallMediaPageProps> = ({
  publications,
  userInteractions,
  openPublication,
}) => {
  const renderMediaCards = useMemo(() => {
    return publications
      .filter((p) => !!p.data.media)
      .map((publication) => {
        const { data, _id, interactions } = publication;
        const { width, height } = data.media!;
        const isPortait = !!width && !!height && width <= height;
        return (
          <MediaCard
            className={isPortait ? 'potrait-mode' : ''}
            key={_id}
            url={data?.thumbnail ? data?.thumbnail?.uri : data?.media?.uri}
            mimeType={data?.media?.mimeType}
            userInteractions={userInteractions[_id]}
            interactions={interactions}
            onClick={() => openPublication(publication._id)}
          />
        );
      });
  }, [userInteractions, publications]);

  return <div className="social-wall-media-page ">{renderMediaCards}</div>;
};
