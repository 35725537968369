import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Dropdown, Menu, Search } from 'semantic-ui-react';

import { NetworkMessagesLabel } from '../../networking/components/NetworkNotificationsLabel';
import store from '../../shared/Store';
import { SocialWallLabel } from '../../social-wall/SocialWallLabel';
import CdnImage from '../CdnImage';
import ExpiringNavLink from '../ExpiringNavLink';
import ImageIcon from '../ImageIcon';
import './AppMenu.scss';
import LogoutButton from './LogoutButton';
import NavMenuItem from './NavMenuItem';
import MessagesPopup from './widgets/MessagesPopup/MessagesPopup';
import NotificationsPopup from './widgets/NotificationsPopup/NotificationsPopup';

const menuModesClassNames = {
  classic: '',
  buttons: 'secondary',
  tabs: 'secondary pointing',
};

class AppMenu extends PureComponent {
  handleDisconnect = async () => {
    await store.disconnect();
    this.forceUpdate();
    window.location.reload();
  };

  renderParentItem = ({ _id, label, name, icon, path, type, className, ...rest }) => {
    const { menuConfig } = this.props;
    const { type: iconType, style, maxHeight } = menuConfig?.icon || {};
    const onClick = type === 'logout' ? this.handleDisconnect : undefined;
    if (type === 'notifications') {
      return <NotificationsPopup key={_id || name} icon={icon} path={path} {...rest} />;
    }

    if (type === 'messages') {
      return <MessagesPopup key={_id || name} icon={icon} path={path} {...rest} />;
    }

    return (
      <NavMenuItem
        {...rest}
        className={cx(name, type, className)}
        to={path}
        key={_id || name}
        name={name}
        exact={path === '/'}
        onClick={onClick}
      >
        <ImageIcon
          icon={icon}
          type={iconType}
          style={{ marginRight: 8, ...style }}
          maxHeight={maxHeight}
        />
        {label}
        {type === 'search-field' && <Search />}
        {type === 'image' && <CdnImage src={rest.image} maxWidth={rest?.maxWidth || 150} />}
        {(type === 'networking' || name === 'networking') && <NetworkMessagesLabel color="" />}
        {type === 'social-wall' && <SocialWallLabel color="blue" />}
      </NavMenuItem>
    );
  };

  renderChildrenItem = ({ children = [], label, path, name }) => {
    return (
      // TODO => open on focus for these item !!!!
      <Dropdown
        item
        text={label}
        to={path}
        key={name}
        as={path ? ExpiringNavLink : undefined}
        open={false}
      >
        <Dropdown.Menu>{children && children.map((c) => this.renderItem(c))}</Dropdown.Menu>
      </Dropdown>
    );
  };

  renderItem = (menuItem) => {
    if (menuItem.children) {
      return this.renderChildrenItem(menuItem);
    }
    return this.renderParentItem(menuItem);
  };

  render() {
    const { menu, menuConfig, variant, loginPath, ...props } = this.props;
    const centered = get(menuConfig, ['entries', 'centered'], false);
    if (store.mustRedirectToLogin()) {
      // Handle disconnect
      return <Redirect to={loginPath} />;
    }

    const right = menu.filter((i) => i.position === 'right');
    const center = menu.filter((i) => i.position === 'center');
    const left = menu.filter((i) => i.position !== 'right' && i.position !== 'center');

    const hasExplicitLogout = menu.some((item) => item.type === 'logout');

    return (
      <Menu
        className={cx(menuModesClassNames[variant], 'menu--main menu--hover')}
        vertical={menuConfig?.wrapper?.variant === 'leftSidebar'}
        {...props}
      >
        {left.length > 0 && (
          <Menu.Menu className={cx('menu--main__left', { centered })} position="left">
            {left.map((m) => this.renderItem(m))}
          </Menu.Menu>
        )}
        {center.length > 0 && (
          <Menu.Menu className={cx('menu--main__center')}> {center.map(this.renderItem)}</Menu.Menu>
        )}
        <Menu.Menu
          position="right"
          className={cx('menu--main__right', { centered, hasRight: right.length > 0 })}
        >
          {right.map((m) => this.renderItem(m))}
          {!hasExplicitLogout && (
            <LogoutButton menuConfig={menuConfig} onClick={this.handleDisconnect} />
          )}
        </Menu.Menu>
      </Menu>
    );
  }
}

AppMenu.defaultProps = {
  menu: [],
  menuConfig: {},
  variant: 'classic',
  loginPath: '/login',
};

AppMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      color: PropTypes.string,
      translations: PropTypes.object,
    }),
  ),
  menuConfig: PropTypes.shape({
    thumbnailButton: PropTypes.bool,
    icon: PropTypes.object,
    wrapper: PropTypes.object,
  }),
  variant: PropTypes.oneOf(['classic', 'tabs', 'buttons']),
  loginPath: PropTypes.string,
};

export default AppMenu;
