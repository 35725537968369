/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Dropdown, Icon, Menu, Sidebar } from 'semantic-ui-react';

import { handleChangeLanguage } from '../../../../components/AppHeader';
import ExpiringNavLink from '../../../../components/ExpiringNavLink';
import store from '../../../../shared/Store';
import './BurgerMenu.scss';
import BurgerUser from './BurgerUser';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CdnImage = require('../../../../components/CdnImage').default;

type BurgerMenuProps = {
  menu: Record<'name' | 'label' | 'path' | 'color' | 'icon', 'string'>[];
  language?: any;
  languageOptions?: any;
  user?: any;
  menuConfig?: Record<string, any>;
  loginPath: string;
};

type BurgerMenuState = {
  isVisible: boolean;
};

class BurgerMenu extends PureComponent<BurgerMenuProps, BurgerMenuState> {
  state = {
    isVisible: false,
  };

  handleDisconnect = async () => {
    await store.disconnect();
    this.forceUpdate();
    window.location.reload();
  };

  toggleMenuBars = () => {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  };

  handleChange = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const { menu, language, user, languageOptions, menuConfig = {}, loginPath } = this.props;
    const isAnonymous = user?.collection === 'anonymous';
    const { showUser, closeIcon = 'times', burgerIcon } = menuConfig;
    if (store.mustRedirectToLogin()) {
      // Handle disconnect
      return <Redirect to={loginPath} />;
    }
    const { isVisible } = this.state;
    return (
      <div className="sidebar--burger-menu">
        <div className="sidebar--burger-menu__header">
          {burgerIcon ? (
            <CdnImage src={burgerIcon} maxHeight={32} maxWidth={32} className="burger-icon" />
          ) : (
            <Icon
              name="bars"
              style={{ fontSize: 32, marginTop: 8, marginLeft: 4, marginRight: 8 }}
              onClick={this.toggleMenuBars}
            />
          )}
        </div>
        <Sidebar
          className="sidebar--burger-menu__container"
          animation="overlay"
          onHide={() => {
            this.setState({ isVisible: false });
          }}
          visible={isVisible}
          width="thin"
        >
          <Menu vertical icon="labeled" style={{ width: '100%' }}>
            <Menu.Item className="header" style={{ width: '100%' }}>
              <Menu.Header className="sidebar--burger-menu__container--header">
                <Icon
                  className={`${closeIcon} sidebar--burger-menu__container--header__close-button`}
                  style={{ fontSize: '1.2em' }}
                  onClick={this.handleChange}
                />
                <BurgerUser config={menuConfig} onDisconnect={this.handleDisconnect} />
              </Menu.Header>
            </Menu.Item>
            {menu.map((item) => {
              const { name, label, path, icon } = item;
              return (
                <Menu.Item
                  as={path ? ExpiringNavLink : undefined}
                  to={path}
                  exact
                  activeClassName="active"
                  key={name}
                  onClick={this.handleChange}
                  className={name}
                >
                  {icon && (
                    <CdnImage maxWidth={60} src={icon} style={{ maxWidth: 30, marginBottom: 8 }} />
                  )}
                  <div className="title">{label}</div>
                </Menu.Item>
              );
            })}
            {!showUser && !isAnonymous && (
              <Menu.Item className="item--action-logout" onClick={this.handleDisconnect}>
                Logout
              </Menu.Item>
            )}
          </Menu>
          {languageOptions && languageOptions.length > 1 && (
            <div className="dropdown--container">
              <Dropdown
                item
                className="dropdown--languages"
                placeholder="Language"
                compact
                selection
                options={languageOptions}
                value={language}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={handleChangeLanguage as any}
              />
            </div>
          )}
        </Sidebar>
      </div>
    );
  }
}

export default BurgerMenu;
