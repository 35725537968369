export function ensureArray<T>(v?: T | T[] | null): T[] {
  if (!v) return [];
  if (Array.isArray(v)) return v;
  return [v];
}

export function randomItem<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

interface ObjectWithId {
  _id: string;
}

export function updateOrAppendItem<T extends ObjectWithId>(list: T[], item: T): T[] {
  if (list.find((i) => i._id === item._id)) {
    // Update in list
    return list.map((i) => (i._id === item._id ? item : i));
  }
  return [...list, item]; // Append
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}
