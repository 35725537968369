/* eslint-disable no-template-curly-in-string */
import cx from 'classnames';
import { FC } from 'react';

import CdnImage from '../../components/CdnImage';
import { TextWithOverflow } from '../../components/TextWithOverflow/TextWithOverflow';
import { VideoPlayIcon } from '../../components/VideoPlayIcon/VideoPlayIcon';
import { bem } from '../../core/design/bem';
import { Label } from '../../design-system/Label/Label';
import { replaceValues } from '../../utils/stringUtils';
import { formatNumberToKiloString } from '../../utils/text.utils';
import { useSocialWallConfig } from '../social-wall-config.context';
import { Media, PublicationAuthor, PublicationInteractions } from '../types/SocialWallV2.types';
import { EmoteList } from './EmoteList';
import './PublicationCard.scss';
import { PublicationHeader } from './PublicationHeader';

interface PublicationCardProp {
  date?: string;
  message?: string;
  userInteractions: string[];
  media?: Media;
  thumbnail?: Media;
  interactions: PublicationInteractions;
  commentCount: number;
  author: PublicationAuthor;
  onClick?: (params?: any) => any;
  onInteractionClick?: (emote: string) => Promise<void>;
}
const css = bem('publication-card');

export const PublicationCard: FC<PublicationCardProp> = ({
  author,
  media,
  date,
  interactions = {},
  commentCount = 0,
  message,
  userInteractions,
  thumbnail,
  onClick,
  onInteractionClick,
}) => {
  const { commentsEnabled, interactionsEnabled } = useSocialWallConfig();

  const isVideo = media?.mimeType?.split('/')?.[0] === 'video';
  const formattedCommentCount = formatNumberToKiloString(commentCount);

  const displayedMedia = thumbnail || media;
  const isHorizontal =
    displayedMedia && (displayedMedia.width || 0) >= (displayedMedia.height || 0);

  return (
    <div className={css()} onClick={onClick}>
      {/* TO DO: use event config to display user informations*/}
      <PublicationHeader
        user={author}
        date={date}
        description={replaceValues('{jobTitle} {company}', author)}
        sideDate
      />
      <div className={css('content')}>
        <TextWithOverflow text={message} overflowEllipsis={!!media} />
        {displayedMedia && (
          <div
            className={cx(
              'image-wrapper',
              isHorizontal ? 'image-wrapper__horizontal' : 'image-wrapper__vertical',
            )}
          >
            <CdnImage src={displayedMedia?.uri} maxWidth={600} maxHeight={400} loading="lazy" />
            {isVideo && <VideoPlayIcon />}
          </div>
        )}
      </div>
      <div className={css('footer', { end: !interactionsEnabled })}>
        <EmoteList
          interactions={interactions}
          userInteractions={userInteractions}
          onEmoteClick={onInteractionClick}
        />
        {commentsEnabled && (
          <Label
            flat
            value={formattedCommentCount}
            icon={{ size: 16, name: 'comment', color: 'grey' }}
          />
        )}
      </div>
    </div>
  );
};
