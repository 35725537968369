import i18n from '../../../translations/i18n';
import { UserAction } from './types';

export const disconnectAction = {
  key: 'disconnect',
  icon: 'power_settings_new',
  label: i18n.t('profile-v2.actions.disconnect'),
  default: true,
};

export const userActions: UserAction[] = [
  {
    key: 'update-password',
    icon: 'lock',
    label: i18n.t('profile-v2.actions.update-password'),
    default: true,
  },
  {
    key: 'edit-profile',
    icon: 'edit',
    label: i18n.t('profile-v2.actions.edit-profile'),
    default: true,
  },
  {
    key: 'display-qrcode',
    icon: 'qr_code_scanner',
    label: i18n.t('profile-v2.actions.display-qrcode'),
  },
  {
    key: 'download-badge',
    icon: 'badge',
    label: i18n.t('profile-v2.actions.download-badge'),
  },
  {
    key: 'download-plane-tickets',
    icon: 'airplane_ticket',
    label: i18n.t('profile-v2.actions.download-plane-tickets'),
  },
  {
    key: 'download-agenda',
    icon: 'calendar_today',
    label: i18n.t('profile-v2.actions.download-agenda'),
  },
  { key: 'download-file', icon: 'download', label: i18n.t('profile-v2.actions.download-file') },
  {
    key: 'enable-networking',
    icon: 'forum',
    label: i18n.t('profile-v2.actions.enable-networking'),
  },
];
