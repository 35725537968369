import startsWith from 'lodash/startsWith';

import { isVideo } from '../components/cms/utils';
import { getUrl, isChineseHost } from './Images';

function matchId(url: string, reg: RegExp, fn: (id: string) => any) {
  const matches = url.match(reg);
  if (matches && matches.length > 1) {
    const id = matches[1];
    return fn(id);
  }
  return undefined;
}

function makeYoutubeUrl(id: string) {
  return `https://www.youtube.com/embed/${id}?rel=0&autoplay=0&enablejsapi=0&wmode=opaque`;
}

function extractYoutubeId(url: string) {
  return (
    matchId(url, /.*youtube.*v=([a-zA-Z0-9_-]+).*/i, (id) => id) ||
    matchId(url, /.*youtu.be\/[^a-zA-Z0-9_-]*([a-zA-Z0-9_-]+).*/i, (id) => id)
  );
}

export function videoCDN(video: string | { uri: string }): string {
  const url = getUrl(video);
  if (!url) return url;
  if (isChineseHost()) {
    return url.replace(
      'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001/events/',
      `https://${window.location.host}/cdn/videos/`,
    );
  }
  return url.replace(
    'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001/events/',
    'https://cdn.appcraft.events/videos/',
  );
}

// TODO: later
// function extractVimeoEvent(url) {

//   return (
//     matchId(
//       url,
//       // vimeo.com/event/id
//       /.*vimeo.com\/event\/([^/]+)/i,
//       (id) => `https://vimeo.com/event/${id}/embed`,
//     ) ||
//     matchId(
//       url,
//       // other vimeo urls
//       /.*vimeo.*\/([a-zA-Z0-9_-]+).*/i,
//       (id) => `https://player.vimeo.com/video/${id}`,
//     )
//   );
// }

export function parseVideo(url: string): { type: string; url: string; id?: string } | undefined {
  if (!url) return undefined;

  // Check for vodalys
  if (url.match(/.*console.vodalys.studio.*/)) {
    return { type: 'iframe', url };
  }
  if (url.match(/.*akamaized\.net.*/)) {
    return { type: 'iframe', url };
  }

  if (url.match(/.*vimeo.*\/embed/) || url.match(/.*player.vimeo.com.*/)) {
    // Already an embed link, don't change anything
    return { type: 'vimeo', url };
  }

  const youtubeId = extractYoutubeId(url);
  if (youtubeId) {
    if (url.includes('embed')) {
      // Already an embed link, don't change
      return { type: 'iframe', url };
    }
    return { type: 'youtube', id: youtubeId, url: makeYoutubeUrl(youtubeId) };
  }
  if (url.match(/\.mp4$/i)) {
    return { type: 'video', url: videoCDN(url) };
  }
  if (
    url.match(/clickmeeting/i) ||
    url.match(/blastream.com/i) ||
    url.match(/zoom.us/i) ||
    url.match(/appcraft\.events\/meet\/.*\/cm\//)
  ) {
    return { type: 'meeting', url };
  }

  if (url.match(/^https:\/\/api.appcraft.events\/files\/v1\/([a-zA-Z0-9_-]+)\/folders/)) {
    // Use CDN for url viewers
    return {
      type: 'iframe-viewer',
      url: url.replace(
        /^https:\/\/api.appcraft.events\/files\/v1\/([a-zA-Z0-9_-]+)\/folders/,
        'https://cdn.appcraft.events/viewer/$1/folders',
      ),
    };
  }
  if (startsWith(url, 'https://static.appcraft.events/live')) {
    // Auto-inject params if needed...
    if (!url.includes('userId=')) {
      const autoParams =
        'userId={userId}&firstName={firstName}&lastName={lastName}&thumbnail={thumbnail.uri}';
      const join = url.includes('?') ? '&' : '?';
      return { type: 'iframe', url: `${url}${join}${autoParams}` };
    }
  }
  return { type: 'iframe', url };
}

// TODO: use video player from fileRobot to display video thumbnail
export const generateVideoThumbnail = async (
  videoUrl: string,
  thumbnailTime?: number,
  imageFormat?: string,
): Promise<string> => {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  video.style.display = 'none';
  canvas.style.display = 'none';

  // Trigger video load
  await new Promise((resolve) => {
    video.addEventListener('seeked', () => resolve(true));

    video.addEventListener('loadedmetadata', () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth / 4;
      canvas.height = video.videoHeight / 4;

      video.currentTime = thumbnailTime ?? video.duration * 0.25;
    });
    video.src = videoUrl;
  });

  canvas.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth / 4, video.videoHeight / 4);
  const imageUrl = canvas.toDataURL(imageFormat || 'image/jpg');
  return imageUrl;
};

export const isVideoMedia = (src: { mimeType: string; filename: string }): boolean => {
  if (!src) return false;
  if (startsWith(src.mimeType, 'video/')) return true;
  return isVideo(src.filename);
};
