/* eslint-disable no-else-return */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button, Card, Icon, Label } from 'semantic-ui-react';

import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { useDesignConfig } from '../../../config/design.context';
import { bem } from '../../../core/design/bem';
import useTranslations from '../../../hooks/useTranslations';
import { checkUserAvailability } from '../../../utils/agendaUtils';
import { useFieldLabel } from '../../../workshops/components/WorkshopsBlock/WorkshopsBlock';
import { getWorkshopString } from '../../../workshops/utils';
import { useWorkshopRegistration } from '../../../workshops/utils/workshopRegistrationUtils';
import CdnImage from '../../CdnImage';
import EntityLink from '../../EntityLink';
import SpeakerAvatars from '../../speakers/SpeakerAvatars';
import QuotaField from '../QuotaField';
import WorkshopDate from '../WorkshopDate';
import { AddToCalendarButton } from '../workshop-templates/actions/AddToCalendarButton';
import './WorkshopCard3dx.scss';

const css = bem('workshop-card-3dx');
const translationPrefix = 'workshops.card';

const ActionButton = ({
  agendaCard,
  loading,
  mandatory,
  registration,
  registerWorkshop,
  unregisterWorkshop,
  workshop,
}) => {
  const { t } = useTranslations(translationPrefix);
  if (agendaCard) {
    return (
      <div className={css('action-buttons').toString()}>
        <AddToCalendarButton item={workshop} />
        <Button
          as={EntityLink}
          entity={workshop}
          forcePath="/live"
          basic
          floated="right"
          className={css('button', { accessLive: true }).toString()}
        >
          {t('access-live')}
        </Button>
        <Button
          basic
          floated="right"
          className={css('button', { removeFromSchedule: true, mandatory }).toString()}
          onClick={(e) => {
            e.preventDefault();
            unregisterWorkshop();
          }}
          loading={loading}
          disabled={mandatory || loading}
        >
          {mandatory ? t('mandatory') : t('unsubscribe')}
        </Button>
      </div>
    );
  } else if (!registration && !mandatory && !agendaCard) {
    return (
      <Button
        basic
        floated="right"
        className={css('button', { addToSchedule: true }).toString()}
        onClick={(e) => {
          e.preventDefault();
          registerWorkshop();
        }}
        loading={loading}
        disabled={loading}
      >
        {t('add-to-my-schedule')}
      </Button>
    );
  } else {
    return null;
  }
};

const WorkshopCard3dx = (props) => {
  const { workshop, showSpeakers, agendaCard, speakersConfig, showEndTime, registration } = props;
  const { primaryColor } = useDesignConfig();

  const {
    _id,
    color = primaryColor,
    endDate,
    image,
    languages,
    mandatory = false,
    quota,
    category,
    shortDescription,
    speakers,
    startDate,
    title,
    subtitle,
    location,
    usersCount,
  } = workshop;

  const { t } = useTranslations(translationPrefix);
  const labelProvider = useFieldLabel({ kind: 'workshops', ...workshop });
  const { loading, registerWorkshop, unregisterWorkshop } = useWorkshopRegistration(workshop);
  const agenda = useSelector(userAgenda);
  const hasOverlappingEvent = useMemo(() => {
    const otherEvents = agenda.filter((evt) => evt._id !== _id);
    return !checkUserAvailability(otherEvents, workshop);
  }, [agenda, workshop, _id]);

  return (
    <div
      className={css({
        'session--has-overlapping-event': hasOverlappingEvent,
        'can-unregister': agendaCard,
        category,
        _id,
      }).toString()}
    >
      <Card as={!agendaCard ? EntityLink : undefined} entity={!agendaCard ? workshop : undefined}>
        <Card.Content className={css('banner').toString()} style={{ backgroundColor: color }}>
          <CdnImage src={image} wrapped maxWidth={800} />
          <div className="headers">
            <Card.Header className="title">{title}</Card.Header>
            {!!subtitle && <div className="subtitle">{subtitle}</div>}
          </div>
        </Card.Content>
        <Card.Content className={css('schedule').toString()}>
          <Card.Description>
            <WorkshopDate
              startDate={startDate}
              endDate={endDate}
              showEndTime={showEndTime}
              variant="3dx"
            />
          </Card.Description>
          {!!location && (
            <Card.Description>
              <Icon name="map marker alternate" />
              {location}
            </Card.Description>
          )}
        </Card.Content>
        <Card.Content className={css('buttons').toString()}>
          <Label
            className={css('label', { field: 'category', category: workshop.category }).toString()}
            style={{ backgroundColor: color, color: '#FFF' }}
          >
            {getWorkshopString(workshop, 'category')}
          </Label>
          <ActionButton
            agendaCard={agendaCard}
            hasOverlappingEvent={hasOverlappingEvent}
            loading={loading}
            mandatory={mandatory}
            registerWorkshop={registerWorkshop}
            unregisterWorkshop={unregisterWorkshop}
            registration={registration}
            workshop={workshop}
          />
          {(registration || mandatory) && !agendaCard && (
            <Label
              className={css('label', { registered: true }).toString()}
              style={{ backgroundColor: 'var(--ac-color-success-dark)', color: '#FFF' }}
            >
              {t('registered')}
            </Label>
          )}
        </Card.Content>
        <Card.Content className={css('hr').toString()}>
          <hr />
        </Card.Content>
        {!agendaCard && <QuotaField quota={quota} usersCount={usersCount} />}
        {showSpeakers && (
          <Card.Content extra className={css('content', { speakers: true }).toString()}>
            <Card.Meta> {t('speakers')}</Card.Meta>
            <Card.Description>
              <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />
            </Card.Description>
          </Card.Content>
        )}
        {shortDescription && (
          <Card.Content extra className={css('content', { description: true }).toString()}>
            <Card.Meta> {t('description')}</Card.Meta>
            <Card.Description>{shortDescription}</Card.Description>
          </Card.Content>
        )}
        {languages && languages.length > 0 && (
          <Card.Content extra className={css('content', { languages: true }).toString()}>
            <Card.Meta> {t('languages')}</Card.Meta>
            <Card.Description>
              {languages.map((v) => labelProvider('languages', v)).join(', ')}
            </Card.Description>
          </Card.Content>
        )}
      </Card>
    </div>
  );
};

WorkshopCard3dx.defaultProps = {
  agendaCard: false,
  registration: undefined,
  showSpeakers: false,
  showEndTime: false,
  speakersConfig: {},
};

WorkshopCard3dx.propTypes = {
  agendaCard: PropTypes.bool,
  registration: PropTypes.object,
  showSpeakers: PropTypes.bool,
  showEndTime: PropTypes.bool,
  speakersConfig: PropTypes.object,
  workshop: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    category: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string.isRequired,
    ]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    image: PropTypes.object,
    languages: PropTypes.string,
    mandatory: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    shortDescription: PropTypes.string,
    speakers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  }).isRequired,
};

export default connect((state, props) => ({
  registration: state.registrations.registrationsById[props.workshop._id],
}))(WorkshopCard3dx);
