/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSize } from 'ahooks';
import React, { useRef } from 'react';

import { useScreensConfig } from '../../../config/screens.context';
import { bem } from '../../../core/design/bem';
import { VideoMenuEntry } from '../../../home/pages/HomeVideo3DX/HomeVideo3DX';
// eslint-disable-next-line import/no-cycle
import { Image, Video } from '../types';
import './HomeImageBlock.scss';
import Media from './components/Media';

const css = bem('HomeImageBlock');

type HomeImageBlockProps = {
  animation?: string;
  backgroundImage?: Image;
  dimensions: Record<string, any>;
  menuPosition?: string;
  entries: Record<string, any>[];
  variant?: 'video' | 'image';
  video?: Video;
};

const defaultDimensions = { width: 1920, height: 1080 };

function HomeImageBlock(props: HomeImageBlockProps): JSX.Element | null {
  const ref = useRef();
  const size = useSize(ref);
  const screens = useScreensConfig();

  const {
    dimensions = defaultDimensions,
    entries = [],
    backgroundImage,
    animation,
    variant = 'image',
    video,
    menuPosition,
  } = props;

  const filteredEntries = entries.filter((entry) => entry.pageId in screens);

  if (!video && !backgroundImage) return null;

  function renderMenu() {
    return (
      !!size.width && (
        <div className={css('menu')}>
          {filteredEntries.map((entry) => (
            // @ts-ignore
            <VideoMenuEntry
              animation={animation}
              key={entry._id}
              {...entry}
              containerSize={size}
              dimensions={dimensions}
            />
          ))}
        </div>
      )
    );
  }

  return (
    <div className={css()}>
      {/* @ts-ignore*/}
      <div ref={ref} className={css('wrapper--16_9', variant)}>
        <Media type={variant} image={backgroundImage} video={video} />
        {menuPosition === 'image' && renderMenu()}
      </div>
      {menuPosition === 'screen' && renderMenu()}
    </div>
  );
}

HomeImageBlock.defaultProps = {
  animation: 'fade-in',
  backgroundImage: undefined,
  menuPosition: 'screen',
  video: undefined,
  variant: 'image',
};

export default HomeImageBlock;
