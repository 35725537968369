/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable operator-linebreak */

/* eslint-disable react/require-default-props */

/** @jsxRuntime classic */

/** @jsx jsx */
import { css as applyCss, jsx } from '@emotion/react';
import max from 'lodash/max';
import { useMemo } from 'react';

import { useDesignConfig } from '../../../../config/design.context';
import { bem } from '../../../../core/design/bem';
import ItemComponent, { ItemConfig } from '../components/ItemComponent';
import SquareGridSpacer from '../components/SquareGridSpacer';
import { computeShadow } from '../styles';
import './GridLayoutVariant.scss';
import { IconGridVariantProps } from './variant.types';

const listCss = bem('IconGridItems');

// type Options = {
//   itemsPerRow: number;
//   showBackground: boolean;
//   showIcon: boolean;
//   underlineTitle: boolean;
//   aspectRatio: string;
//   overlayColor: string;
//   borderRadius: number;
//   border: string;
// };

const GridLayoutVariant = (
  props: IconGridVariantProps & { itemConfig?: ItemConfig },
): JSX.Element => {
  const design = useDesignConfig();
  const { variant, items, itemConfig } = props;
  const { aspectRatio = '1 / 1' } = itemConfig || {};

  const emotionCss = useMemo(() => {
    const { primaryColor } = design;
    const {
      gridGap = 8,
      itemsPerRow = 3,
      borderRadius,
      border,
      shadow,
      cssStyle,
      overlay,
      css: cssCustom,
      underlineTitle = false,
    } = itemConfig || {};

    const extraStyles = [
      underlineTitle && {
        '.IconGridItem__Title:after': {
          content: '""',
          backgroundColor: primaryColor,
        },
      },
    ].filter((v) => v);

    return applyCss(
      cssCustom,
      cssStyle,
      {
        '&.IconGridItems': {
          gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
          gridGap,
        },
        '.IconGridItem': {
          borderRadius,
          border,
          boxShadow: computeShadow(shadow),
        },

        '.IconGridItem__Content': {
          background: overlay,
          borderRadius,
        },
        '.IconGridItem__Title': {
          fontSize: `${1.25 - itemsPerRow * 0.1}em`,
        },
      },
      ...extraStyles,
    );
  }, [itemConfig, design]);

  const rowCount = max(
    items
      .filter((item) => (item as any).gridConfig)
      .map((item) => {
        const { gridConfig } = item as any;
        const { y, h } = gridConfig || {};
        return y + h;
      }),
  );

  return (
    <div css={emotionCss} className={listCss({ variant })}>
      <SquareGridSpacer rowCount={rowCount} aspectRatio={aspectRatio} />
      {items
        .filter((item) => (item as any).gridConfig)
        .map((item) => {
          const { gridConfig } = item as any;
          const { x, y, w, h } = gridConfig || {};
          return (
            <ItemComponent
              key={item._id}
              variant={variant}
              item={item}
              itemConfig={itemConfig}
              style={{
                gridColumn: `${x + 1} / ${x + 1 + w}`,
                gridRow: `${y + 1} / ${y + 1 + h}`,
              }}
            />
          );
        })}
    </div>
  );
};

GridLayoutVariant.defaultProps = {
  itemConfig: {},
};

export { GridLayoutVariant };
