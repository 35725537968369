import { FC, Fragment, useMemo } from 'react';
import { useMedia } from 'react-media';
import { Redirect } from 'react-router-dom';
import { Container, Grid, Header } from 'semantic-ui-react';

import { CmsPageLayout } from '../../cms/CmsPage';
import MaterialIcon from '../../components/Icons/MaterialIcon';
import SquareImage from '../../components/SquareImage';
import { TabbedMenu } from '../../components/TabbedMenu/TabbedMenu';
import { useLoginPath, useScreenConfig } from '../../config/screens.context';
import { bem } from '../../core/design/bem';
import { defaultThumbnail } from '../../gamification/leaderboard/components/Podium';
import useUrlState from '../../hooks/useUrlState';
import store from '../../shared/Store';
import i18n from '../../translations/i18n';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import { replaceValues } from '../../utils/stringUtils';
import { formatUsername } from '../../utils/userUtils';
import { useMe } from '../hooks';
import './ProfilePageV2.scss';
import { ActionsTab } from './components/ActionsTab';
import { AgendaTab } from './components/AgendaTab';
import { UserInfoTab } from './components/UserInfoTab';
import { FlightTickets } from './components/actions/FlightTickets';
import { QRcode } from './components/actions/QRcode';

const css = bem('ProfilePageV2');

interface ProfilePageProps {
  pageId: string;
  design?: Record<string, any>;
  metadata?: Record<string, any>;
  pageFooter?: Record<string, any>;
  pageHeader?: Record<string, any>;
}

type Tab = {
  enabled?: boolean;
  title: string;
  component: FC<any>;
} & Record<string, any>;

const defaultTabs: Record<string, Tab> = {
  infos: { enabled: true, title: i18n.t('profile-v2.tabs.information'), component: UserInfoTab },
  actions: { enabled: true, title: i18n.t('profile-v2.tabs.actions'), component: ActionsTab },
  agenda: { enabled: true, title: i18n.t('profile-v2.tabs.agenda'), component: AgendaTab },
};

function hasAction(tabs: Tab, action: string) {
  return tabs?.actions?.actions?.find(({ key }: { key: string }) => key === action);
}

const ProfilePageV2: FC<ProfilePageProps> = (props) => {
  const { design, metadata, pageFooter, pageHeader, pageId } = props;
  const me = useMe();
  const screen = useScreenConfig(pageId);
  const loginPath = useLoginPath();
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const { tabs = defaultTabs, config = {}, qrcode, file } = screen;
  const hasQRcode = hasAction(tabs, 'display-qrcode');
  const hasPlaneTickets = hasAction(tabs, 'download-plane-tickets');
  const tabKeys = ['infos', 'actions', 'agenda']
    .map((key) => (!(key in tabs) || tabs[key]?.enabled ? key : null))
    .filter((v) => v) as string[];

  const menuTabs = useMemo(
    () =>
      tabKeys
        .map((tab) => ({ ...defaultTabs[tab], ...tabs[tab], name: tab }))
        .filter((t) => t.enabled),
    [tabKeys, tabs],
  );

  const desktopMenus = menuTabs.filter((m) => m.name !== 'infos').reverse();

  const [urlState, setUrlState] = useUrlState({
    activeTab: !mobile ? desktopMenus[0].name : tabKeys[0] || undefined,
  });
  const { activeTab } = urlState;
  const Component = defaultTabs[activeTab]?.component;

  const handleClick = (_e: any, { name }: { name: string }) => {
    setUrlState({ activeTab: name });
  };

  const { thumbnail = defaultThumbnail } = me;
  const { imageSize = 80, subtitleTemplate = '{company}' } = config;
  const isInfoTab = activeTab === 'infos';

  const ScreenContainer = mobile ? Fragment : Container;
  const mode = mobile ? 'mobile' : 'desktop';

  if (!store.isLoggedIn()) {
    return <Redirect to={loginPath} />;
  }
  return (
    <CmsPageLayout
      fullheight
      className={css({ mode }).toString()}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      metadata={metadata}
      designOverride={design}
    >
      <div className={css('Header')}>
        <Header as="h3" className="title">
          {formatUsername(me)}
        </Header>
        <div className="subtitle"> {replaceValues(subtitleTemplate, me)}</div>
      </div>

      <ScreenContainer>
        <Grid className={css('Content').toString()} columns={2}>
          <Grid.Column className="infos" width={mobile ? 16 : 6}>
            <div className="image-and-actions">
              {hasPlaneTickets && (isInfoTab || !mobile) && (
                <div
                  className="action plane"
                  onClick={() => FlightTickets.open({ user: me, file })}
                >
                  <MaterialIcon name="airplane_ticket" />
                </div>
              )}
              <SquareImage
                className="userAvatar"
                src={thumbnail}
                imageSize={imageSize}
                style={{ width: imageSize, height: imageSize }}
              />
              {hasQRcode && (isInfoTab || !mobile) && (
                <div className="action qrcode" onClick={() => QRcode.open({ user: me, qrcode })}>
                  <MaterialIcon name="qr_code_scanner" />
                </div>
              )}
            </div>
            {tabKeys.length > 0 && mobile && (
              <div className={css('Tabs')}>
                <TabbedMenu activeTab={activeTab} tabs={menuTabs} onClick={handleClick} />
              </div>
            )}
            {mobile ? (
              <Component {...tabs[activeTab]} user={me} config={screen} />
            ) : (
              <UserInfoTab {...tabs.infos} user={me} config={screen} />
            )}
          </Grid.Column>
          {!mobile && (
            <Grid.Column width={10}>
              <div className={css('Tabs')}>
                <TabbedMenu activeTab={activeTab} tabs={desktopMenus} onClick={handleClick} />
              </div>
              <Component {...tabs[activeTab]} user={me} config={screen} />
            </Grid.Column>
          )}
        </Grid>
      </ScreenContainer>
    </CmsPageLayout>
  );
};

export default ProfilePageV2;
