/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Embed } from 'semantic-ui-react';

import { useTracking } from '../Context';
import { eventTags } from '../core/trackers/events';
import { useMe } from '../profile/hooks';
import store from '../shared/Store';
import { cloudimg } from '../utils/Images';
import { replaceUriValues } from '../utils/stringUtils';
import { parseVideo } from '../utils/videoUtils';
import './VideoEmbed.scss';
import { ReactVideoPlayer } from './video/components/ReactVideoPlayer';
import { VimeoVideoPlayer } from './video/components/VimeoVideoPlayer';
import { VodalysVideoPlayer } from './video/components/VodalysVideoPlayer';

export function EmbedWithIframeListener({ onInferredClick, iframe, ...props }) {
  const iframeRef = useRef(null);
  const onInferredClickRef = useRef(onInferredClick);
  onInferredClickRef.current = onInferredClick;

  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === 'iframe' &&
        iframeRef.current === document.activeElement &&
        onInferredClickRef.current
      ) {
        onInferredClickRef.current(iframeRef.current);
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return <Embed {...props} iframe={{ allowTransparency: 'true', ...iframe, ref: iframeRef }} />;
}

EmbedWithIframeListener.defaultProps = {
  iframe: undefined,
  onInferredClick: undefined,
};

EmbedWithIframeListener.propTypes = {
  iframe: PropTypes.object,
  onInferredClick: PropTypes.func,
};

const VideoEmbed = ({
  url,
  id,
  allowFullScreen,
  autoPlay,
  videoProps,
  statsMode,
  block,
  ...props
}) => {
  const me = useMe();
  const { trackEvent } = useTracking();
  const { item, video } = props;

  // eslint-disable-next-line no-extra-boolean-cast
  const videoTag = item ? eventTags.ITEM_VIDEO_VIEW : eventTags.VIDEO_VIEW;
  const videoTitle = video?.title || video?.originalFilename || '';

  const videoBlockId = video?._id || block?._id;
  const handleVideoClick = () => {
    trackEvent(videoTag, { item, video, videoUrl: url, videoTitle });
  };
  const handleVideoStat = (stat) => {
    trackEvent(eventTags.VIDEO_VIEW_SECTION, {
      entity: pick(item, ['_id', 'collection', 'kind', 'workshopId', 'title']),
      entityId: item?._id,
      entityType: item?.collection || 'video',
      entityKind: item?.kind,
      video,
      videoUrl: url,
      videoTitle,
      ...stat,
    });
  };

  // Check video type...
  if (!url && !id) return null; // Nothing to show

  if (!url && id) {
    // Fallback to default embed mode
    return <EmbedWithIframeListener id={id} {...props} onInferredClick={handleVideoClick} />;
  }

  const { type, id: videoId, url: embedUrl } = parseVideo(url);
  const customUrl = replaceUriValues(embedUrl, { ...me, userId: store?.user?._id });
  switch (type) {
    case 'youtube': {
      return (
        <ReactVideoPlayer
          key={customUrl}
          {...props}
          autoplay={autoPlay}
          item={item}
          block={block}
          videoId={videoId}
          url={customUrl}
          originalUrl={url}
          _id={videoBlockId}
          onPlay={handleVideoClick}
          onSectionStat={handleVideoStat}
        />
      );
    }
    case 'video': {
      const { placeholder } = props;
      return (
        <div className="ui active embed">
          <div className="embed">
            <video
              autoPlay={autoPlay}
              allowFullScreen={allowFullScreen}
              controls
              poster={cloudimg(placeholder, { w: 1920, h: 1080, func: 'crop' })}
              {...videoProps}
              onPlay={handleVideoClick}
            >
              <source src={customUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      );
    }
    case 'meeting': {
      const { iframe, ...otherProps } = props;
      return (
        <EmbedWithIframeListener
          iframe={{
            allowFullScreen,
            allow: 'camera;microphone;display-capture',
            ...iframe,
          }}
          {...otherProps}
          url={customUrl}
          onInferredClick={handleVideoClick}
        />
      );
    }

    case 'vimeo': {
      const { iframe, ...otherProps } = props;

      // Only use the Vimeo player if available...
      // TODO: autoload
      if (window.Vimeo) {
        return (
          <VimeoVideoPlayer
            key={customUrl}
            iframe={{ allowFullScreen, ...iframe }}
            item={item}
            block={block}
            {...otherProps}
            _id={videoBlockId}
            url={customUrl}
            onPlay={handleVideoClick}
            onSectionStat={handleVideoStat}
          />
        );
      }
      // break; // continue
    }

    // eslint-disable-next-line no-fallthrough
    default: {
      const { iframe, ...otherProps } = props;

      if (statsMode === 'vodalys') {
        return (
          <VodalysVideoPlayer
            key={customUrl}
            iframe={{
              allowFullScreen,
              allow: 'camera;microphone;display-capture',
              ...iframe,
            }}
            {...otherProps}
            _id={videoBlockId}
            item={item}
            block={block}
            url={customUrl}
            onPlay={handleVideoClick}
            onSectionStat={handleVideoStat}
          />
        );
      }

      return (
        <EmbedWithIframeListener
          iframe={{
            allowFullScreen,
            allow: 'camera;microphone;display-capture',
            ...iframe,
          }}
          {...otherProps}
          url={customUrl}
          onInferredClick={handleVideoClick}
        />
      );
    }
  }
};

VideoEmbed.defaultProps = {
  allowFullScreen: true,
  autoPlay: false,
  id: undefined,
  url: undefined,
};

VideoEmbed.propTypes = {
  allowFullScreen: PropTypes.bool,
  autoPlay: PropTypes.bool,
  id: PropTypes.string,
  url: PropTypes.string,
};

export default VideoEmbed;
