import moment from 'moment';

import { UserType } from '../../../core/users/users.types';
import i18n from '../../../translations/i18n';
import { MeetingRequest } from './types';

export function createDefaultMeeting(user: UserType): MeetingRequest {
  const tomorrow = moment().add(1, 'day');
  return {
    date: tomorrow.format('YYYY-MM-DD'),
    startTime: '10:00',
    endTime: '11:00',
    location: '',
    message: i18n.t('networking.appointment.default-message', { user }),
  };
}
