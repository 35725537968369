import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

import GlobalModalManager from '../../../../components/GlobalModalManager';
import MaterialIcon from '../../../../components/Icons/MaterialIcon';
import { QueryModal } from '../../../../components/QueryModal/QueryModal';
import { bem } from '../../../../core/design/bem';
import { UserType } from '../../../../core/users/users.types';
import { formatUsername } from '../../../../utils/userUtils';
import UserFiles from '../../../components/UserFiles';
import { UserFileConfig } from '../../../components/UserFiles/UserFiles';

const css = bem('FlightTicketsAction');

type FlightTicketsStatic = {
  open: (props: any) => Promise<boolean | null>;
};

type FlightTicketsProps = {
  user: UserType;
  file: UserFileConfig;
  onCancel: () => void;
};

export const FlightTickets: FC<FlightTicketsProps> & FlightTicketsStatic = (props) => {
  const { t } = useTranslation();
  const { user, file, onCancel, ...restProps } = props;
  return (
    <QueryModal
      className={cx('Action', 'FlightTicketsAction')}
      draggable
      open
      closeIcon
      onCancel={onCancel}
      initialStep={60}
      size="small"
      {...restProps}
    >
      <div className={css('text')}>
        <MaterialIcon className="icon" size={60} name="airplane_ticket" />
        <Header as="h3">{formatUsername(user)}</Header>
        {t('profile-v2.actions.flight-tickets.text')}
      </div>
      <UserFiles user={user} fileConfig={file} buttonProps={{ basic: false }} />
    </QueryModal>
  );
};
FlightTickets.open = async (props: FlightTicketsProps): Promise<boolean | null> => {
  return GlobalModalManager.open(FlightTickets, props);
};
