import cx from 'classnames';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Item, Label } from 'semantic-ui-react';

import { UserFlag } from '../../components/Avatars/UserFlag';
import { useScreenConfig } from '../../config/screens.context';
import { avatarWithFallbackWhenMissing } from '../../utils/avatarUtils';
import InviteButton from '../components/InviteButton';
import { InviteStatuses } from './NetworkingParticipantList.helpers';
import './NetworkingUserItem.scss';

const NetworkingUserItem = ({ item, onConnect, status }) => {
  const { displayCustomFields } = useScreenConfig('networking');
  const validFields = (displayCustomFields || []).filter((field) => item[field.field]);
  const [tags, info] = partition(validFields || [], (field) => field.type === 'tag');
  return (
    <Item className={cx('NetworkingUserItem', item.category && `category--${item.category || ''}`)}>
      <Item.Image
        size="tiny"
        src={avatarWithFallbackWhenMissing(item.thumbnail, 70)}
        circular
        className="NetworkingUserItem--Avatar"
      />
      <UserFlag user={item} />
      <Item.Content className="NetworkingUserItem--Content">
        <InviteButton
          status={status}
          userId={item._id}
          onConnect={() => {
            return onConnect(item);
          }}
        />
        <Item.Header>
          {item.firstName} {item.lastName}{' '}
          {tags.map((field) => (
            <Label size="small" basic>
              {item[field.field]}
            </Label>
          ))}
        </Item.Header>
        <Item.Meta className="NetworkingUserItem--Meta">
          {info.map((field) => (
            <div key={field.field} className="NetworkingUserItem--Meta--Field">
              {field.icon && <Icon name={field.icon} />}&nbsp;{item[field.field]}
            </div>
          ))}
        </Item.Meta>
      </Item.Content>
    </Item>
  );
};

NetworkingUserItem.propTypes = {
  item: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.values(InviteStatuses)).isRequired,
  onConnect: PropTypes.func.isRequired,
};

export default NetworkingUserItem;
