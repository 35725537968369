/* eslint-disable react/no-array-index-key*/
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Card, Header } from 'semantic-ui-react';

import { useTracking } from '../../../Context';
import { eventTags } from '../../../core/trackers/events';
import { containerTypePropTypes } from '../../../propTypes';
import BlockContainer from '../../BlockContainer';
import { isImage, isPDF } from '../utils';
import './FilesBlock.scss';
import FileCard from './components/FileCard';
import ImageLightbox from './components/ImageLightbox';
import PDFModal from './components/PDFModal';

export function sanitizeFile(file) {
  return {
    ...file,
    title: file?.title || file?.originalFilename || file?.value?.originalFilename,
    originalFilename: file?.originalFilename || file.value?.originalFilename,
    uri: file?.uri || file?.value?.uri,
  };
}

export function useFileTracking(block) {
  const { trackEvent } = useTracking();
  return useMemo(() => {
    function onDownload(file) {
      trackEvent(eventTags.DOWNLOAD_FILE, {
        ...file,
        file: { _id: file._id },
        item: block,
      });
    }
    function onView(file) {
      trackEvent(eventTags.VIEW_FILE, {
        ...file,
        file: { _id: file._id },
        item: block, // Parent item => cms block
      });
    }
    return { onDownload, onView };
  }, [block, trackEvent]);
}

function FilesBlock(props) {
  const { id, title, subtitle, files, containerType } = props;
  const [lightbox, setLightbox] = useState(null);
  const [pdfToView, setPdfToView] = useState(null);

  const { onDownload, onView } = useFileTracking(props);

  const cleanFiles = useMemo(() => {
    if (!files) return [];
    return files.map(sanitizeFile);
  }, [files]);

  if (cleanFiles.length === 0) return null;

  return (
    <BlockContainer id={id} type={containerType} className="block--cms-files">
      <Header as="h3" style={{ marginBottom: 0 }}>
        {title}
      </Header>
      <Header as="h4" style={{ marginTop: 0 }}>
        {subtitle}
      </Header>
      <Card.Group>
        {cleanFiles.map((file, index) => {
          const { uri } = file;

          function setCurrentFile() {
            onView(file);
            if (isImage(uri)) {
              // is image
              const images = cleanFiles.filter((f) => isImage(f.uri));
              setLightbox({ images, currentIndex: findIndex(images, (i) => i.uri === uri) });
            } else if (isPDF(uri)) {
              setPdfToView(file);
            }
          }

          return (
            <FileCard
              key={index}
              file={file}
              onDownload={() => onDownload(file)}
              onClickFile={setCurrentFile}
            />
          );
        })}
      </Card.Group>
      {lightbox && <ImageLightbox {...lightbox} onClose={() => setLightbox(null)} />}
      {pdfToView && <PDFModal file={pdfToView} onClose={() => setPdfToView(null)} />}
    </BlockContainer>
  );
}

FilesBlock.defaultProps = {
  containerType: 'segment',
  files: [],
  subtitle: undefined,
  title: undefined,
};

FilesBlock.propTypes = {
  containerType: containerTypePropTypes,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default FilesBlock;
