/* eslint-disable react/sort-comp */
import React, { CSSProperties } from 'react';

import { uuid } from '../../core/trackers/utils';

export type ModalProps<Input, Output = unknown> = {
  onValidate: (data?: Output) => unknown;
  onCancel: () => unknown;
  style?: CSSProperties;
  [key: string]: unknown;
} & Input;

export type ModalComponent<Input, Output = unknown> =
  | React.ComponentClass<ModalProps<Input, Output>>
  | React.FC<ModalProps<Input, Output>>;

export type ModalResolveFunction<T = unknown> = (data?: T | null) => void;

export type ModalManagerFunction<Input, Output> = (data: Input) => Promise<Output | null>;

type ModalComponentItem<Input, Output = unknown> = {
  Modal: ModalComponent<Input, Output>;
  id: string;
  props: ModalProps<Input, Output>;
};

interface GlobalModalManagerState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modals: ModalComponentItem<any>[];
}

const DEFAULT_MODAL_INDEX = 1000;

class GlobalModalManager extends React.Component<unknown, GlobalModalManagerState> {
  static instance?: GlobalModalManager;

  static async open<Input, Output>(
    Modal: ModalComponent<Input, Output>,
    modalProps: Input,
  ): Promise<Output | null> {
    if (!GlobalModalManager.instance) return null;
    return new Promise<Output | null>((resolve) => {
      GlobalModalManager.instance?.createModal(
        Modal,
        modalProps,
        resolve as ModalResolveFunction<Output>,
      );
    });
  }

  static isLastModalOpen(modalId: string): boolean {
    if (!GlobalModalManager.instance) return false;
    const { modals } = GlobalModalManager.instance.state;
    if (!modals.length) return false;

    const last = modals[modals.length - 1];
    return last.id === modalId;
  }

  state: GlobalModalManagerState = {
    modals: [],
  };

  close<Input, Output>(
    modalId: string,
    resolve: ModalResolveFunction<Output>,
    data?: Output,
  ): string | undefined {
    if (!modalId) return;

    this.destroyModal(modalId);

    resolve(data);
  }

  componentDidMount(): void {
    GlobalModalManager.instance = this;
  }

  createModal<Input, Output>(
    Modal: ModalComponent<Input, Output>,
    props: Input,
    resolve: ModalResolveFunction<Output>,
  ): ModalComponentItem<Input, Output> {
    const id = uuid();

    const modalProps = {
      ...props,
      onValidate: (data?: Output) => this.close(id, resolve, data),
      onCancel: () => this.close(id, resolve),
      id,
    };

    this.setState(({ modals }) => ({ modals: [...modals, { id, Modal, props: modalProps }] }));
    return { id, Modal, props: modalProps };
  }

  destroyModal(modalId: string): void {
    this.setState(({ modals }) => ({ modals: modals.filter((modal) => modal.id !== modalId) }));
  }

  render(): JSX.Element {
    const { modals } = this.state;
    return (
      <>
        {modals.map(({ id, Modal, props }, index) => (
          <Modal key={id} {...props} style={{ zIndex: DEFAULT_MODAL_INDEX + index }} />
        ))}
      </>
    );
  }
}

export default GlobalModalManager;
