import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { ModalProps } from '../../GlobalModalManager/GlobalModalManager';
import './AddMeetingRequest.scss';
import { MeetingRequest } from './types';

const css = bem('AddMeetingRequest');

type Props = {
  defaultRequest: MeetingRequest;
};

export const AddMeetingRequestModal: FC<ModalProps<Props, MeetingRequest>> = (props) => {
  const { onCancel, onValidate, defaultRequest } = props;
  const { t } = useTranslation();
  const [meeting, setMeeting] = useState<Record<string, any>>(defaultRequest);
  const { date, startTime, endTime, location, message } = meeting;
  const disabled = !date || !startTime || !endTime;

  const handleChange = (e: any, name: string) => {
    setMeeting({ ...meeting, [name]: e.target.value });
  };

  const handleSave = () => onValidate(meeting as MeetingRequest);

  return (
    <Modal size="small" open className={css().toString()}>
      <Modal.Header>
        <Icon name="calendar alternate outline" />
        {t('networking.options.make-appointment')}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <div className="label required">{t('networking.appointment.date')}</div>
            <Form.Input
              type="date"
              value={date}
              min={moment().format('YYYY-MM-DD')}
              onChange={(e) => handleChange(e, 'date')}
            />
            <Form.Input
              type="time"
              value={startTime}
              onChange={(e) => handleChange(e, 'startTime')}
            />
            <Form.Input type="time" value={endTime} onChange={(e) => handleChange(e, 'endTime')} />
          </Form.Group>
          <Form.Group widths="equal">
            <div className="label">{t('networking.appointment.location')}</div>
            <Form.Input value={location} onChange={(e: any) => handleChange(e, 'location')} />
          </Form.Group>
          <Form.Group widths="equal">
            <div className="label required">{t('networking.appointment.message')}</div>
            <Form.TextArea
              required
              value={message}
              onChange={(e: any) => handleChange(e, 'message')}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{t('btn.cancel')}</Button>
        <Button onClick={handleSave} primary disabled={disabled}>
          {t('btn.send')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
