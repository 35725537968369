import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';

import { useComponentOptions } from '../../../../config/design.context';
import { bem } from '../../../../core/design/bem';
import { speakerPropTypes } from '../../../../propTypes';
import { getString } from '../../../../utils';
import { EntityTags } from '../../../../workshops/components/WorkshopTable/WorkshopModal';
import { UserFlag } from '../../../Avatars/UserFlag';
import EntityLink, { usePageForEntity } from '../../../EntityLink';
import SquareImage from '../../../SquareImage';
import { speakerThumbnailOptions } from './ClassicSpeakerCard';
import './SquareSpeakerCard.scss';

const css = bem('SquareSpeakerCard');
const translationPrefix = 'speakers.card';

const SquareSpeakerCard = (props) => {
  const {
    defaultThumbnail,
    fluid,
    imageSize,
    openMode,
    speaker,
    tagFields,
    width,
    cdnOptions,
    countryCodeField,
  } = props;
  const { company, firstName, lastName, role, logo, thumbnail = defaultThumbnail } = speaker;
  const imageProps = useComponentOptions('thumbnails');

  const page = usePageForEntity(speaker);

  return (
    <>
      <Card
        as={page ? EntityLink : undefined}
        className={css()}
        entity={page ? speaker : undefined}
        fluid={fluid}
        style={{ width, cursor: page ? 'pointer' : undefined }}
      >
        <div className="thumbnail">
          <SquareImage
            as="img"
            alt={`${firstName} ${lastName}`}
            src={thumbnail}
            imageSize={imageSize}
            {...imageProps}
            cdnOptions={{ ...imageProps?.cdnOptions, ...speakerThumbnailOptions, ...cdnOptions }}
            className={css('avatar').toString()}
          />
          <UserFlag user={speaker} field={countryCodeField} />
        </div>
        <Card.Content className={css('content').toString()}>
          {logo && (
            <SquareImage
              as="img"
              src={logo}
              alt={`${firstName} ${lastName}`}
              className={css('logo').toString()}
              imageSize={50}
              func="fit"
            />
          )}
          <div className="info">
            <Card.Header className={css('firstNameLastName').toString()}>
              {firstName} {lastName}
            </Card.Header>
            {(company || role) && (
              <Card.Description className={css('companyRole').toString()}>
                {[company, role].filter((v) => v).join(' - ')}
              </Card.Description>
            )}
            <EntityTags tagFields={tagFields} entity={speaker} type="speaker" />
          </div>
          {openMode === 'link' && (
            <Card.Content className="navlink">
              <EntityLink autoHide entity={speaker}>
                {getString(`${translationPrefix}.view-sessions`)}
              </EntityLink>
            </Card.Content>
          )}
        </Card.Content>
      </Card>
    </>
  );
};

SquareSpeakerCard.defaultProps = {
  countryCodeField: 'country',
  cdnOptions: undefined,
  defaultThumbnail: undefined,
  fluid: undefined,
  imageSize: 300,
  openMode: 'none',
  tagFields: [],
  width: undefined,
};
SquareSpeakerCard.propTypes = {
  countryCodeField: PropTypes.string,
  cdnOptions: PropTypes.object,
  defaultThumbnail: PropTypes.string,
  fluid: PropTypes.bool,
  imageSize: PropTypes.number,
  openMode: PropTypes.string,
  speaker: speakerPropTypes.isRequired,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number,
};

export default SquareSpeakerCard;
