import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import CdnImage from '../../../components/CdnImage';
import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { Icons } from '../../../components/Icons/material-icon.types';
import { isVideoMedia, videoCDN } from '../../../utils/videoUtils';
import { Publication } from '../../types/SocialWallV2.types';
import { EmoteList } from '../EmoteList';
import { PublicationTextAndComment } from './DetailedPublication/PublicationTextAndComment';
import { DetailedPublicationHeader } from './DetailedPublicationHeader';

interface DesktopDetailsPublicationProps {
  publication: Publication;
  comments: Publication[];
  userId: string;
  onClose: () => any;
  onDelete: () => void;
  onEdit: () => void;
  onAddComment: (message: string) => Promise<Publication>;
  onDeleteComment: (commentId: string) => Promise<void>;
  onInteractionClick: (action: string) => Promise<void>;
  userInteractions: string[];
  onNext: () => void;
  onPrev: () => void;
}

export const DesktopDetailsPublication: FC<DesktopDetailsPublicationProps> = ({
  publication,
  comments,
  userId,
  onClose,
  userInteractions,
  onDelete,
  onEdit,
  onAddComment,
  onDeleteComment,
  onInteractionClick,
  onNext,
  onPrev,
}) => {
  const { author, data, createdAt, interactions } = publication;
  const { media, message, thumbnail } = data;
  const { filename, mimeType, uri } = media || {};

  const { t } = useTranslation();
  const actions = [
    {
      label: t('btn.delete'),
      onClick: onDelete,
      icon: 'delete' as Icons,
    },
    {
      label: t('btn.edit'),
      onClick: onEdit,
      icon: 'edit_square' as Icons,
    },
  ];

  const renderMedia = () => {
    if (!media || !mimeType || !uri) return null;
    const isVideo = isVideoMedia({ mimeType, filename: filename || '' });
    const videoUrl = videoCDN(uri);

    return isVideo ? (
      <div className="video-wrapper">
        <video
          playsInline
          disableRemotePlayback
          disablePictureInPicture
          x-webkit-airplay="deny"
          autoPlay
          controls
          width={250}
          poster={thumbnail?.uri}
        >
          <source src={videoUrl} type={mimeType || 'video/mp4'} />
          {t('common.no-video')}
        </video>
      </div>
    ) : (
      <CdnImage className="image-media" src={uri} maxWidth={600} maxHeight={400} loading="lazy" />
    );
  };

  return (
    <div className="publication-container">
      <div className="publication-container__content">
        {media ? (
          renderMedia()
        ) : (
          <div className="publication-container__text">
            <span>{message}</span>
          </div>
        )}
        <div
          className={cx(
            'publication-container__header',
            media && 'publication-container__header--gradient',
          )}
        >
          <button
            className={cx('close-button', !media && 'close-button--dark')}
            type="button"
            onClick={onClose}
            aria-label="close-button"
          >
            <MaterialIcon name="close" />
          </button>
        </div>
        <Button className="navigation-button prev-button" onClick={onPrev} icon="angle left" />
        <Button className="navigation-button next-button" onClick={onNext} icon="angle right" />

        <EmoteList
          interactions={interactions}
          userInteractions={userInteractions}
          onEmoteClick={onInteractionClick}
        />
      </div>
      <div className="publication-container__side">
        <DetailedPublicationHeader
          user={author}
          date={createdAt}
          onClose={onClose}
          dark
          closeButton={false}
          showOptions={userId === publication.author.userId}
          actions={actions}
        />
        <PublicationTextAndComment
          publication={{ ...publication, data: media ? publication.data : {} }}
          comments={comments}
          userInteractions={userInteractions}
          style={{
            paddingTop: '60px',
          }}
          withEmote={false}
          onAddComment={onAddComment}
          onDeleteComment={onDeleteComment}
        />
      </div>
    </div>
  );
};
