/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable operator-linebreak */
import get from 'lodash/get';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Header, Icon, TextArea } from 'semantic-ui-react';

import { bem } from '../../../../core/design/bem';
import accommodationService from '../../../../core/services/accommodation.service';
import { useMe } from '../../../../profile/hooks';
import { RoomingConfig } from '../RoomingBlock';
import { AccommodationRoomUserStatus, RoomRequest, RoomingSession } from '../types';
import {
  LevelType,
  computeInitialRoom,
  computeLevelTypes,
  findAccommodationAndLevel,
} from '../utils';
import './AddRoomForm.scss';

const css = bem('RoomForm');
const translationPrefix = 'blocks.rooming';

type AddRoomFormTypes = {
  roomingSession: RoomingSession;
  collection: string;
  config?: RoomingConfig;
  refresh: any;
};

function userToOption(user: any) {
  return {
    key: user._id,
    text: `${user.firstName} ${user.lastName}`,
    value: user._id,
  };
}

const AddRoomForm = (props: AddRoomFormTypes): JSX.Element => {
  const { roomingSession, collection, refresh, config = {} } = props;
  const { displayComment = false } = config;
  const { t } = useTranslation();
  const me = useMe();
  const [room, setRoom] = useState<RoomRequest>(() => computeInitialRoom(accommodations ?? []));

  const owner = {
    _id: me._id,
    collection: me.collection,
    status: AccommodationRoomUserStatus.ACCEPTED,
  };

  const { accommodations, candidateRoommates } = roomingSession;
  const accommodationLevels = computeLevelTypes(accommodations);

  const { accommodationLevel: selectedLevel } = findAccommodationAndLevel(
    accommodations ?? [],
    room,
  );

  const { otherUsers, usersById } = useMemo(
    () => ({
      otherUsers: orderBy(
        candidateRoommates.filter((u) => u._id !== me._id && !u.hasValidRoom),
        [(u) => u?.firstName.toLowerCase(), (u) => u?.lastName.toLowerCase()],
        ['asc', 'asc'],
      ),
      usersById: keyBy(candidateRoommates, '_id'),
    }),
    [me, candidateRoommates],
  );

  const { maxUsers = 0 } = selectedLevel || {};

  const accommodationTypeIds =
    accommodationLevels
      .find((l) => l.type === room.accommodationLevelType)
      ?.levels.map((l) => l.accommodationId) || [];
  const accommodationList =
    accommodations
      ?.filter((acc) => accommodationTypeIds.includes(acc._id))
      ?.map((acc) => {
        const { title, _id: accommodationId, levels } = acc;
        const level = levels?.find((l) => l.type === room.accommodationLevelType);
        const { remainingRooms = 0 } = level || {};
        return {
          key: accommodationId,
          text: `${t(`${translationPrefix}.remaining-rooms`, {
            title,
            count: remainingRooms,
          })}`,
          value: accommodationId,
          disabled: remainingRooms === 0,
        };
      }) || [];

  const handleTypeChange = (type: LevelType) => {
    const accommodationLevelType = type.type;
    let accommodationId;
    let accommodationLevelId;
    // Auto-set accommodationLevelId
    if (type.levels.length === 1) {
      accommodationLevelId = type.levels[0]._id;
    }
    // Auto-set accommodationId
    const accommodationIds = uniq(type.levels.map((l) => l.accommodationId));
    if (accommodationIds.length === 1) {
      // if only one accommodation has the selected level then auto set the accommodationId
      // otherwise select the accommodation before setting accommodationLevelId
      accommodationId = accommodationIds[0];
    }

    if (room.users) {
      room.users = undefined;
    }

    setRoom({ ...room, accommodationLevelType, accommodationId, accommodationLevelId });
  };

  const handleAccommodationChange = (accommodationId: string) => {
    const accommodation = accommodations?.find((acc) => acc._id === accommodationId);
    const accommodationLevel = accommodation?.levels?.find(
      (l) => l.type === room.accommodationLevelType,
    );
    const { _id: accommodationLevelId } = accommodationLevel || {};
    setRoom({ ...room, accommodationId, accommodationLevelId, users: [] });
  };

  const handleSelectUsers = (value: string | string[]) => {
    const finalUsers = maxUsers > 2 ? value.slice(0, maxUsers - 1) : value;
    setRoom({ ...room, users: finalUsers });
  };

  const handleCommentChange = (e: any) => {
    const comment = e.target.value;
    setRoom({ ...room, comment });
  };

  const handleValidate = async () => {
    const selectedUsers = room.users ?? [];
    const formattedUsers = isString(selectedUsers)
      ? [selectedUsers]
      : selectedUsers.slice(0, maxUsers - 1);
    const body = {
      accommodationId: room.accommodationId,
      levelId: room.accommodationLevelId,
      owner,
      roomingSession: roomingSession.roomingSession,
      users: [
        owner,
        ...formattedUsers.map((userId) => ({
          _id: userId,
          collection: get(usersById, [userId, 'collection']),
          status: AccommodationRoomUserStatus.PENDING,
        })),
      ],
      comment: room.comment,
    };
    const res = await accommodationService.addRoom(collection, room.accommodationId!, body);
    if (res.success) {
      refresh();
      setRoom({});
    }
  };

  return (
    <div className={css()}>
      <Form>
        <Header>
          <Icon name="bed" />
          {t(`${translationPrefix}.header`)}
        </Header>
        <div className={css('category')}>
          <div className="header">{t(`${translationPrefix}.select-your-room-category`)}</div>
          {accommodationLevels.map((level) => {
            const { type, name: levelName, remainingRooms } = level;
            return (
              <Form.Radio
                className="radio"
                disabled={remainingRooms <= 0}
                key={type}
                label={levelName}
                name={type}
                onChange={() => handleTypeChange(level)}
                checked={type === room.accommodationLevelType}
              />
            );
          })}
        </div>
        {!!room.accommodationLevelType && accommodations?.length > 1 && (
          <div className={css('hotel')}>
            <Form.Field
              control={Dropdown}
              selection
              deburr
              clearable
              search
              label={t(`${translationPrefix}.select-your-hotel`)}
              options={accommodationList}
              onChange={(_e: any, { value }: { value: string }) => handleAccommodationChange(value)}
            />
          </div>
        )}
        {!!selectedLevel && maxUsers > 1 && (
          <div className={css('roommates')}>
            <Form.Field
              control={Dropdown}
              deburr
              className="dropdown"
              selection
              search
              fluid
              label={t(`${translationPrefix}.select-your-roommates`)}
              options={otherUsers.map(userToOption)}
              multiple={maxUsers > 2}
              value={room.users || []}
              onChange={(_e: any, { value }: { value: string | string[] }) =>
                handleSelectUsers(value)
              }
            />
          </div>
        )}
        {!!selectedLevel && displayComment && (
          <Form.Field
            className={css('comment').toString()}
            control={TextArea}
            name="comment"
            label={t(`${translationPrefix}.comment`)}
            placeholder={t(`${translationPrefix}.comment-placeholder`)}
            value={room.comment}
            onChange={handleCommentChange}
          />
        )}
      </Form>
      <Button
        style={{ marginTop: 10 }}
        primary
        className={css('validation')}
        disabled={
          (maxUsers <= 1 && !selectedLevel) ||
          (maxUsers > 1 && (room.users?.length || 0) < maxUsers - 1)
        }
        onClick={handleValidate}
      >
        <Icon name={maxUsers > 1 ? 'send' : 'check'} />
        {t(`${translationPrefix}.${maxUsers > 1 ? 'send-invitation' : 'validate'}`)}
      </Button>
    </div>
  );
};

AddRoomForm.defaultProps = {};

export default AddRoomForm;
