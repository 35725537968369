import { orderBy } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';

import socialWallService from '../../core/services/social-wall.service';
import { Publication } from '../types/SocialWallV2.types';

type UseSocialWallPublicationsComments = {
  comments: Publication[];
  addPublicationComment: (message: string) => Promise<Publication>;
  deletePublicationComment: (commentId: string) => Promise<void>;
};

const REFETCH_INTERVAL = 20000;

export const useSocialWallPublicationsComments = (
  publicationId: string,
): UseSocialWallPublicationsComments => {
  const queryClient = useQueryClient();

  const { data = [] } = useQuery(
    ['social-wall', 'comments', { publicationId }],
    async () => {
      const comments = await socialWallService.getComments(publicationId);
      return orderBy(comments, ['publishedAt', 'createdAt'], ['asc', 'asc']);
    },
    {
      refetchInterval: REFETCH_INTERVAL,
    },
  );

  const addPublicationComment = async (message: string) => {
    const comment = await socialWallService.postComment(publicationId, {
      message,
    });

    // Invalidate query to launch getComments
    queryClient.invalidateQueries({ queryKey: ['social-wall', 'comments', { publicationId }] });

    return comment;
  };

  const deletePublicationComment = async (commentId: string) => {
    await socialWallService.deleteMessage(commentId);

    queryClient.invalidateQueries({ queryKey: ['social-wall', 'comments', { publicationId }] });
  };
  return { comments: data, addPublicationComment, deletePublicationComment };
};
