import React, { FC, useContext, useMemo } from 'react';

import { UserType } from '../../core/users/users.types';
import { useMe } from '../../profile/hooks';

export type ChatChannel = {
  hash: string;
  id: string;
  invited: string;
  invitedProfile: UserType;
  inviter: string;
  inviterProfile: UserType;
  createAt: string;
  messsage: string;
  messages: Record<string, any>;
  status: 'accepted' | 'refused';
  unreadCount: Record<string, number>;
};

type ChatContextType = {
  channel: ChatChannel;
  users: UserType[];
  otherUsers: UserType[];
};

export const ChatContext = React.createContext<ChatContextType>({
  channel: {} as ChatChannel,
  users: [] as UserType[],
  otherUsers: [] as UserType[],
});

export const ChatContextProvider: FC<{ children: any; channel: ChatChannel }> = ({
  children,
  channel,
}) => {
  const me = useMe();
  const value = useMemo(
    () => ({
      channel,
      users: [channel.invitedProfile, channel.inviterProfile],
      otherUsers: [me._id === channel.invited ? channel.inviterProfile : channel.invitedProfile],
    }),
    [channel],
  );
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export function useChatContext(): ChatContextType {
  return useContext(ChatContext);
}
