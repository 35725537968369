import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoItemsPlaceholder } from '../../components/NoItemsPlaceholder/NoItemsPlaceholder';
import { Publication } from '../types/SocialWallV2.types';
import { PublicationCard } from './PublicationCard';

type SocialWallFeedPageProps = {
  publications: Publication[];
  addPublication: () => void;
  userInteractions: Record<string, string[]>;
  openPublication: (publicationId: string) => void;
  onInteractionClick: (publicationId: string, emote: string) => Promise<void>;
};

export const SocialWallFeedPage: FC<SocialWallFeedPageProps> = ({
  publications,
  addPublication,
  userInteractions,
  openPublication,
  onInteractionClick,
}) => {
  const { t } = useTranslation();

  if (!publications?.length) {
    return (
      <NoItemsPlaceholder
        buttonOptions={{ onClick: addPublication, label: t('social-wall.add-new-post') }}
        title={t('social-wall.no-post.title')}
        description={t('social-wall.no-post.description')}
        icon="collections"
      />
    );
  }

  return (
    <div className="publication-list">
      {publications.map((publication) => {
        const { _id, data, createdAt, commentCount, ...restData } = publication;
        return (
          <PublicationCard
            key={_id}
            message={data.message}
            thumbnail={data.thumbnail}
            media={data.media}
            date={createdAt}
            commentCount={commentCount}
            userInteractions={userInteractions[_id]}
            onClick={() => openPublication(_id)}
            onInteractionClick={(emote: string) => onInteractionClick(_id, emote)}
            {...restData}
          />
        );
      })}
    </div>
  );
};
