import { Popup } from 'semantic-ui-react';

import { userArrayProptypes } from '../../../propTypes';
import { avatarWithFallbackWhenMissing } from '../../../utils/avatarUtils';
import { replaceValues } from '../../../utils/stringUtils';
import EntityLink from '../../EntityLink';
import SquareImage from '../../SquareImage';
import './SimpleVariant.scss';

export function getAvatarTitle(user, template) {
  if (template) return replaceValues(template, user);
  return user.name || `${user.firstName || ''} ${user.lastName || ''}`;
}

const SimpleVariant = (props) => {
  const {
    users,
    cdnOptions,
    size,
    template,
    imageSize,
    defaultThumbnail,
    imageKey,
    className,
    subtitleTemplate,
  } = props;

  return (
    <div>
      {users.map((user) => (
        <Popup
          key={user._id}
          header={getAvatarTitle(user, template)}
          content={replaceValues(subtitleTemplate || '{jobTitle}{role}\n{company}', user)}
          className="popup--avatar"
          trigger={
            <EntityLink entity={user} disableLegacyMode>
              <SquareImage
                size={size}
                src={avatarWithFallbackWhenMissing(user[imageKey], size, defaultThumbnail)}
                imageSize={imageSize}
                circular
                style={{ cursor: 'pointer' }}
                cdnOptions={cdnOptions}
                className={className}
              />
            </EntityLink>
          }
        />
      ))}
    </div>
  );
};

SimpleVariant.propTypes = { users: userArrayProptypes };
SimpleVariant.defaultProps = { users: [] };

export default SimpleVariant;
