import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import BlockContainer from '../../BlockContainer';
import { BlockProps, ContainerProps } from '../types';
import './FlightBlock.scss';

const css = bem('FlightBlock');

type FlightStop = {
  departureTime: string;
  departureAirport: string;
  arrivalTime: string;
  arrivalAirport: string;
  duration?: string;
};

type FlightBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  flightNumber: string;
  stops: FlightStop[];
  subtitle?: string;
  title: string;
};

const FlightBlock: FC<FlightBlockProps> = (props) => {
  const { t } = useTranslation();
  const {
    _id,
    className,
    container,
    stops = [],
    color = 'var(--ac-color-primary)',
    title,
    subtitle,
    flightNumber,
  } = props;

  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      <div className={css('Content')}>
        <div className={css('Header')} style={{ backgroundColor: color }}>
          <Header as="h3" className="title">
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </Header>
          {!!subtitle && (
            <Header as="h3" className="subtitle">
              {subtitle}
            </Header>
          )}
          <div className="flight-number">
            <span className="label">{t('blocks.flight.flight')}</span>
            <div className="divider" />
            <span className="number">{flightNumber}</span>
          </div>
        </div>
        <div className={css('Stops')}>
          {stops.map((stop, index) => {
            const { departureTime, departureAirport, arrivalAirport, arrivalTime, duration } = stop;
            return (
              <div className={css('Stop')} key={`stop ${index + 1}`}>
                <div className="departure-and-duration">
                  <div className="stopovers">
                    <div className="circle" />
                    <div className="divider" />
                  </div>
                  <div className="infos">
                    <div className="departure">
                      <span className="time" style={{ color }}>
                        {departureTime}
                      </span>
                      <span className="airport">{departureAirport}</span>
                    </div>
                    <div className="duration">
                      {!!duration && t('blocks.flight.duration', { duration })}
                    </div>
                  </div>
                </div>
                <div className="arrival">
                  <div className="circle" />
                  <span className="time" style={{ color }}>
                    {arrivalTime}
                  </span>
                  <span className="airport">{arrivalAirport}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BlockContainer>
  );
};

export default FlightBlock;
