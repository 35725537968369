import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { useUserRankingInOwnTeam } from '../../hooks/leaderboard.hooks';
import { CompleteRankingList } from '../components/CompleteRankingList';
import './UserRankingInOwnTeam.scss';

const prefix = 'achievements.leaderboard';

const css = bem('UserRankingInOwnTeam');

type Props = {
  field?: string;
  showTopThree: boolean;
  subtitleTemplate: string;
} & typeof defaultProps;

const defaultProps = {
  field: 'company',
};

export const UserRankingInOwnTeam = (props: Props): JSX.Element | null => {
  const { field, showTopThree, subtitleTemplate } = props;
  const { t } = useTranslation();
  const teamRanking = useUserRankingInOwnTeam(field);
  if (!teamRanking) return null;
  const { users = [], userCount, teamId } = teamRanking;
  return (
    <div className={css()}>
      <Header as="h3" className="team">
        {teamId}
      </Header>
      <div className="userCount">
        {t(`${prefix}.ranking-by-team.user-count`, { count: userCount })}
      </div>
      <CompleteRankingList
        users={users}
        showTopThree={showTopThree}
        subtitleTemplate={subtitleTemplate}
      />
    </div>
  );
};

UserRankingInOwnTeam.defaultProps = defaultProps;
