/* eslint-disable operator-linebreak */
import flatten from 'lodash/flatten';
import mapKeys from 'lodash/mapKeys';

import { generateColorsVariant } from './colorsUtils';
import { fontFormatFromName } from './fontUtils';
import { replaceValues } from './stringUtils';

interface VariablesOptions {
  opacities?: number[];
  brightnesses?: Record<string, number>;
}
interface CustomFont {
  name: string;
  files?: Record<string, { uri: string }>;
}

export interface Fonts {
  bodyFont?: { kind?: string; category?: string; family?: string };
  customFont?: {
    body: CustomFont & {
      fallbacks?: 'custom' | 'systemfont';
    };
  };
  designFont?: { family: string };
}

export interface CSSVariables {
  [x: string]: Record<string, string | number | CSSVariables | undefined>;
}

const computeVariable = (
  variable: CSSVariables,
  prefix?: string,
): Record<string, string | number> | undefined => {
  let variables = {} as Record<string, string | number>;
  if (!variable) return undefined;

  Object.entries(variable).forEach(([key, value]) => {
    const finalKey = `${prefix ? `${prefix}-` : ''}${key}`;
    if (typeof value === 'string' || typeof value === 'number') {
      variables[finalKey] = value;
      return;
    }
    const nestedVariables = computeVariable(value as CSSVariables, finalKey);
    variables = { ...variables, ...nestedVariables };
  });

  return prefix ? variables : mapKeys(variables, (value, key) => `--ac-${key}`);
};

export const generateVariables = (
  designVariables: CSSVariables,
  options: VariablesOptions,
): string => {
  const { color, ...restVariables } = designVariables;

  // Generate color variants depending on brightness & opacity
  const generatedColors = color
    ? generateColorsVariant(color as Record<string, string>, {
        brightnesses: options.brightnesses,
        opacities: options.opacities,
      })
    : {};

  // Compute json to define css variable
  const computedStyles = computeVariable({ ...restVariables, color: generatedColors });

  if (!computedStyles) return '';
  // Inject css variables in root & iFrame's root
  const formattedVariables = Object.entries(computedStyles)
    .map(([key, value]) => {
      return `${key}: ${value.toString()};`;
    })
    .join('\n');

  return `:root{${formattedVariables}}`;
};

export const generateClass = (
  classToCreate: Record<string, string>,
  designVariables: CSSVariables,
): string => {
  const { size } = designVariables;

  const generatedClass = Object.entries(classToCreate).map(([key, value]) => {
    return Object.entries(size).map(([sizeKey, sizeValue]) => {
      return `.ac-${key}-${sizeKey}{
            ${replaceValues(value, { size: sizeValue })}
          }`;
    });
  });

  return flatten(generatedClass).join('\n');
};

const generateFontFaces = (font: CustomFont): string => {
  if (!font || !font?.files) return '';
  const { name = 'default-class', files } = font;

  const generatedFontFaces = Object.keys(files).map((type) => {
    const file = files[type];
    if (!name || !file || !file.uri) return '';
    const format = fontFormatFromName(file.uri);
    if (!format) return '';

    return `
          /* ${type} */
         @font-face {
            font-family: ${name};
            src: url("${file.uri}") format("${format}");
            ${type === 'italic' || type === 'boldItalic' ? '  font-style: italic;' : ''}
            ${type === 'bold' || type === 'boldItalic' ? '  font-weight: bold;' : ''}
          }`;
  });

  return generatedFontFaces.join('\n');
};

export const generateFonts = ({
  classNames,
  // bodyFont,
  customFont,
  designFont,
}: Fonts & { classNames: string }): string => {
  const font = customFont?.body;
  if (!font?.name) {
    return `
        ${classNames} {
          font-family: ${designFont?.family || 'sans-serif'};
        }`;
  }

  return `${generateFontFaces(font)} 
      
      ${classNames} {
        font-family: ${font.name}, ${font.fallbacks || 'sans-serif'} !important;
      }
      `;
};
