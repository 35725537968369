import PropTypes from 'prop-types';
import React from 'react';
import { Divider } from 'semantic-ui-react';

import useTranslations from '../../hooks/useTranslations';
import { SSOButton } from './SSOButton';
import './SsoLogin.scss';

const translationPrefix = 'login.sso';

const SsoLogin = ({ sso }) => {
  const { t } = useTranslations(translationPrefix);

  if (sso.length === 0) return null;

  const validSSO = sso.filter((s) => s.url);
  return (
    <div className="sso-authentication">
      <div className="sso-header">{t('header')}</div>
      {validSSO.map((ssoInput, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SSOButton key={index} {...ssoInput} />
      ))}
      <Divider horizontal>{t('or')}</Divider>
    </div>
  );
};

SsoLogin.propTypes = {
  sso: PropTypes.array.isRequired,
};

export default SsoLogin;
