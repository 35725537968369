/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Header, Item } from 'semantic-ui-react';

import { ellipsisIfNeeded } from '../../../home/blocks/SocialWallWidgetBlock';
import { Workshop } from '../../../types/workshop.types';
import Images from '../../../utils/Images';
// eslint-disable-next-line import/no-cycle
import { EntityTags } from '../../components/WorkshopTable/WorkshopModal';

const translationPrefix = 'workshops.workshop';

type Props = {
  workshops: Workshop[];
  onClick: any;
  // eslint-disable-next-line react/no-unused-prop-types
  config?: Record<string, any>;
};

const CardVariant = (props: Props) => {
  const { config = {}, workshops, onClick } = props;
  const { itemsPerRow = 2, tagFields = [] } = config || {};
  return (
    <Card.Group itemsPerRow={itemsPerRow}>
      {workshops.map((w) => {
        const { title: header, _id, shortDescription } = w;
        return (
          <Card key={_id} style={{ cursor: 'pointer' }} onClick={() => onClick(w)}>
            <Card.Content>
              <EntityTags tagFields={tagFields} entity={w} type="workshop" />
              <Card.Header>{header}</Card.Header>
              <Card.Description>{ellipsisIfNeeded(shortDescription, 70)}</Card.Description>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};

const ItemVariant = (props: Props) => {
  const { workshops, onClick } = props;
  return (
    <Item.Group unstackable>
      {workshops.map((w) => {
        const { title: header, _id, image, shortDescription } = w;
        return (
          <Item key={_id} style={{ cursor: 'pointer' }} onClick={() => onClick(w)}>
            {image && image.uri && (
              <Item.Image size="small" src={Images.cdn(image)} style={{ maxWidth: 100 }} />
            )}
            <Item.Content style={{ paddingLeft: 10 }}>
              <Item.Header style={{ fontSize: '1em' }}>{header}</Item.Header>
              <Item.Meta style={{ fontSize: 12 }}>
                {ellipsisIfNeeded(shortDescription, 70)}
              </Item.Meta>
            </Item.Content>
          </Item>
        );
      })}
    </Item.Group>
  );
};

CardVariant.defaultProps = {
  config: {},
};

ItemVariant.defaultProps = {
  config: {},
};

const components = {
  item: ItemVariant,
  card: CardVariant,
};

export const OtherCurrentLives = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { config = {}, workshops, onClick } = props;
  const { width, variant = 'item' } = config || {};
  if (!workshops?.length) return null;
  const Component = get(components, variant, ItemVariant);
  return (
    <Grid.Column className="otherCurrentLives" width={width}>
      <Header style={{ flexGrow: 0 }}>{t(`${translationPrefix}.other-current-lives`)}</Header>
      <Component workshops={workshops} onClick={onClick} config={config} />
    </Grid.Column>
  );
};

OtherCurrentLives.defaultProps = {
  config: {},
};
