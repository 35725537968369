import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { SemanticICONS, Tab } from 'semantic-ui-react';

import { CmsPageLayout } from '../cms/CmsPage';
import { useConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { useMe } from '../profile/hooks';
import i18n from '../translations/i18n';
import { GLOBAL_MEDIA_QUERIES } from '../utils/mediaQueries';
import './SocialWallv2.scss';
import { DetailedPublicationModal } from './components/DetailedPublicationModal/DetailedPublicationModal';
import { SocialWallFeedPage } from './components/SocialWallFeed';
import { SocialWallMediaPage } from './components/SocialWallMediaPage';
import { SocialWallFeedPageContent } from './components/SocialWallPageContent';
import { SocialWallPublicationModal } from './components/SocialWallPublicationModal';
import { useSocialWallEmotes } from './hooks/useSocialWallEmotes';
import { useSocialWallPublications } from './hooks/useSocialWallPublications';
import { SocialWallConfigProvider } from './social-wall-config.context';

type PanesTypes = { type: 'newsfeed' | 'media'; enabled: boolean };

interface SocialWallProps {
  tabs: PanesTypes[];
  comments: { enabled: boolean };
  publications: { enabled: boolean };
  interactions: { enabled: boolean; defaultEmotes?: string[] };
  design?: Record<string, any>;
  metadata?: Record<string, any>;
  pageFooter?: Record<string, any>;
  pageHeader?: Record<string, any>;
}

const defaultTabs: Record<string, PanesTypes> = {
  newsfeed: { enabled: true, type: 'newsfeed' },
  media: { enabled: true, type: 'media' },
};

const useHasBottomTabs = () => {
  const { menus } = useConfig();
  const mobile = useMedia(GLOBAL_MEDIA_QUERIES.mobile);
  return (
    menus?.bottomTabs?.enabled &&
    ((!mobile && menus?.bottomTabs?.media?.desktop) || (mobile && menus?.bottomTabs?.media?.mobile))
  );
};

export const SocialWallV2: FC<SocialWallProps> = (props: SocialWallProps) => {
  const {
    tabs = defaultTabs,
    comments = { enabled: true },
    publications = { enabled: true },
    interactions = { enabled: true },
    metadata,
    design,
    pageHeader,
    pageFooter,
  } = props;

  const { t } = useTranslation();
  const { primaryColor } = useDesignConfig();
  const { _id: userId, collection: userCollection } = useMe();

  const { publications: posts, createPublication } = useSocialWallPublications(
    publications.enabled,
  );

  const hasBottomTabs = useHasBottomTabs();

  // TO DO: use store to get/set publications/comments/interactions
  const { toggleInteraction, userInteractions } = useSocialWallEmotes({ userId, userCollection });

  const onAddPublicationClick = async () => {
    await SocialWallPublicationModal.open({ createPublication });
  };

  const onOpenPublicationDetails = async (publicationId: string) => {
    await DetailedPublicationModal.open({
      publicationId,
      userId,
      userCollection,
      config: {
        comments,
        publications,
        interactions,
      },
    });
  };

  const buttonOptions = {
    label: t('social-wall.add-message'),
    icon: 'plus' as SemanticICONS,
    color: primaryColor,
    onClick: posts.length ? onAddPublicationClick : undefined,
    higher: hasBottomTabs,
  };

  const panesConfig = {
    newsfeed: {
      menuItem: i18n.t('social-wall.tabs.newsfeed'),
      render: () => (
        <SocialWallFeedPageContent
          key="feed-page"
          buttonOptions={buttonOptions}
          className={cx('social-wall__content', !posts.length && 'social-wall__content--empty')}
        >
          <SocialWallFeedPage
            publications={posts}
            addPublication={onAddPublicationClick}
            userInteractions={userInteractions}
            openPublication={onOpenPublicationDetails}
            onInteractionClick={toggleInteraction}
          />
        </SocialWallFeedPageContent>
      ),
    },
    media: {
      menuItem: i18n.t('social-wall.tabs.media'),
      render: () => (
        <SocialWallFeedPageContent
          key="media-page"
          buttonOptions={buttonOptions}
          className={cx('social-wall__content', !posts.length && 'social-wall__content--empty')}
        >
          <SocialWallMediaPage
            publications={posts}
            userInteractions={userInteractions}
            openPublication={onOpenPublicationDetails}
          />
        </SocialWallFeedPageContent>
      ),
    },
  };
  const panes: any[] = Object.entries(tabs)
    .map(([tabType, tab]) => {
      if (!tab.enabled) return null;
      return panesConfig[tab.type || tabType];
    })
    .filter((v) => v);

  return (
    <CmsPageLayout
      withFooter={false}
      fullheight
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <SocialWallConfigProvider
        comments={comments}
        publications={publications}
        interactions={interactions}
      >
        {panes.length > 1 ? (
          <div className="social-wall-wrapper">
            <Tab
              defaultActiveIndex={0}
              renderActiveOnly
              className="social-wall"
              menu={{ attached: false, tabular: false, pointing: true, secondary: true }}
              panes={panes}
            />
          </div>
        ) : (
          <>{panes[0]?.render() || panesConfig.newsfeed.render()}</>
        )}
      </SocialWallConfigProvider>
    </CmsPageLayout>
  );
};
