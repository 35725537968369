import cx from 'classnames';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { Card, Header } from 'semantic-ui-react';

import { workshopProptypes } from '../../../../propTypes';
import Images from '../../../../utils/Images';
import { getClassName, getWorkshopString } from '../../../../workshops/utils';
import EntityLink from '../../../EntityLink';
import SpeakerAvatars from '../../../speakers/SpeakerAvatars';
import WorkshopDate from '../../WorkshopDate';
import { WorkshopActions } from '../actions/WorkshopActions';
import { computeActions } from '../actions/actions.utils';
import '../light-item/WorkshopLightItem.scss';
import './WorkshopImageItem.scss';

type WorkshopImageItemProps = {
  workshop: any;
  config?: any;
};

const WorkshopImageItem: FC<WorkshopImageItemProps> = (props) => {
  const { workshop, config } = props;
  const { showSpeakers = true, showDate = true, showEndTime = false, speakerConfig = {} } =
    config || {};

  const { shortDescription, image, speakers, title, startDate, endDate, category } = workshop;

  return (
    <Card className={cx('Workshop', getClassName(workshop))}>
      <Card.Content className="Workshop__Container">
        {image && image.uri && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <EntityLink
            className="Workshop__Container--Image"
            entity={workshop}
            style={{
              backgroundImage: `url(${Images.cdn(image)})`,
            }}
            title={title}
          />
        )}
        <div className="Workshop__Container--Content">
          <div style={{ flex: 1 }}>
            {title && (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <EntityLink entity={workshop}>
                <Header className="title" as="h2">
                  {title}
                </Header>
              </EntityLink>
            )}
            {category && <p className="category">{getWorkshopString(workshop, 'category')}</p>}
            {showDate && (
              <Card.Description className="date">
                <WorkshopDate startDate={startDate} endDate={endDate} showEndTime={showEndTime} />
              </Card.Description>
            )}
            {shortDescription && (
              <Card.Description className="description">{shortDescription}</Card.Description>
            )}
            {showSpeakers && (
              <div className="speakers">
                <SpeakerAvatars size="mini" speakerIds={speakers} {...speakerConfig} />
              </div>
            )}
          </div>
          <WorkshopActions
            // Remove see-more, not required if we have a title
            actions={
              computeActions(config)
              //   .filter(
              //   (action) => action.type !== 'see-more' && !!title,
              // )
            }
            workshop={workshop}
          />
        </div>
      </Card.Content>
    </Card>
  );
};

WorkshopImageItem.defaultProps = {
  config: {},
};

WorkshopImageItem.propTypes = {
  config: PropTypes.object,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopImageItem;
