/* eslint-disable operator-linebreak */
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'semantic-ui-react';

import { matchesWithArray } from '../../core/filter/utils';
import { useSpeakerIds } from '../../core/users/users.hooks';
import { getString } from '../../utils';
import { BlockContainerPropType } from '../BlockContainer/BlockContainer';
import BlockHeader from '../BlockHeader';
import SpeakerAvatars from './SpeakerAvatars';
import SpeakerCard from './SpeakerCard';

const translationPrefix = 'blocks';

const SpeakersBlock = ({ ids, header, container, ...blockConfig }) => {
  const { variant, filters = {}, itemsPerRow = 3, centered = true } = blockConfig || {};
  const speakers = useSpeakerIds(ids);
  const filteredSpeakers = !isEmpty(filters)
    ? speakers?.filter(matchesWithArray(filters))
    : speakers;
  const { t } = useTranslation();
  if (!filteredSpeakers || filteredSpeakers.length === 0) return null;
  if (variant === 'text') {
    return (
      <div className="speakers" style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: 10 }}> {t('workshops.workshop.modal.with')}</span>
        <SpeakerAvatars speakerIds={ids} {...blockConfig} />
      </div>
    );
  }
  return (
    <>
      <BlockHeader
        className="speakers"
        title={container.header || header || getString(`${translationPrefix}.speakers`)}
        headerLevel={container.headerLevel}
      />
      <Card.Group itemsPerRow={itemsPerRow} centered={centered}>
        {filteredSpeakers.map((speaker) => (
          <SpeakerCard key={speaker._id} speaker={speaker} {...blockConfig} />
        ))}
      </Card.Group>
    </>
  );
};

SpeakersBlock.defaultProps = {
  header: undefined,
  container: undefined,
};

SpeakersBlock.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  header: PropTypes.string,
  container: BlockContainerPropType,
};

export default SpeakersBlock;
