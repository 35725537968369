import cx from 'classnames';
import React from 'react';
import { Menu } from 'semantic-ui-react';

import { bem } from '../../core/design/bem';
import './TabbedMenu.scss';

const css = bem('TabbedMenu');

type Tab = {
  name: string;
  title: string;
} & Record<string, any>;

type MenuProps = {
  activeTab: string;
  tabs: Tab[];
  onClick: any;
  className?: string;
};

export const TabbedMenu = (props: MenuProps): JSX.Element => {
  const { activeTab, tabs, onClick, className } = props;
  return (
    <Menu widths={tabs.length as any} className={cx(css().toString(), className)}>
      {tabs.map((tab) => {
        const { name, title, style } = tab;
        return (
          <Menu.Item
            key={name}
            name={name}
            active={activeTab === name}
            style={style}
            onClick={onClick}
          >
            {title}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
