import cx from 'classnames';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

import { CmsPageLayout } from '../cms/CmsPage';
import { useCurrentUser } from '../shared/store-user.hooks';
import { replaceValues } from '../utils/stringUtils';
import './IframePage.scss';
import ResizableIframe from './ResizableIframe';

function IframePage(props) {
  const {
    url,
    hasAutoResize,
    header,
    metadata,
    pageId,
    design,
    pageHeader,
    pageFooter,
    iframe,
  } = props;
  const user = useCurrentUser();

  console.log('iframe', iframe);
  if (!url) return null;
  const iframeUrl = replaceValues(url, { ...user, userId: user._id });
  return (
    <CmsPageLayout
      className={cx('page', 'page--iframe', pageId)}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <ResizableIframe
          src={iframeUrl}
          autoResize={hasAutoResize}
          allowFullScreen
          allow="camera;microphone;fullscreen"
          style={{ height: '100%' }}
          {...iframe}
        />
      </Container>
    </CmsPageLayout>
  );
}

IframePage.defaultProps = {
  design: {},
  header: {},
  hasAutoResize: false,
  pageFooter: undefined,
  pageHeader: undefined,
  pageId: undefined,
  url: undefined,
};

IframePage.propTypes = {
  design: PropTypes.object,
  header: PropTypes.object,
  hasAutoResize: PropTypes.bool,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  pageId: PropTypes.string,
  url: PropTypes.string,
};

export default IframePage;
