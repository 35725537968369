import { useSize } from 'ahooks';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import Styles from '../../../Styles';
import AppHeader from '../../../components/AppHeader';
import ExpiringNavLink from '../../../components/ExpiringNavLink';
import { useConfig } from '../../../config/config.context';
import { useScreenConfig } from '../../../config/screens.context';
import { bem } from '../../../core/design/bem';
import { videoCDN } from '../../../utils/videoUtils';
import './HomeVideo3DX.scss';

const pageCSS = bem('page');

export const CustomPageLayout = ({ className, header, designOverride, children }) => {
  const hasHeader = !!header;

  return (
    <div className={cx(className ? className.toString() : undefined, { headered: hasHeader })}>
      <Styles designOverride={designOverride} />
      {children}
    </div>
  );
};

CustomPageLayout.defaultProps = {
  className: '',
  designOverride: {},
  header: undefined,
};

CustomPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  designOverride: PropTypes.object,
  header: PropTypes.object,
};

export const VideoMenuEntry = ({
  containerSize,
  dimensions,
  dot,
  fontSize,
  label,
  pageId,
  title,
  animation,
}) => {
  const screen = useScreenConfig(pageId);
  const { path } = screen;
  if (!dot || !path) return null;
  const { width: parentWidth, height: parentHeight } = dimensions;
  const { size = 10, position, shadowColor = 'white' } = dot;

  function toX(x) {
    return (x * containerSize.width) / dimensions.width;
  }
  function toY(y) {
    return (y * containerSize.height) / dimensions.height;
  }

  const dotSize = toX(size);
  const shadowSize = 0.5 * dotSize;
  const paddingSize = 40;

  function computeLabelPosition() {
    if (label.anchor) return {};
    return {
      left: toX(label.position.x - paddingSize),
      top: toY(label.position.y - fontSize * 0.3 - paddingSize),
    };
  }

  return (
    <ExpiringNavLink
      className={cx('menu_entry', pageId, !!animation && `menu_entry--animation-${animation}`)}
      to={path}
      style={{
        left: `${(position.x * 100) / parentWidth}%`,
        top: `${(position.y * 100) / parentHeight}%`,
      }}
    >
      <div
        className="menu_entry__dot"
        style={{
          width: dotSize,
          height: dotSize,
          marginLeft: -dotSize / 2,
          marginTop: -dotSize / 2,
          boxShadow: `0 0 ${shadowSize}px ${shadowSize}px ${shadowColor}`,
        }}
      />
      <div
        className={cx(
          'menu_entry__label',
          label.anchor && `menu_entry__label--anchor-${label.anchor}`,
        )}
        style={{
          fontSize: `${toX(fontSize)}px`,
          padding: toX(paddingSize),
          ...computeLabelPosition(),
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </div>
    </ExpiringNavLink>
  );
};

VideoMenuEntry.defaultProps = {
  animation: undefined,
  fontSize: 30,
};

const stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

VideoMenuEntry.propTypes = {
  animation: PropTypes.string,
  containerSize: PropTypes.shape({ width: stringOrNumber, height: stringOrNumber }).isRequired,
  dimensions: PropTypes.shape({ width: stringOrNumber, height: stringOrNumber }).isRequired,
  dot: PropTypes.shape({
    size: PropTypes.number,
    position: PropTypes.shape({ x: stringOrNumber, y: stringOrNumber }),
    shadowColor: PropTypes.string,
  }).isRequired,
  fontSize: PropTypes.number,
  label: PropTypes.shape({
    anchor: PropTypes.string,
    position: PropTypes.shape({ x: stringOrNumber, y: stringOrNumber }),
  }).isRequired,
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export const VideoMenu = ({ dimensions, entries, video }) => {
  const ref = useRef();
  const size = useSize(ref);

  return (
    <div ref={ref} className="page-background__wrapper--16_9">
      <video muted autoPlay loop>
        <source src={videoCDN(video.uri)} type="video/mp4" />
      </video>

      {size.width && size.height && (
        <div className="menu">
          {entries.map((entry) => (
            <VideoMenuEntry
              key={entry._id}
              {...entry}
              containerSize={size}
              dimensions={dimensions}
            />
          ))}
        </div>
      )}
    </div>
  );
};

VideoMenu.defaultProps = {
  entries: [],
  video: {},
};
VideoMenu.propTypes = {
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  entries: PropTypes.array,
  video: PropTypes.object,
};
function HomeVideo3DX(props) {
  const { design, header, pageId, videoWithMenu } = props;
  const { lang, languages } = useConfig();
  return (
    <CustomPageLayout
      className={pageCSS({ type: 'HomeVideo3DX', id: pageId })}
      header={header}
      designOverride={design}
    >
      <AppHeader language={lang} languageOptions={languages} />
      <div className="page-background page-background--video">
        <VideoMenu {...videoWithMenu} />
      </div>
    </CustomPageLayout>
  );
}

HomeVideo3DX.defaultProps = {
  design: {},
  header: {},
  id: '',
};

HomeVideo3DX.propTypes = {
  design: PropTypes.object,
  header: PropTypes.object,
  id: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  videoWithMenu: PropTypes.object.isRequired,
};

export default HomeVideo3DX;
