import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';

import { useComponentOptions } from '../../../../config/design.context';
import { EntityTags } from '../../../../workshops/components/WorkshopTable/WorkshopModal';
import SquareImage from '../../../SquareImage';

const ClassicSpeakerHeader = ({ fluid, template, speaker, ...rest }) => {
  const { company, description, firstName, lastName, role, thumbnail, url } = speaker;
  const { tagFields = [] } = rest;
  const isLightTemplate = template === 'light';
  const imageProps = useComponentOptions('thumbnails');

  return (
    <Card className="card--speaker" fluid={fluid}>
      {thumbnail && (
        <div>
          <SquareImage
            as="img"
            src={thumbnail}
            alt={`${firstName} ${lastName}`}
            className="avatar large"
            imageSize={200}
            {...imageProps}
          />
        </div>
      )}
      <Card.Content className="content">
        <Card.Header>
          {firstName} {lastName}
        </Card.Header>
        {!isLightTemplate && <Card.Description>{company}</Card.Description>}
        <Card.Meta>
          <span className="date">{role} </span>
        </Card.Meta>
        {url && (
          <Card.Meta>
            <a href={url} rel="noreferrer noopener" className="field-link" target="_blank">
              {url}{' '}
            </a>
          </Card.Meta>
        )}
        {description && !isLightTemplate && (
          <Card.Description style={{ textAlign: 'justify', marginTop: 20 }}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Card.Description>
        )}
        <EntityTags tagFields={tagFields} entity={speaker} type="speaker" />
      </Card.Content>
    </Card>
  );
};

ClassicSpeakerHeader.defaultProps = {
  fluid: undefined,
  template: undefined,
};
ClassicSpeakerHeader.propTypes = {
  fluid: PropTypes.bool,
  template: PropTypes.string,
  speaker: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    url: PropTypes.string,
  }).isRequired,
};

export default ClassicSpeakerHeader;
