import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Icon } from 'semantic-ui-react';

import HtmlTemplate from '../../../../components/HtmlTemplate';
import SpeakerAvatars from '../../../../components/speakers/SpeakerAvatars';
import WorkshopLogos from '../../../../components/workshops/WorkshopLogos';
import { bem } from '../../../../core/design/bem';
import { useIsDigital } from '../../../../profile/hooks';
import { formatDateRangeDuration } from '../../../../utils/dateUtils';
import { EntityTags } from '../../../../workshops/components/WorkshopTable/WorkshopModal';
import './CloudTvLiveDescription.scss';

const css = bem('CloudTvLiveDescription');

function CloudTvLiveDescription(props) {
  const { session, isOpen, alwaysOpen, onClose, ...rest } = props;
  const { tagFields = [], showSpeakers = false, speakersConfig = {}, logosProps } = rest;
  const { t } = useTranslation();
  const isDigitally = useIsDigital();
  const { logos, title, description, speakers, location, startDate, endDate } = session;

  if (!isOpen && !alwaysOpen) return null;

  return (
    <div className={css()}>
      {!alwaysOpen && <Icon name="times" style={{ cursor: 'pointer' }} onClick={onClose} />}
      <WorkshopLogos logos={logos} {...logosProps} />
      <div className="dateLocation">
        {startDate && endDate && (
          <>
            <div className="date">{t('program.table.time', { startDate, endDate })}</div>
            <div className="duration">
              <Icon name="hourglass half" />
              {formatDateRangeDuration(startDate, endDate, t)}
            </div>
          </>
        )}
        {location && !isDigitally && (
          <div className="location">
            <Icon name="map marker alternate" />
            {location}
          </div>
        )}
      </div>

      <Header as="h2" className="title">
        {title}
      </Header>
      {description && <HtmlTemplate className="description" template={description} />}
      <EntityTags tagFields={tagFields} entity={session} type="workshop" />
      {showSpeakers && speakers?.length > 0 && (
        <div className="speakers">
          <span className="with-speakers" style={{ minWidth: 50 }}>
            {t('workshops.workshop.modal.with')}
          </span>
          <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />
        </div>
      )}
    </div>
  );
}

CloudTvLiveDescription.defaultProps = {
  alwaysOpen: false,
  isOpen: true,
};

CloudTvLiveDescription.propTypes = {
  alwaysOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

export default CloudTvLiveDescription;
