/* eslint-disable import/no-cycle */
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Card, Icon } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { workshopProptypes } from '../../../propTypes';
import Images from '../../../utils/Images';
import { useActionRegistration } from '../../../workshops/components/WorkshopActions';
import { EntityTags } from '../../../workshops/components/WorkshopTable/WorkshopModal';
import { getClassName } from '../../../workshops/utils';
import EntityLink from '../../EntityLink';
import './WorkshopImageOverlayCard.scss';

const css = bem('overlay-wrapper');

const WorkshopImageOverlayCard = (props) => {
  const { workshop, ...rest } = props;
  const { image, title, workshopStatus, workshopDuration } = workshop;
  const { isHighlighted = true, tagFields, tagsConfig = {}, showFavorite = false } = rest || {};
  const { size, position = 'outside-overlay' } = tagsConfig;
  const hasImage = image && image.uri;

  const {
    registerWorkshop,
    unregisterWorkshop,
    registerWorkshopSession,
    unregisterWorkshopSession,
    registration,
  } = useActionRegistration(workshop);

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (registration) {
      if (registration.workshopId) unregisterWorkshopSession();
      else unregisterWorkshop();
    } else if (workshop.workshopId) registerWorkshopSession();
    else registerWorkshop();
  };
  return (
    <Card
      className={cx(
        'workshop--variant-image-overlay',
        getClassName(workshop),
        `session--${workshopStatus}`,
        { hidden: !isHighlighted, hasImage },
      )}
      as={EntityLink}
      entity={{ ...workshop, kind: 'workshops' }}
      style={{ backgroundImage: hasImage ? `url(${Images.maxWidth(image, 500)})` : null }}
    >
      <div className={css({ tagPosition: position })}>
        <div className="overlay">
          <h2>{title}</h2>
          {position === 'inside-overlay' && (
            <EntityTags size={size} tagFields={tagFields} entity={workshop} type="workshop" />
          )}
        </div>
        {position === 'outside-overlay' && (
          <>
            {showFavorite && (
              <Icon name={`heart ${registration ? '' : 'outline'}`} onClick={handleSave} />
            )}
            <EntityTags size={size} tagFields={tagFields} entity={workshop} type="workshop" />
            {workshopDuration && <span className="duration">{workshopDuration}</span>}
          </>
        )}
      </div>
    </Card>
  );
};

WorkshopImageOverlayCard.defaultProps = {};

WorkshopImageOverlayCard.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopImageOverlayCard;
