/* eslint-disable operator-linebreak */
import { decode } from 'he';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import trim from 'lodash/trim';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import moment from 'moment';
import React from 'react';

import { stringFormatter } from '../translations/formatUtils';

export function html2text(html) {
  if (!html) return html;
  return decode(
    trim(
      html
        .replace(/<style([\s\S]*?)<\/style>/gi, '')
        .replace(/<script([\s\S]*?)<\/script>/gi, '')
        .replace(/<\/div>/gi, '\n')
        .replace(/<\/li>/gi, '\n')
        .replace(/<li>/gi, '  *  ')
        .replace(/<\/ul>/gi, '\n')
        .replace(/<\/p>/gi, '\n')
        .replace(/<br\s*[/]?>/gi, '\n')
        .replace(/<[^>]+>/gi, ''),
      ' \n',
    ),
  );
}

function ensureString(v) {
  if (typeof v === 'number') return v.toString();
  if (Array.isArray(v)) return v.map(ensureString).join(', ');
  return v || '';
}

function splitAtIndex(str, index) {
  return [str.slice(0, index), str.slice(index + 1)];
}

function formatDate(type, formatInput, date = new Date()) {
  const format = formatInput?.trim().match(/date\((.+)\)/)?.[1] || formatInput?.trim();

  switch (type) {
    case 'today':
      return moment(date).format(format || 'YYYY-MM-DD');
    case 'now':
      return moment(date).format(format || 'LLL');
    default:
      return moment(date).format(format || 'YYYY-MM-DD HH:mm');
  }
}

function replaceWithFormat(item, keyAndFormat) {
  const firstIndex = keyAndFormat.indexOf('|');
  if (firstIndex === -1) {
    // Simple replace
    return ensureString(get(item, keyAndFormat));
  }
  const [key, format] = splitAtIndex(keyAndFormat, firstIndex);
  const value = get(item, key.trim());
  if (key.indexOf('date') !== -1) return formatDate('input', format, new Date(value));
  return stringFormatter(value, format.trim(), undefined); // TODO: language ?
}

// eslint-disable-next-line import/prefer-default-export
export function replacePath(path, item) {
  if (!path) return '';
  return path.replace(/:([^/]+)/g, (m, key) => ensureString(get(item, key)));
}

export function replaceValues(text, item) {
  if (!text) return '';
  return (
    text
      // i18n format
      .replace(/{{(.*?)}}/g, (m, key) => {
        const object =
          key.indexOf('date.') !== -1
            ? { ...item, date: { now: formatDate('now'), today: formatDate('today') } }
            : item;
        return ensureString(replaceWithFormat(object, key.trim()));
      })
      // fallback oldschool
      .replace(/{(.*?)}/g, (m, key) => ensureString(get(item, key)))
  );
}

export function replaceObjectValues(strings, obj) {
  if (!strings) return {};
  return mapValues(strings, (v) => {
    if (!v || typeof v !== 'string') return v;
    return replaceValues(v, obj);
  });
}

export function replaceUriValues(text, item) {
  if (!text) return '';
  return text
    .replace(/{(.*?)}/g, (m, key) => encodeURIComponent(ensureString(get(item, key))))
    .replace(/ /, '%20');
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function urlify(text) {
  if (!text) return '';
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <a href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}

export function markdownUrlify(text, options = {}) {
  if (!text) return '';
  const { onLinkClick } = options;
  const urlRegex = /(\[[^\]]*\]\([^)]*\))/g;
  const splitRegex = /\[([^\]]*)\]\(([^)]*)\)/;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      // Split
      const innerMatch = part.match(splitRegex);
      if (innerMatch) {
        const [, urlText, url] = innerMatch;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer" onClick={onLinkClick}>
            {urlText}
          </a>
        );
      }
    }
    return urlify(part);
  });
}

/**
 * Simiar to path.join. Ensures middle slashes and prevents double slashes
 * @param {*} paths array of paths
 */
// eslint-disable-next-line import/prefer-default-export
export function urlJoin(paths) {
  if (!paths || paths.length === 0) return '';
  const validPaths = paths.filter((p) => p);
  return validPaths
    .map((p, index) => {
      if (index === 0) return trimEnd(p, '/ ');
      if (index === validPaths.length - 1) return trimStart(p, '/ ');
      return trim(p, '/ ');
    })
    .filter((v) => v) // Remove empty paths
    .join('/');
}
